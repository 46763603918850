.snowfall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: -999999;

  &.login {
    z-index: 0;
  }

  &.hide {
    display: none;
  }
}

.snowflake {
  position: absolute;
  top: -10px;
  font-size: calc(14px + var(--sizeRand) * 20px);
  opacity: var(--opacityRand); /* Opacity varies by group */
  animation: fall linear infinite;
  animation-duration: calc(1s + var(--speedRand) * 1.4s);
  animation-delay: calc(-3s * var(--timeRand));
  left: calc(var(--positionRand) * 100%);
  color: var(--snowflake-color, #cceeff); /* Default light blue color */
  filter: var(--blurRand); /* Blur applied dynamically via inline styles */
}

@keyframes fall {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(50vh) translateX(calc(5vw * var(--rand2)));
  }
  100% {
    transform: translateY(100vh) translateX(calc(10vw * var(--rand)));
  }
}
