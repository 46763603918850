@import "../../scss/vars.scss";

.template-editor-wrapper {
  padding: 20px;
  margin-top: 100px;
  &__save {
    position: fixed;
    bottom: 40px;
    left: 40px;
  }
  h1 {
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
  }

  .editor-container {
    display: flex;
    align-items: flex-start;

    .layout-container {
      flex: 1;
      margin-left: 20px;
      min-height: 600px;
      width: 700px;
      display: flex;
      justify-content: center;
      .layout {
        position: relative;
        min-height: 600px;
        width: 700px;
        // border: 1px solid $grey-lighter;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        .react-grid-placeholder {
          background-color: $primary-color-opacity;
        }
      }

      .grid-item {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 1px solid $grey-lighter;
        min-height: unset !important;
        &__line {
          height: 1px;
          width: 100%;
          background-color: $grey-lighter;
        }
        &__exam-stats {
          width: 100%;
          table {
            border-collapse: collapse;
            width: 100%;
            th,
            td {
              border: 1px solid #ccc;
              padding: 8px;
              text-align: left;
            }
          }
        }
        .react-resizable-handle {
          opacity: 0;
        }
        &:hover {
          border: 1px solid $primary-color;
          .react-resizable-handle {
            opacity: 1;
          }
        }
        .drag-handle {
          position: absolute;
          top: 5px;
          left: 5px;
          background: #007bff;
          color: #fff;
          border: none;
          padding: 4px 8px;
          font-size: 12px;
          // display: none;
          cursor: pointer;
          border-radius: 3px;
          width: 100%;
          height: 100%;
          opacity: 0;
        }

        .delete-button {
          position: absolute;
          top: 5px;
          right: 50px;
          background: #007bff;
          color: #fff;
          border: none;
          padding: 4px 8px;
          font-size: 12px;
          display: none;
          cursor: pointer;
          border-radius: 3px;
          z-index: 999999;
        }

        .edit-button {
          position: absolute;
          top: 5px;
          right: 5px;
          background: #007bff;
          color: #fff;
          border: none;
          padding: 4px 8px;
          font-size: 12px;
          display: none;
          cursor: pointer;
          border-radius: 3px;
        }

        &:hover .edit-button {
          display: block;
        }
        &:hover .delete-button {
          display: block;
        }
      }
    }
  }
}
