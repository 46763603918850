@import "../../../scss/vars";

.event-item {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin: 0 0 20px;
  position: relative;
  height: 78px;
  background-color: white;
  @include md {
    height: 110px;
  }

  // SCSS
  &__iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999;
    display: flex;
    flex-direction: column;

    &-close {
      flex: 0 0 5%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    iframe {
      flex: 1;
      width: 100%;
      border: none;
    }
  }

  &__progress {
    border-bottom: 3px solid $blue-color;
    border-left: 8px solid $blue-color;
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    height: 100%;
    position: absolute;
    top: 0;
    left: -8px;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
    box-sizing: content-box;
  }
  &.announcement {
    border-left: 8px solid $red;
    .event-item__progress {
      border-color: $red;
    }
  }
  &.parent-event-meeting {
    border-left: 8px solid $blue-dark;
    .event-item__progress {
      border-color: $blue-dark;
    }
  }
  &.lecture {
    border-left: 8px solid $primary-color;
    .event-item__progress {
      border-color: $primary-color;
    }
  }
  &.test {
    border-left: 8px solid $green-color;
    .event-item__progress {
      border-color: $green-color;
    }
  }
  &.parent-contact {
    border-left: 8px solid $grey;
    .event-item__progress {
      border-color: $grey;
    }
  }
  &.parent-event {
    border-left: 8px solid $blue-dark-opacity;
    .event-item__progress {
      border-color: $blue-dark-opacity;
    }
  }
  &.online-exam {
    border-left: 8px solid $blue-color;
  }
  &.exam {
    border-left: 8px solid $secondary-color;
    .event-item__progress {
      border-color: $secondary-color;
    }
  }
  &.is-collapsed {
    transition: 0.2s ease-in-out;
    &.expired {
      opacity: 0.6;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &.is-expanded {
    // z-index: 99999;
    // position: absolute;
  }
  &__delete {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    z-index: 999999999;
    display: none;
    &.show {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .wrapper {
      padding: 30px;
      background-color: white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 50vw;
      position: relative;
      @include md {
        width: 90vw;
      }
      &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 40px;
        width: 40px;
        box-shadow: $box-shadow;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
      &__title {
      }
      &__actions {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include md {
          flex-direction: column;
          button {
            width: 100%;
            &:nth-child(2) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &__preview {
    display: flex;
    height: 100%;
    padding-left: 20px;
    &.announcement {
      padding-left: 0;
      .time-preview {
        width: 170px;
      }
    }
    @include md {
      padding-left: 0;
    }
    &.active {
      border: 1px solid $color-green;
    }
    .time-preview {
      width: 150px;
      display: flex;
      // flex-direction: column;
      align-items: center;
      gap: 10px;
      @include md {
        width: 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__creator {
        height: 35px;
        width: 35px;
        object-fit: cover;
        border-radius: 50%;
      }
      .time {
        span {
          color: $grey;
          font-size: 14px;
          @include md {
            font-size: 12px;
          }
          &.separator {
            margin: 0 5px;
          }
        }
      }
      .duration {
        span {
          font-weight: 300;
          color: $grey;
          font-size: 12px;
          @include md {
            font-size: 11px;
          }
        }
      }
      .event-creator {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          height: 30px;
          width: 30px;
          object-fit: cover;
          border-radius: 50%;
        }
        .created_by__info {
          .name {
            font-size: 12px;
            display: flex;
            color: $grey;
            font-weight: 300;
            gap: 5px;
            overflow: hidden;
            flex-direction: column;
            width: 120px;
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .info {
      margin-left: 30px;
      height: 100%;
      width: calc(100% - 130px);
      display: flex;
      flex-direction: column;
      &.info-parent-event {
        margin-left: 0;
        display: flex;
        justify-content: center;
      }
      @include md {
        width: calc(100% - 100px);
        margin-left: 5px;
      }
      &__call {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        gap: 10px;
        align-items: center;
        svg {
          margin: 0 !important;
          padding: 0 !important;
          border-radius: none !important;
          opacity: 0.8;
        }
        span {
          color: $grey;
          font-weight: 300;
          font-size: 15px !important;
        }
      }
      &__title {
        .ck-editor__top {
          display: none;
        }
        .ck-content {
          height: 32px;
          overflow: hidden;
          border-radius: unset !important;
        }
        span {
          font-size: 18px;
          @include md {
            font-size: 15px;
            font-weight: 500;

            position: relative;
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      &__details {
        // margin-top: 5px;
        display: flex;
        @include md {
          flex-direction: column;
        }
        &-item {
          margin-right: 20px;
          max-width: 33%;
          @include md {
            max-width: 100%;
          }
          .item-title {
            position: relative;
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span {
            font-size: 14px;
            font-weight: 300;
            color: $grey;
            @include md {
              font-size: 13px;
            }
            .label {
              @include md {
                display: none;
              }
            }
            .separator {
              margin-left: 6px;
              @include md {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;
    .button-container {
      display: flex;
      @include md {
        width: 45%;
        display: block;
      }
    }
    .meeting-selector {
      position: absolute;
      bottom: 10px;
      right: 20px;
      margin-left: 20px;
      border: 2px solid $primary-color;
      border-radius: $border-radius;
      cursor: pointer;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      max-height: 53px; // Adjust based on the height of the selector-footer
      &.open {
        max-height: 250px; // Adjust based on the total height when open
      }

      .selector-wrapper {
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        .selector-footer {
          display: flex;
          align-items: center;
          padding: 5px 10px;

          span {
            margin-right: 5px;
            color: $grey;
            padding: 8px 0;
            width: 250px;
            text-align: center;
            border-radius: $border-radius;
            color: $primary-color;
            &:hover {
              background-color: $grey-even-lighter;
            }
          }

          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }

        .dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          background-color: #fff;
          overflow: hidden;

          li {
            padding: 8px 12px;
            cursor: pointer;
            &.taken {
              opacity: 0.3;
              pointer-events: none;
            }
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }

    .online-select {
      margin-left: 20px;
      select {
        height: 52px;
      }
    }
    .cta-edit {
      &.right {
        margin-right: 320px;
      }
    }
    .start-online {
      padding: 12px 30px;
      margin-left: 20px;
      @include md {
        display: none;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 10px !important;
      }
      &:hover {
        svg {
          path {
            transform: 0.3s ease;
            color: white;
          }
        }
      }
    }
    @include md {
      justify-content: center;
      width: 100%;
      button {
        font-size: 14px;
        padding: 10px 0;
        margin: 0 10px;
        width: 45%;
      }
      .button-container {
        button {
          width: 100%;
        }
      }
    }
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .professor-event-card {
      height: calc(100% - 85px);
      overflow-y: auto;
      @include md {
        height: calc(100% - 70px);
      }
    }
    .student-event-card {
      overflow-y: auto;
      // height: calc(100% - 100px);
      overflow-y: auto;
      @include md {
        // height: calc(100% - 70px);
      }
    }
    .parent-event-card {
      overflow-y: auto;
      height: calc(100%);
      overflow-y: auto;
      @include md {
        height: calc(100% - 70px);
      }
    }
    .exams__card-actions {
      display: flex;
      justify-content: space-between;
      height: 50px;
      padding: 0 30px;
      margin-bottom: 20px;
    }
    &.is-collapsed {
      padding: 13px 20px;
      height: 100%;
      background-color: white;
    }
    &.is-expanded {
      position: absolute;
      top: -20vh;
      left: -20vw;
      z-index: 9999999;
      .dismissal {
        @include md {
          padding: 0 10px;
        }
      }
    }
  }
}
