@import "../../../scss/vars.scss";

.editor-wrapper {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  width: 400px;
  position: sticky;
  top: 110px;
  height: calc(100vh - 160px);
  padding: 20px;
  overflow: auto;
  z-index: 10;
  &__save {
    position: absolute;
    height: 50px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: calc(100% - 40px);
    button {
      width: 100%;
    }
  }
  .button-multiple-toggle {
    width: 100%;
    margin-bottom: 20px;
    .wrapper {
      width: 100%;
      .item {
        flex: 1;
      }
    }
  }
  &__toolbox {
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .item-container {
      // box-shadow: $box-shadow;
      border: 1px solid $grey-lighter;
      border-radius: $border-radius;
      padding: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      &__label {
        font-weight: 300;
        color: $grey;
      }
    }
    &-section {
      width: 100%;
      .item-container {
        width: 100%;
      }
    }
    &-components {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      .item-container {
        width: 47%;
      }
    }
  }
}
