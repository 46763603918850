@import "../../scss/vars";
.exam-records {
  width: 100%;
  overflow-x: hidden;
  @include md() {
    margin-top: 0;
  }
  .info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    .controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
    }
    .details {
      display: flex;
      flex-direction: column;
      .exam-title {
        font-size: 20px;
        color: $grey;
        font-weight: 500;
      }
      .exam-description {
        font-size: 18px;
        color: $grey;
        font-weight: 300;
        margin-top: 5px;
      }
      .exam-date {
        font-size: 16px;
        color: $grey;
        font-weight: 300;
        margin-top: 10px;
      }
    }
    &:after {
      position: absolute;
      width: 100%;
      height: 1px;
      content: "";
      background-color: black;
      opacity: 0.1;
      bottom: 0;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 !important;
    color: $grey;
    font-weight: 300;
    @include md {
      margin-left: 20px;
    }
    span {
      font-size: 25px;
      font-weight: 400;
    }
    .delete {
      z-index: 1000;
    }
  }
  .marked-users {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include md() {
      padding: 20px 20px;
      justify-content: space-between;
    }

    &__item {
      width: 21%;
      margin: 20px 2%;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      position: relative;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include md() {
        width: 46%;
        margin: 20px 0;
      }

      &:hover {
        cursor: pointer;

        > .marked-users__item-delete {
          display: block;
        }
      }

      .marked-users__item-delete {
        @include md() {
          display: block;
        }
      }

      &-info {
        width: 100%;
        padding: 20px 0 0;

        &-img {
          text-align: center;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        &-details {
          margin-top: 10px;
          text-align: center;

          .name {
            display: flex;
            flex-direction: column;

            span {
              font-size: 17px;
              font-weight: 400;
            }
          }

          .department {
            padding-bottom: 10px;

            span {
              font-size: 15px;
              font-weight: 200;
            }
          }

          .comment {
            background-color: $primary-color;
            padding: 7px 10px;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            span {
              position: relative;
              display: block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: white;
              font-weight: 400;
            }
          }
        }
      }

      &-delete {
        background-color: white;
        width: 30px;
        height: 30px;
        position: absolute;
        top: -13px;
        right: 45px;
        transition: 0.2s ease-in-out;
        display: none;
        border-radius: 50%;

        &.not_scored {
          right: 140px;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.07);
        }
      }

      &-not_scored {
        background-color: white;
        width: 150px;
        height: 50px;
        position: absolute;
        top: -20px;
        right: -20px;
        border: 3px solid $primary-color;
        border-radius: $border-radius;

        span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0 !important;
          font-size: 18px !important;
          font-weight: 300;
          color: $grey;
        }
      }

      &-score {
        background-color: white;
        width: 50px;
        height: 50px;
        position: absolute;
        top: -20px;
        right: -20px;

        span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0 !important;
          font-size: 18px !important;
          font-weight: 400;
        }

        .CircularProgressbar {
          .CircularProgressbar-trail {
            stroke: $grey-lighter;
          }

          .CircularProgressbar-path {
            stroke: $primary-color;
          }
        }
      }
    }
  }

  .expanded-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: 300px;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    z-index: 1000;
    padding: 20px;
    z-index: 9999;
    &__close {
      position: absolute;
      top: 10px;
      transition: 0.3s ease-in-out;
      right: 10px;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
    &__profile {
      display: flex;
      align-items: center;
      .profile-img {
        width: 50px;
        height: 50px;
        img {
          border-radius: 50%;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .name {
        color: $grey;
        margin-left: 10px;
        span {
          &:nth-child(2) {
            margin-left: 5px;
          }
        }
      }
    }
    &__score {
      margin-top: 20px;
      display: flex;
      .total-score {
        background-color: $primary-color;
        border-radius: $border-radius;
        padding: 10px 15px;
        display: flex;
        width: 35%;
        &__circle {
          width: 130px;
          height: 130px;
          position: relative;
          .CircularProgressbar {
            width: 130px;
            &-trail {
              stroke: $primary-color-pastel;
            }
            &-path {
              stroke: white;
            }
          }
          .score-label {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-color;
            font-size: 18px;
            font-weight: 500;
          }
        }
        &__comments {
          margin-left: 10px;
          color: white;
          display: flex;
          justify-content: center;
          flex-direction: column;
          .comment-label {
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
      .detailed-score {
        margin-left: 20px;
        width: calc(65% - 20px);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        &__list {
          display: flex;
          flex-direction: row;
          gap: 20px;
          &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            .sub-label {
              color: $grey;
              font-weight: 200;
              font-size: 14px;
            }
            .CircularProgressbar {
              height: 80px;
              width: 80px;
              margin-top: 15px;
              &-path {
                stroke: $primary-color;
              }
              text {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }
  }

  .users {
    padding: 20px;
    width: 100%;
    @include md {
      padding: 20px;
    }
    &__item {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 20px 15px;
      margin-bottom: 30px;
      position: relative;
      // transition: 0.3s ease-in-out;
      &.comment-open {
        padding-bottom: 100px;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-personal {
          display: flex;
          align-items: center;
          .image {
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
              // box-shadow: $box-shadow;
            }
          }
          .details {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            &__name {
              color: $grey;
              font-weight: 500;
              .space {
                margin: 0 3px;
              }
            }
            &__department {
              color: $grey;
              font-weight: 300;
              font-size: 14px;
            }
          }
        }
        &-score {
          span {
            font-weight: 300;
            color: $grey;
            margin-right: 20px;
            font-size: 14px;
          }
          .CircularProgressbar {
            height: 70px;
            width: 70px;
            .CircularProgressbar-trail {
              // stroke: $primary-color-opacity-big;
              stroke: $grey-even-lighter;
            }
            .CircularProgressbar-path {
              stroke: $primary-color;
            }
            text {
              fill: $primary-color;
            }
          }
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 90px;
        &-final {
          // width: 300px;
          overflow: visible;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          .score-comments {
            display: flex;
            position: absolute;
            bottom: 80px;
            left: 0;
            right: 0;
            width: 400px;
            margin: auto;
            flex-direction: column;
            span {
              color: $grey;
              font-weight: 300;
            }
            textarea::placeholder {
              color: $grey-light !important;
            }
            textarea {
              // box-shadow: $box-shadow;
              border: 1px solid $grey-lighter;
              border-radius: $border-radius;
              height: 120px;
              width: 400px;
              outline: none;
              resize: none;
              padding: 10px;
              font-size: 14px;
            }
          }
          .score-actions {
            margin-left: 20px;
            margin-top: 15px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            // height: 120px;
            display: flex;
            align-items: center;
            // flex-direction: column;
            justify-content: space-between;
            &__empty {
              display: flex;
              align-items: center;
              gap: 20px;
            }
            &__add {
              margin-right: 40px;
              color: $primary-color;
              border-bottom: 1px solid white;
              &:hover {
                cursor: pointer;
                border-bottom: 1px solid $primary-color;
              }
            }
          }
        }
        &-scoring {
          // width: calc(100% - 300px);
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          // border: 1px solid red;
          display: flex;
          .score-item {
            // box-shadow: $box-shadow;
            // border-radius: $border-radius;
            // border: 1px solid red;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            padding: 10px;
            &__max {
              text-align: center;
              span {
                font-size: 11px;
                color: $grey;
                font-weight: 300;
              }
            }
            span {
              color: $grey;
              font-weight: 300;
              margin-bottom: 10px;
              text-align: center;
            }
            &__input {
              display: flex;
              //box-shadow: $box-shadow;
              border: 1px solid $grey-lighter;
              border-radius: 30px;
              padding: 10px 7px;
              svg {
                padding: 0 !important;
                margin: 0 !important;
                transition: 0.3s ease-in-out;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.03);
                  background: $primary-color !important;
                }
              }
              input {
                width: 40px;
                border: none;
                outline: none;
                // border: 1px solid black;
                // border-bottom: 1px solid $grey-even-lighter;
                display: block;
                margin: 0 5px;
                text-align: center;
                color: $grey;
                -moz-appearance: textfield;
              }
              input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
            }
          }
        }
      }
    }
  }
}
