@import "src/scss/vars";

.weekly-slider-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .print-container {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    &__day {
      width: 12%;
      display: flex;
      justify-content: center;
      svg {
        margin: 0 !important;
        padding: 0 !important;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    width: 200px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .dropdown.open {
    display: block;
  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .dropdown-item.selected {
    background-color: #007bff;
    color: white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &__title {
      font-size: 24px;
      font-weight: 300;
      color: $grey;
      display: flex;
      gap: 20px;
      align-items: center;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
      &:hover {
        cursor: pointer;
      }
    }
    h2 {
      margin: 0;
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .slider-container {
      width: 65%;
      margin-bottom: 20px;
    }

    .user-info {
      width: 30%;
      display: flex;
      flex-direction: column;
      max-height: 460px;
      overflow-y: auto;
      .user-item {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        border-radius: $border-radius;
        text-decoration: none;
        gap: 0;
        .details {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            height: 30px;
            width: 30px;
            object-fit: cover;
            border-radius: 50%;
          }
          .name {
            font-weight: 400;
            color: $grey;
            font-size: 18px;
          }
        }

        .amount {
          color: $primary-color;
          margin-left: 40px;
          font-size: 14px;
        }
        .tuition-title {
          color: $primary-color;
          margin-left: 40px;
          font-size: 14px;
        }
      }
    }
  }
}
