@import "../../scss/vars.scss";

.chat-edit {
  position: fixed;
  top: 20vh;
  height: 60vh;
  left: 20vw;
  width: 60vw;
  background-color: white;
  border-radius: $border-radius;
  z-index: 99999;
  padding: 40px 25px 25px;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    box-shadow: $box-shadow;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  &__actions {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
  }
  &__header {
    margin-top: 20px;
    .title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: $grey;
      font-weight: 300;
      display: block;
    }
  }
  .sub-title {
    font-size: 19px;
    color: $grey;
    font-weight: 300;
    margin-bottom: 20px;
  }
  &__general {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    .info {
      display: flex;
      flex-direction: column;
      width: 46%;
      text-align: center;
      .pic {
        img {
          border-radius: 50%;
          height: 100px;
          width: 100px;
          object-fit: cover;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .name {
        margin-top: 30px;
        input {
          width: 90%;
        }
      }
    }
    .participants {
      width: 46%;
    }
  }
}
