@import "../../../../scss/vars.scss";

.student-event-card {
  position: relative;
  height: 100%;
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    z-index: 9999;
    @include md {
      top: 10px;
      right: 10px;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .general {
    padding-top: 20px;
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 20px 30px;
    box-shadow: $box-shadow;
    @include md {
      padding: 20px 10px;
    }
    &.no-shadow {
      box-shadow: unset;
    }
    &__subtitle {
      color: $grey;
      font-weight: 300;
      font-size: 14px;
    }
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      .label {
        font-size: 23px;
        color: $grey;
        font-weight: 500;
        margin-top: 10px;
        padding-right: 20px;
        @include md {
          width: 70%;
          padding-right: 0;
          text-align: center;
        }
      }
    }
    &__wrapper {
      display: flex;
      justify-content: center;
      @include md {
        margin-top: 10px;
        justify-content: center;
      }
      &-details {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
        flex-wrap: wrap;
        @include md {
          flex-direction: column;
          align-items: flex-start;
          width: 70%;
          // border: 1px solid red;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      @include md {
        width: 100%;
        margin-bottom: 25px;
      }
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      .wrapper {
        margin-left: 10px;
        span {
          font-weight: 300;
          color: $grey;
          @include md {
            font-size: 15px;
          }
        }
      }
    }
    &__date {
      display: inline-flex;
      align-items: center;
      margin-top: 15px;
      width: fit-content;
      border-radius: $border-radius;
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        .date {
          font-size: 15px;
          color: $grey;
          font-weight: 300;
        }
        .time {
          font-size: 17px;
          color: $grey;
          font-weight: 300;
        }
      }
    }
    &__classroom {
      align-items: center;
      display: flex;
      width: fit-content;
      margin-left: 25px;
      border-radius: $border-radius;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      .wrapper {
        margin-left: 15px;
      }
      span {
        font-size: 16px;
        color: $grey;
        font-weight: 300;
      }
    }
  }
  .details {
    margin-top: 30px;
    padding: 0 30px;
    padding-bottom: 20px;
    @include md {
      padding: 0 10px 20px;
    }
    &__comment {
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      padding: 20px 20px;
      padding-right: 40px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 100%;
      &-label {
        font-size: 300;
        color: $grey;
      }
      .comment {
        margin-top: 10px;
        color: $grey;
        font-weight: 400;
        font-size: 17px;
      }
    }
    .start-online {
      margin: 0 auto;
      display: flex;
      align-items: center;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &:hover {
        svg {
          path {
            color: white;
          }
        }
      }
      span {
        display: block;
        margin-left: 20px;
        @include md {
          font-size: 14px;
        }
      }
    }
    &__label {
      color: $grey;
      font-weight: 300;
    }
    .label {
      color: $grey;
      font-weight: 300;
    }
    .wrapper {
      display: flex;
      @include md {
        flex-direction: column;
      }
    }
    &__score {
      margin-top: 7px;
      display: flex;
      align-items: center;
      border-radius: $border-radius;
      box-shadow: $box-shadow-dark;
      width: fit-content;
      padding: 20px 20px;
      background-color: $primary-color;
      &-comments {
        display: flex;
        flex-direction: column;
        .label {
          color: white;
          font-weight: 500;
        }
        .comment {
          font-weight: 300;
          color: white;
        }
      }
      .score {
        margin-right: 20px;
        height: 130px;
        position: relative;
        .CircularProgressbar {
          height: 130px;
          .CircularProgressbar-trail {
            stroke: $primary-color-pastel;
          }
          .CircularProgressbar-path {
            stroke: white;
          }
        }
        &__label {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 70%;
          width: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          //   border: 1px solid red;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 50%;
          color: $primary-color;
          font: 700;
        }
      }
    }
    &__detailed-score {
      margin-top: 25px;
      width: 50%;
      .label {
        font-size: 400;
        color: $grey;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 40px;
          padding: 10px 20px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          &-label {
            margin-bottom: 10px;
            font-weight: 300;
            color: $grey;
          }
          .CircularProgressbar {
            height: 70px;
          }
        }
      }
    }
  }
}
