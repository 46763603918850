.profile-skeleton {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  .left {
    width: 30%;
  }
  .right {
    width: 68%;
    padding: 0 20px;
  }
}
