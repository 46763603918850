@import "../../scss/vars.scss";

.loader-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__item {
    $loading-light: #ffffff;
    $loading-dark: #f2f1f1;
    margin: 20px 0;
    background-color: $loading-dark;
    border-radius: $border-radius;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 60px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        to right,
        $loading-dark 8%,
        $loading-light 18%,
        $loading-dark 33%
      );
      animation: loading 2s linear infinite;
    }
    &.align-right {
      margin-left: auto;
    }
    &.width-20 {
      width: 18%;
      height: 150px;
      margin-right: 2%;
      @include md {
        width: 29%;
        margin-right: 4%;
      }
    }
    &.width-25 {
      width: 21.5%;
      height: 150px;
      margin-right: 4%;
      &:nth-child(4n) {
        margin-right: 0;
      }
      @include md {
        width: 29%;
        margin-right: 4%;
      }
    }
    &.width-30 {
      width: 30%;
      height: 200px;
      margin-right: 3%;
    }
    &.mobile-width {
      &-100 {
        @include md {
          width: 100%;
          height: 150px;
          margin-right: 0;
        }
      }
      &-50 {
        @include md {
          width: 46%;
          height: 150px;
          margin-right: 4%;
        }
      }
    }
    &.height-small {
      height: 60px;
    }
    &.height-xl {
      height: 350px;
    }
    &.height-xxl {
      height: 500px;
    }
    &.animation-delay {
      &__1 {
        &::before {
          animation-delay: 0.2s;
        }
      }
      &__2 {
        &::before {
          animation-delay: 0.4s;
        }
      }
      &__3 {
        &::before {
          animation-delay: 0.6s;
        }
      }
      &__4 {
        &::before {
          animation-delay: 0.8s;
        }
      }
      &__5 {
        &::before {
          animation-delay: 1s;
        }
      }
      &__6 {
        &::before {
          animation-delay: 1.2s;
        }
      }
    }

    @keyframes loading {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }
}
