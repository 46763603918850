@import "../../scss/vars";

.pagination-container {
  display: flex;
  justify-content: center;
  list-style-type: none;
  width: 100%;
  padding-left: 0;
  @include md {
    padding-bottom: 70px;
  }
  .pagination-item {
    padding: 0 12px;
    height: 40px;
    width: 40px;
    text-align: center;
    margin: auto 10px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01071em;
    border-radius: 50%;
    line-height: 1.43;
    font-size: 16px;
    min-width: 32px;
    font-weight: 300;
    color: $grey;
    box-shadow: $box-shadow;
    transition: 0.3s ease-in-out;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      // background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
      transform: scale(1.14);
    }

    &.selected {
      background-color: $primary-color;
      color: white;
      font-weight: 400;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    &.disabled {
      pointer-events: none;
      .arrow {
        svg {
          opacity: 0.4;
        }
      }
      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
