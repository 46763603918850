@import "src/scss/vars";

.curriculum-card {
  position: relative;
  width: 100%;
  min-height: 150px;
  // margin-right: 20px;
  // margin-bottom: 25px;
  transition: 0.4s ease-in-out;
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      position: relative;
    }

    &.is-expanded {
      width: 60vw; // Keep width as before
      height: auto; // Auto height
      max-height: 90vh; // Max height
      position: fixed; // Fix position to center
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // Center it
      z-index: 9999999;
      overflow-y: auto;
      padding-bottom: 0;
      &.live-work {
        .curriculum-livework {
          min-height: 70vh;
          position: relative;
          &__loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .containLoader {
              position: relative;
              height: 60px;
              width: 60px;
              margin: auto;
              .circleGroup {
                position: absolute;
                height: 100%;
                width: 100%;
              }
              @for $i from 1 through 8 {
                .circle-#{$i} {
                  border: 7px solid #6225e6;
                  // border: 10px solid black;
                  border-radius: 50%;
                  box-sizing: border-box;
                  border-right: 7px solid transparent;
                  border-bottom: 7px solid transparent;
                  border-left: 7px solid transparent;
                  -webkit-animation: rotate
                    1.2s
                    cubic-bezier(0, 0.58, 1, 0.3)
                    infinite;
                  -moz-animation: rotate
                    1.2s
                    cubic-bezier(0, 0.58, 1, 0.3)
                    infinite;
                  -o-animation: rotate
                    1.2s
                    cubic-bezier(0, 0.58, 1, 0.3)
                    infinite;
                  animation: rotate 1.2s cubic-bezier(0, 0.58, 1, 0.3) infinite;

                  -webkit-animation-delay: 30ms * $i;
                  -moz-animation-delay: 30ms * $i;
                  -o-animation-delay: 30ms * $i;
                  animation-delay: 30ms * $i;
                }
              }
              .innerText {
                position: absolute;
                bottom: -40px;
                animation: flash 1.2s ease-in-out infinite;
                pointer-events: none;
              }
            }

            @keyframes rotate {
              50% {
                border: 1px solid rgb(0, 255, 153);
                border-right: 1px solid transparent;
                border-bottom: 1px solid transparent;
                border-left: 1px solid transparent;
              }
              100% {
                transform: rotatez(360deg);
              }
            }
            @keyframes flash {
              0% {
                opacity: 0;
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          }
        }
      }
      .profile__details {
        width: calc(100% - 60px);
        .details {
          flex-wrap: wrap;
          white-space: unset;
          gap: 6px;

          @include md {
            gap: 2px;
          }

          span {
            flex-wrap: wrap;
            white-space: unset;
          }
        }
      }
    }
  }

  &__preview {
    display: flex;
    height: 100%;
    padding: 20px;
  }
  &__actions {
    height: 60px;
    width: 100%;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 99;
    justify-content: space-between;
    .cta {
      padding: 6px 30px;
    }
    .cta-red {
      border: unset;
    }
  }
  &__header {
    &-profile {
      display: flex;
      align-items: center;
      .profile {
        &__image {
          height: 40px;
          width: 40px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &__details {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          width: calc(100% - 60px);
          position: relative;
          &-lock {
            // position: absolute;
            right: 0;
            margin-left: 10px;
            svg {
              margin: 0 !important;
              padding: 0 !important;
            }
          }
          .info {
            display: flex;
            align-items: flex-end;
            width: 100%;
            .name {
              span {
                font-size: 17px;
                @include md {
                  font-size: 15px;
                }
                &:nth-child(2) {
                  margin-left: 7px;
                }
              }
            }
            .date {
              margin-left: 10px;
              font-size: 13px;
              color: $grey;
              font-weight: 300;
              @include md {
                font-size: 10px;
              }
            }
          }
          .details {
            display: flex;
            align-items: center;
            gap: 5px; // Adjust gap as needed
            overflow: hidden;
            white-space: nowrap;
            width: calc(
              100% - 20px
            ); // Ensure the container has a defined width

            span {
              color: $grey;
              font-weight: 200;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &:last-child {
                text-overflow: ellipsis; // Add ellipsis only to the last child
              }
              // flex: 1; // Allow the spans to take up equal space and grow as needed

              @include md {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &__title {
    margin-top: 20px;
    font-weight: 400;
    color: $grey;
    font-size: 17px;
  }
  &__live-work-preview {
    border-radius: $border-radius;
    // background-color: $primary-color;
    border: 1px solid $primary-color;
    display: flex;
    width: fit-content;
    padding: 3px 13px;
    margin-top: 7px;
    span {
      // color: white;
      color: $primary-color;
      font-size: 14px;
    }
  }
  &__files {
    display: flex;
    margin-top: 20px;
    // overflow-x: auto;
    flex-wrap: wrap;
    width: 100%;
    // overflow-y: hidden;
    &-item {
      height: 50px;
      width: 50px;
      margin-right: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      position: relative;
      &:hover {
        .curriculum-card__files-item-overlay {
          display: flex;
        }
      }
      &-overlay {
        border-radius: $border-radius;
        background-color: rgba(0, 0, 0, 0.35);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        display: none;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
      &.expanded {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        svg {
          height: 50px;
          width: 50px;
        }
        img {
          border-radius: $border-radius !important;
        }
      }
      &.audio,
      &.ppt {
        opacity: 0.5;
        // border-radius: 50%;
        img {
          // height: 80%;
          // width: 80%;
          // border-radius: 50%;
          padding: 10px;
          object-fit: contain;
          // background-color: $grey-even-lighter;
        }
      }
      &.pdf {
        opacity: 0.4;
        border-radius: 50%;

        // box-shadow: $box-shadow;
        img {
          // height: 80%;
          // width: 80%;
          // border-radius: 50%;
          padding: 10px;
          object-fit: contain;
          // background-color: $grey-even-lighter;
        }
      }
      &.img {
        img {
          border-radius: 50%;
          // height: 80%;
          // width: 80%;
        }
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: $border-radius;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          // opacity: 0.7;
        }
      }
    }
  }
  &__chip {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: $border-radius;
    background-color: $primary-color;
    padding: 6px 15px;
    display: flex;
    align-items: center;
    @include md {
      padding: 4px 10px;
    }
    span {
      color: white;
      font-weight: 400;
      font-size: 12px;
      @include md {
        font-size: 10px;
      }
    }
  }
  &__download {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
      align-items: center;
      padding: 7px 15px;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      span {
        display: block;
        margin-right: 15px;
        font-size: 15px;
      }
    }
  }
  &__submissions {
    margin-top: 25px;
    padding: 15px 0;
    // border-top: 1px solid $grey-even-lighter;
    &-title {
      position: relative;
      background-color: white;
      padding-right: 20px;
      z-index: 2;
      display: flex;
      // width: fit-content;
      span {
        position: relative;
        font-size: 14px;
        color: $grey;
        font-weight: 200;
        background-color: white;
        z-index: 2;
        display: block;
        padding-right: 20px;
      }
      &:after {
        position: absolute;
        z-index: 1;
        top: 13px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        background-color: $grey-lighter;
      }
    }
    &-upload {
      border-radius: $border-radius;
      margin-top: 30px;
      // box-shadow: $box-shadow;
      // border: 1px solid $grey-even-lighter;
      // padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      padding-bottom: 70px;
      .label {
        font-size: 14px;
        font-weight: 300;
        color: $grey;
        display: block;
        margin-bottom: 10px;
      }
      .upload-files {
        width: 35%;
        display: flex;
        flex-direction: column;
        &__thumbnails {
          display: flex;
          position: absolute;
          bottom: 20px;
          left: 0;
          .exam-thumbnail-item {
            width: 40px;
            height: 40px;
            margin-right: 15px;
            position: relative;
            svg {
              position: absolute;
              top: -15px;
              right: -15px;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
        &__button {
          background-color: $grey-even-lighter;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease-in-out;
          margin: 30px auto 0;
          position: relative;
          overflow: hidden;
          &-input {
            position: absolute;
            height: 200px;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            opacity: 0;
            &:hover {
              cursor: pointer;
            }
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          &:hover {
            cursor: pointer;
            background-color: $primary-color;
            svg {
              path {
                color: White;
              }
            }
          }
        }
      }
      .upload-text {
        width: 60%;
        textarea {
          resize: none;
          height: 150px;
          width: 100%;
          border: 1px solid $grey-lighter;
          outline: none;
          border-radius: $border-radius;
          padding: 10px;
        }
      }
      .upload-submit {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 20px;
        right: 0;
        button {
          padding: 8px 40px;
        }
      }
    }
  }
}
