@import "../../../scss/vars.scss";

.mobile-filters {
  width: calc(100vw - 40px - 140px);
  position: absolute;
  transition: 0.3s ease-in-out;
  top: 60px;
  right: 0;
  height: calc(70vh - 60px);
  &.big-width {
    width: calc(100vw - 110px);
  }
  &__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 10px;
    position: relative;
    border-top: 1px solid $grey-even-lighter;
    .filters__section {
      text-align: left;
      &-label {
        color: $grey;
        font-weight: 300;
      }
      .filters__class {
        &-grade {
          font-size: 12px;
          color: $grey;
          font-weight: 200;
          margin: 10px 0;
        }
        &-item {
          margin: 8px 0;
          &-button {
            font-size: 14px;
            padding: 5px 10px;
            border-radius: $border-radius;
            border: 1px solid $grey-even-lighter;
            &.active {
              border: 1px solid $primary-color;
            }
          }
        }
      }
    }
  }
}
