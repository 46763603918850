@import "../../../../scss/vars.scss";

.announcement-section {
  padding: 20px 30px 20px 10px;
  display: flex;
  flex-direction: column;
  width: 58.4vw;
  height: calc(81vh + 100px);
  padding-bottom: 120px;
  overflow-y: auto;
  pointer-events: all;
  @include md {
    width: 100%;
    padding: 20px;
    height: calc(100% + 100px);
    padding-right: 15px;
    padding-bottom: 120px;
  }
  .ck-editor {
    .ck-content {
      min-height: 150px;
    }
  }
  .left {
    width: 100%;
  }
  .target {
    padding-bottom: 0;
    .label {
      display: block;
      margin-bottom: 6px;
    }
    &__select {
      display: flex;
      @include md() {
        flex-direction: column;
      }
      .item {
        display: flex;
        align-items: center;
        margin-right: 50px;
        @include md() {
          margin-bottom: 15px;
        }
        span {
          font-size: 17px;
          margin-left: 8px;
        }
        &:hover {
          cursor: pointer;
          > .target__select-radio {
            border: 4px solid $primary-color;
            transform: scale(1.08);
          }
        }
      }
      &-radio {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 4px solid white;
        transition: 0.2s ease-in-out;
        box-shadow: $box-shadow-dark;
        background-color: white;
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }
  .right {
    width: 100%;
  }
  .label {
    margin-top: 20px;
    font-size: 14px;
    color: $grey;
    font-weight: 300;
    display: block;
  }
  .class-selector {
    margin-top: 15px;
  }
  .classes {
    margin-top: 5px;
    width: 100%;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    input {
      margin: 10px 10px 0;
      font-size: 13px;
      width: 94%;
      box-shadow: unset;
    }
    &__list {
      overflow-y: auto;
      max-height: 30vh;
      padding: 5px 10px 0;
      &-item {
        padding: 5px 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        border: 2px solid white;
        border-radius: $border-radius;
        &.active {
          border: 2px solid $primary-color;
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          border-radius: $border-radius;
          background-color: $grey-light;
          opacity: 0.8;
        }
        &:hover {
          cursor: pointer;
          background-color: $grey-super-light;
        }
        .class-name {
          font-size: 15px;
          font-weight: 400;
          margin: 0;
          padding: 0;
        }
        .grade {
          font-size: 13px;
          color: $grey;
          font-weight: 300;
        }
      }
    }
  }
  .title-config {
    width: 100%;
    .label {
      margin-bottom: 5px;
      margin-right: 15px;
      font-size: 14px;
    }
    input {
      width: 100%;
      font-size: 14px;
    }
  }
  .departments {
    margin-top: 15px;
    .label {
      font-size: 14px;
      color: $grey;
      font-weight: 300;
      display: block;
    }
    &__list {
      display: flex;
      overflow-x: auto;
      flex-wrap: wrap;
      margin: -15px;
      padding: 15px;
      .item {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 7px 20px;
        margin-right: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        color: $grey;
        border: 2px solid white;
        font-weight: 500;
        transition: 0.3s ease-in-out;
        flex-basis: auto;
        min-width: min-content;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        color: $grey;
        @include md {
          margin: 5px 15px 7px 0;
          padding: 7px 15px;
        }
        &:hover {
          transform: scale(1.05);
          cursor: pointer;
        }
        &.active {
          border: 2px solid $primary-color;
        }
      }
    }
  }
  .professors {
    margin-top: 20px;
    .label {
      margin-top: 0;
      font-size: 14px;
      color: $grey;
      font-weight: 300;
      display: block;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      .item {
        border-radius: $border-radius;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: $box-shadow;
        padding: 15px 5px;
        width: 150px;
        border: 2px solid white;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.05);
          cursor: pointer;
        }
        &.active {
          border: 2px solid $primary-color;
        }
        &__img {
          width: 100%;
          display: flex;
          justify-content: center;
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &__name {
          display: flex;
          flex-direction: column;
          color: $grey;
          font-weight: 300;
          font-size: 15px;
          justify-content: center;
          width: 100%;
          margin-top: 7px;
          span {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
