@import "../../../../scss/vars.scss";

.mark-student {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  transition: 0.3s ease-in-out;
  margin-bottom: 20px;
  &.saved {
    overflow: hidden;
    transform: scale(0);
    max-height: 0;
    padding: 0;
    margin: 0;
  }
  &__personal {
    width: 30%;
    display: flex;
    align-items: center;
    border-right: 1px solid $grey-lighter;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    &-img {
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      span {
        color: $grey;
        font-weight: 300;
      }
    }
  }
  &__comment {
    display: flex;
    flex-direction: column;
    gap: 7px;
    flex: 1;
    padding-left: 20px;
    border-left: 1px solid $grey-lighter;
    &-label {
      font-weight: 300;
      color: $grey;
    }
    textarea {
      resize: none;
      height: 100px;
    }
    button {
      margin-left: auto;
      margin-top: auto;
      width: 200px;
    }
  }
  &__grade {
    width: 200px;
    height: 200px;
    .grade-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      .CircularProgressbar {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        .CircularProgressbar-path {
          stroke: $primary-color;
        }
        text {
          display: none;
        }
      }
      .score-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        &__max {
          text-align: center;
          span {
            font-size: 11px;
            color: $grey;
            font-weight: 300;
          }
        }
        span {
          color: $grey;
          font-weight: 300;
          margin-bottom: 10px;
          text-align: center;
        }
        &__input {
          display: flex;
          //box-shadow: $box-shadow;
          border: 1px solid $grey-lighter;
          border-radius: 30px;
          padding: 10px 7px;
          svg {
            padding: 0 !important;
            margin: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.03);
              background: $primary-color !important;
            }
          }
          input {
            width: 40px;
            border: none;
            outline: none;
            // border: 1px solid black;
            // border-bottom: 1px solid $grey-even-lighter;
            display: block;
            margin: 0 5px;
            text-align: center;
            color: $grey;
            -moz-appearance: textfield;
          }
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }
    }
  }
}
