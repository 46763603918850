@import "../../../scss/vars.scss";
.homepage-skeleton {
  margin-top: 100px;
  padding: 0 32px;
  .calendar-skeleton {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @include md {
      flex-direction: column;
    }
    .left {
      width: 30%;
      @include md {
        width: 100%;
      }
    }
    .right {
      width: 68%;
      padding: 0 20px;
      @include md {
        width: 100%;
      }
      .filters {
        display: flex;
        justify-content: space-between;
        &__left {
          width: 60%;
        }
        &__right {
          width: 30%;
        }
      }
    }
  }
}
