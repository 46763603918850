@import "../../scss/vars.scss";

.events__item-create-motion {
  &:not(.type) {
    pointer-events: none;
  }
}
.configure-event {
  padding: 0 20px;
  position: relative;
  width: 88vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 10;
  @include md {
    padding: 0;
    height: 92vh !important;
  }
  &__error {
    position: absolute;
    z-index: 9999999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    &-box {
      background-color: white;
      border-radius: $border-radius;
      padding: 20px 40px;
      max-width: 50vw;
      .box-header {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 20px;
        }
        .label {
          margin-top: 20px;
          font-size: 17px;
          color: $grey;
        }
        .box-actions {
          width: 100%;
          margin-top: 50px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .error-msg {
    margin-left: 10px;
    color: $red;
    font-weight: 500;
    font-size: 16px;
  }
  &__actions {
    position: sticky;
    z-index: 9999999999;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0 15px;
    display: flex;
    justify-content: space-between;
    height: 80px !important;
    pointer-events: all;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      rgba(255, 255, 255, 1) 100%
    );
    backdrop-filter: blur(2px);
    &.under {
      z-index: 9;
    }
    .cta-shadow {
      box-shadow: unset !important;
      border: 1px solid $grey-lighter;
    }
    @include md {
      width: 100% !important;
      padding: 15px 10px;
      padding-right: 5px;
    }
    .prev {
      transition: 0.3s ease-in-out;
      &.uploading {
        transform: translateX(-100vw);
      }
    }
    .cta {
      position: relative;
      z-index: 10;
      background-color: white;
      box-shadow: $box-shadow;
      color: $grey !important;
      &:hover {
        background-color: white !important;
      }
      @include md {
        padding: 0;
        margin: 0;
        border: unset;
        background-color: unset;
        box-shadow: unset;
      }
      &.not-visible {
        opacity: 0;
        pointer-events: none;
      }
      .icon {
        display: none;
        padding: 10px;
        border-radius: 50%;
        background-color: #f1f1f1;
        // box-shadow: $box-shadow;
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
        &.right {
          transform: rotate(180deg);
        }
        @include md {
          display: block;
        }
      }
      span {
        @include md {
          display: none;
        }
      }
      &:hover {
        background-color: $primary-color;
      }
    }
    .final {
      position: absolute;
      transition: 0.3s ease-in-out;
      right: 0;
      &.uploading {
        border: 1px solid white;
      }
    }
    .steps {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__list {
        display: flex;
        align-items: center;
        .step {
          border-radius: 50%;
          height: 30px;
          width: 30px;
          background-color: $primary-color-opacity;
          background-color: rgb(230, 230, 230);
          margin: 0 20px;
          color: white;
          font-weight: 500;
          font-size: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &:after {
            position: absolute;
            top: 14px;
            height: 2px;
            width: 35px;
            content: "";
            right: -38px;
            background-color: $primary-color-opacity;
            background-color: rgb(227, 227, 227);
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          &.active {
            background-color: $primary-color-opacity;
            width: 35px;
            height: 35px;
            &:after {
              top: 17px;
              height: 2px;
              width: 35px;
              content: "";
              right: -37px;
              background-color: $primary-color;
              background-color: rgb(227, 227, 227);
            }
          }
          &.current {
            background-color: $primary-color;
            background-color: $primary-color;
            width: 35px;
            height: 35px;
            &:after {
              top: 17px;
              height: 2px;
              width: 35px;
              content: "";
              right: -37px;
              background-color: $primary-color;
              background-color: rgb(227, 227, 227);
            }
          }
        }
      }
    }
  }
  &.type {
    @include md {
      height: 100%;
    }
    .header {
      display: none;
    }
  }
  &.general {
    height: 90vh;
    .configure-event__actions {
      width: 57vw;
    }
  }
  &.grades {
    .configure-event__actions {
      width: 86.5vw;
    }
  }
  &.date {
    .configure-event__actions {
      width: 77vw;
      background: transparent !important;
    }
  }
  &.online-date {
    .configure-event__actions {
      width: 77vw;
      background: transparent !important;
    }
  }
  .header {
    position: fixed;
    top: 0;
    left: 5vw;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    width: 90%;
    height: 70px;
    transition: 0.3s ease-in-out;
    display: none;
    &__close {
      position: absolute;
      top: 20px;
      right: 10px;
      transition: 0.3s ease-in-out;
      z-index: 99999;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
    .title {
      padding: 10px 20px;
      border-radius: $border-radius;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.5s ease;
      font-size: 20px;
      color: $grey;
      font-weight: 300;
      z-index: 99;
      &.active {
        left: 0;
        background-color: $primary-color;
        color: white;
        transform: translateX(20px);
        width: 180px;
        text-align: center;
      }
    }
  }
  &__slider {
    height: calc(100% - 80px);
    z-index: 999999999;
    pointer-events: none !important;
    .slick-slider {
    }
    .slick-list {
      height: 100%;
      overflow: visible;
      pointer-events: none;
      .slick-track {
        height: 100%;
        height: 100%;
        top: 3px;
        pointer-events: none;
        .slick-slide {
          opacity: 0;
          &:has(.section__type) {
            display: flex;
            align-items: center;
          }
          &.slick-active {
            opacity: 1;
          }
        }
      }
    }
  }
  .section {
    border-radius: $border-radius;
    @include md {
      height: 86vh;
    }
    &__input {
      .label {
        display: block;
        font-size: 17px;
        color: $grey;
        font-weight: 300;
        margin-bottom: 10px;
      }
    }
    &__type {
      @include md {
        height: 100%;
        display: flex !important;
        align-items: center;
      }
    }
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px 0;
    @include md {
      justify-content: space-between;
      padding: 0;
      margin-top: 0;
    }
    .item {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 22%;
      transition: 0.3s ease-in-out;
      border: 2px solid white;
      padding: 20px;
      padding-bottom: 60px;
      margin-top: 30px;
      position: relative;
      background-color: white;
      &-small {
        padding-bottom: 40px;
        width: 20%;
        .item-logo {
          width: 10vw;
          height: 10vw;
        }
      }
      &.announcement,
      &.parent-event {
        width: calc(100% - 2.5%);
        height: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
        &-small {
          width: 95%;
        }
        &.not-visible {
          opacity: 0;
          pointer-events: none;
          height: 0;
          overflow: hidden;
        }
        @include md {
          width: 100%;
        }
        .item-label {
          margin: 0;
          display: block;
          width: fit-content;
        }
        .item-logo {
          margin: 0;
          height: 80px;
          width: 80px;
          margin-right: 50px;
        }
        &:after {
          right: 20px;
          left: unset;
          height: 50px;
          width: 2px;
          bottom: 30px;
        }
        &:hover {
          &:after {
            transform: scale(1.5);
          }
        }
      }
      &.announcement {
        width: 95%;
      }
      &.parent-event {
        margin-top: 20px;
      }
      @include md {
        width: 47%;
        padding-bottom: 50px;
        margin: 15px 0;
      }
      &:after {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100px;
        max-width: 50px;
        content: "";
        background-color: $primary-color;
        height: 2px;
        border-radius: $border-radius;
        transition: 0.3s ease-in-out;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        &:after {
          max-width: 100px;
        }
        .item-logo {
          &:after {
            max-width: 120%;
            max-height: 120%;
            top: -10%;
            left: -10%;
          }
          .img-wrapper {
            max-width: 64%;
            max-height: 64%;
            img {
              max-width: 54%;
              max-height: 54%;
            }
          }
        }
      }
      &-logo {
        width: 13vw;
        height: 13vw;
        background-color: $primary-color-pastel;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        z-index: 9;
        @include md {
          width: 23vw;
          height: 23vw;
        }
        &:after {
          position: absolute;
          height: 130%;
          width: 130%;
          max-height: 115%;
          max-width: 115%;
          background-color: $primary-color-opacity;
          z-index: -1;
          top: -7.5%;
          left: -7.5%;
          content: "";
          border-radius: 50%;
          transition: 0.3s ease-in-out;
        }
        .img-wrapper {
          width: 70%;
          height: 70%;
          max-width: 60%;
          max-height: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 50%;
          transition: 0.3s ease-in-out;
          img {
            transition: 0.3s ease-in-out;
            width: 60%;
            height: 60%;
            max-width: 50%;
            max-height: 50%;
            opacity: 0.8;
          }
        }
      }
      &.parent-event {
        &:after {
          background-color: $blue-dark;
        }
        .item-label {
          color: $blue-dark;
        }
        .item-logo {
          background-color: $blue-dark;
          &:after {
            background-color: $blue-dark-opacity;
          }
        }
      }
      &.announcement {
        &:after {
          background-color: $red;
        }
        .item-label {
          color: $red;
        }
        .item-logo {
          background-color: $red;
          &:after {
            background-color: $red-opacity;
          }
        }
      }
      &.test {
        &:after {
          background-color: $color-green;
        }
        .item-label {
          color: $color-green;
        }
        .item-logo {
          background-color: $color-green;
          &:after {
            background-color: $color-green-opacity;
          }
        }
      }
      &.exam {
        &:after {
          background-color: $secondary-color;
        }
        .item-label {
          color: $secondary-color;
        }
        .item-logo {
          background-color: $secondary-color;
          &:after {
            background-color: $secondary-color-opacity;
          }
        }
      }
      &.online-exam {
        &:after {
          background-color: $blue-color;
        }
        .item-label {
          color: $blue-color;
        }
        .item-logo {
          background-color: $blue-color;
          &:after {
            background-color: $blue-color-opacity;
          }
        }
      }
      &.announcement-type {
        &:after {
          background-color: $red;
        }
        .item-label {
          color: $red;
        }
        .item-logo {
          background-color: $red;
          &:after {
            background-color: $red-opacity;
          }
        }
      }
      &-sublabel {
        color: $grey-light;
        font-weight: 500;
        font-size: 13px;
        @include md {
          font-size: 0.75em;
        }
      }
      &-label {
        font-size: 22px;
        display: block;
        width: 100%;
        color: $primary-color;
        font-weight: 500;
        margin-bottom: 30px;
        @include md {
          margin-bottom: 20px;
          font-size: 0.9em;
        }
      }
    }
  }
}
