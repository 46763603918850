@import "../../scss/vars";

.quiz-score-slide {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 110px;
  @include md {
    margin-top: 60px;
    padding: 30px 15px;
    padding-left: 18px;
    padding: 0;
  }
  .return-home {
    @include md {
      width: 80% !important;
    }
  }
  &__explanation {
    width: 100%;
    padding-top: 25px;
    position: relative;
    align-items: center;
    &:after {
      width: 100%;
      position: absolute;
      content: "";
      height: 1px;
      background-color: $grey-lighter;
      top: 10px;
      left: 0;
    }
    .label {
      color: $grey;
      font-weight: 300;
      font-size: 13px;
    }
    .sub-title {
      color: $grey;
      font-weight: 500;
      font-size: 16px;
      margin-left: 5px;
    }
  }
  .wrapper {
    width: 70vw;
    margin: 0 auto;
    background-color: white;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    position: relative;
    @include md {
      padding: 40px 0;
      width: 100%;
    }
    .result__answers {
      padding: 0 40px;
      margin-top: 30px;
      @include md {
        padding: 0 15px;
      }
      &-explain {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
        .line {
          width: 10px;
          height: 2px;
          background-color: $primary-color;
          margin-right: 10px;
        }
        &-answer {
          color: $grey;
          font-weight: 300;
          font-size: 12px;
        }
        &-dot {
          height: 10px;
          width: 10px;
          background-color: $green-color;
          border-radius: 50%;
          margin-left: 20px;
          margin-right: 10px;
        }
      }
      .title {
        font-size: 22px;
        color: $grey;
      }
      .question {
        text-align: left;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        margin: 20px 0;
        padding: 30px 20px;
        position: relative;
        &.correct-wrong {
          .question__answers-answer {
            @include md {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .correct-wrong {
                margin-top: 15px;
                margin-left: auto;
              }
            }
          }
        }
        .ck-editor__top {
          display: none;
        }
        .title-wrapper {
          display: flex;
          justify-content: space-between;
          .correct-wrong {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius;
            padding: 8px 0;
            border: 1px solid $grey-even-lighter;
            span {
              margin: 0 10px;
              padding: 7px 13px;
              border-radius: 12px;
              display: block;
              font-weight: 300;
              font-size: 16px;
              padding: 7px 13px;
              &.correct {
                background-color: #25e66260;
              }
              &.selected {
                border: 2px solid $primary-color;
                border-radius: 12px;
              }
            }
          }
          &.just-title {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include md {
              flex-direction: column;
              .correct-wrong {
                margin-top: 15px;
                margin-left: auto;
              }
            }
            .ck-editor {
              width: 100%;
            }
          }
        }
        &__title {
          width: 100%;
          font-size: 20px;
          color: $grey;
          &-is-correct {
            position: absolute;
            top: 10px;
            right: 20px;
            z-index: 99;
            border-radius: $border-radius;
            background-color: #25e66294;
            padding: 2px 12px;
            color: white;
            font-weight: 400;
            display: flex;
            align-items: center;
            @include md {
              right: 10px;
            }
            span {
              margin-right: 10px;
              font-size: 16px;
              @include md {
                font-size: 12px;
              }
            }
            svg {
              margin: 0 !important;
              padding: 0 !important;
              @include md {
                height: 20px;
                width: 20px;
              }
            }
            &.not-correct {
              background-color: #ff6f6f;
            }
          }
        }
        &__answers {
          &-answer {
            padding: 10px 17px;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            margin: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $grey;
            position: relative;
            .column {
              border-radius: $border-radius;
              &.b {
                &.correct {
                  border: 2px solid $primary-color;
                  background-color: #25e66260;
                }
              }
            }
            &.correct {
              background-color: #25e66260;
            }
            &.wrong {
              align-items: flex-start;
              &.matching {
                .column.b {
                  .label {
                    &:nth-child(1) {
                      // opacity: 0.8;
                      // color: white;
                      // background-color: #ff4d4da9;
                      border: 2px solid $primary-color;
                    }
                    &:nth-child(2) {
                      background-color: #25e66260;
                      position: relative;
                      &:before {
                        position: absolute;
                        left: -50px;
                        content: "Σωστό :";
                        color: $grey;
                        font-weight: 200;
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
            }
            &.matching {
              box-shadow: unset;
              border-radius: unset;
              padding: 0;
              .arrow {
                transform: rotate(180deg);
                margin-top: 10px;
              }
              .column {
                width: 48%;
                position: relative;
                &.a {
                  // &:after {
                  //     position: absolute;
                  //     content: '';
                  //     top: 22px;
                  //     right: -20px;
                  //     height: 2px;
                  //     width: 15px;
                  //     background-color: rgba(128, 128, 128, 0.4);
                  // }
                }
                .label {
                  display: block;
                  width: 100%;
                  border-radius: $border-radius;
                  box-shadow: $box-shadow;
                  padding: 10px 17px;
                  &.wrong {
                    opacity: 0.4;
                  }
                  &:nth-child(2) {
                    margin-top: 15px;
                  }
                }
              }
            }
            &.selected {
              border: 2px solid $primary-color;
            }
            .correct-icon {
              margin: 0 !important;
              padding: 0 !important;
              height: 30px;
              width: 30px;
              position: absolute;
              left: -40px;
            }
            .correct-wrong {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: $border-radius;
              padding: 8px 0;
              border: 1px solid $grey-even-lighter;
              span {
                margin: 0 10px;
                padding: 7px 13px;
                border-radius: 12px;
                &.correct {
                  background-color: #25e66260;
                }
                &.selected {
                  // background-color: $primary-color;
                  border: 2px solid $primary-color;
                  // color: white;
                }
              }
            }
          }
        }
      }
    }
    .separator {
      width: 100%;
      margin: 30px 0;
      position: relative;
      &:before {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: $grey-even-lighter;
        border-radius: 50%;
        content: "";
        left: -52px;
        top: -20px;
      }
      &:after {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: $grey-even-lighter;
        border-radius: 50%;
        content: "";
        right: -52px;
        top: -20px;
      }
      .line {
        margin-left: 5px;
        width: 100%;
        height: 3px;
        background-image: repeating-linear-gradient(
          to right,
          $grey-even-lighter,
          $grey-even-lighter 10px,
          transparent 10px,
          transparent 20px
        );
        background-size: 20px 3px;
      }
    }
    .positions {
      width: 100%;
      &__top {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 30px 0;
        &-position {
          width: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 10px;
          .user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              box-shadow: $box-shadow;
              margin: 0 auto;
            }
            &__name {
              margin-top: 10px;
              color: $grey;
              font-size: 18px;
              font-weight: 300;
            }
            &__score {
              border: 1px solid $primary-color;
              border-radius: $border-radius;
              margin: 10px auto 0;
              padding: 6px 40px;
              color: $grey;
            }
          }
          .rank {
            box-shadow: $box-shadow;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            border-radius: $border-radius;
            position: relative;
            &:after {
              position: absolute;
              bottom: -20px;
              width: 100%;
              height: 75px;
              // border: 1px solid green;
              z-index: 2;
              content: "";
              background: linear-gradient(rgba(255, 255, 255, 0.6), white);
            }
            span {
              font-size: 25px;
              font-weight: 500;
              color: $grey-light;
              position: relative;
              z-index: 8;
            }
          }
          &.first {
            .rank {
              height: 100px;
            }
          }
          &.second {
            .rank {
              height: 75px;
            }
          }
          &.third {
            .rank {
              height: 50px;
            }
          }
        }
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 28px;
      }
      .subtitle {
        font-size: 16px;
        color: $grey;
        font-weight: 300;
        margin-top: 10px;
      }
      .score {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        &-circle {
          width: 200px;
          height: 200px;
        }
        &-text {
          position: absolute;
          font-size: 40px;
          // color: $grey;
          font-weight: 300;
          margin-left: 10px;
        }
      }
    }

    &-details {
      height: 200px;
      // border-top: 1px dashed black;
      &:after {
        position: absolute;
        width: 98%;
        content: "";
        height: 25px;
        background-color: white;
        top: 0;
        border-radius: $border-radius;
        left: 1%;
      }
      &:before {
        position: absolute;
        width: 96%;
        content: "";
        height: 2px;
        top: 0;
        left: 2%;
        z-index: 11;
        // border-top: 1px dashed $grey-lighter;
        // border-spacing: 6px;
        background-image: linear-gradient(
          90deg,
          rgba(188, 188, 188, 0.5) 33.33%,
          transparent 33.33%,
          transparent 66.67%,
          rgb(188, 188, 188, 0.5) 66.67%
        );
        background-size: 12px 2px;
      }
    }
  }
}
