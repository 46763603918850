@import "../../../../scss/vars.scss";

.online-date-section {
  display: flex;
  padding-top: 15px;
  width: 78vw;
  height: calc(77vh - 50px);
  pointer-events: all;
  // border: 1px solid red;
  overflow-y: auto;
  position: relative;
  @include md {
    flex-direction: column;
    height: 88vh;
    width: 100%;
    padding-bottom: 100px;
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 5px;
  }
  .config {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    @include md {
      width: 100%;
      height: unset;
      padding: 30px 0 15px;
    }
    .wrapper {
      display: block;
      width: 100%;
      padding-bottom: 50px;
    }
    .general {
      width: 50%;
    }
    &__date {
      padding-left: 10px;
      width: 90%;
      @include md {
        width: 100%;
      }
      &-label {
        font-weight: 300;
        color: $grey;
        display: block;
        margin-bottom: 20px;
        font-size: 20px;
        width: 100%;
        text-align: center;
      }
      .date-picker {
        width: 100%;
        font-family: Arial, sans-serif;
        border-radius: $border-radius;
        padding: 0 10px;
        .header {
          position: relative;
          left: unset;
          height: unset;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 10px;
          border-bottom: 1px solid $grey-even-lighter;
          span {
            font-size: 18px;
            font-weight: 300;
            color: $grey;
          }

          button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 20px;
          }
        }

        .day-names {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          padding-bottom: 3px;
          margin-top: 3px;
          .day-name {
            padding: 4px;
            font-weight: 200;
            color: $primary-color;
            font-size: 15px;
          }
        }

        .days {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          grid-gap: 20px;
          @include md {
            grid-gap: 0.8em 0.5em;
          }
          .day {
            padding: 20px 5px;
            border-radius: $border-radius;
            cursor: pointer;
            color: $grey;
            font-size: 20px;
            position: relative;
            @include md {
              padding: 7px 10px;
            }
            &:hover {
              background-color: $primary-color-opacity;
              color: white;
            }

            &.selected {
              background-color: $primary-color;
              color: white;
              font-weight: 500;
              &:after {
                display: none;
              }
              &:before {
                display: none;
              }
            }
            &.range-day {
              background-color: $primary-color-opacity-big;
              color: white;
              font-weight: 500;
              &:after {
                display: none;
              }
              &:before {
                display: none;
              }
            }
            &.empty {
              visibility: hidden;
            }
            &.has-lecture {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-exam {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-exam-and-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-exam-and-test {
              &:after {
                position: absolute;
                width: 1px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: white;
                border-left: 7px $secondary-color;
                border-right: 7px $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 16px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-exam {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
            }
          }
        }
      }
    }
    &__duration {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      &-label {
        font-weight: 300;
        color: $grey;
        display: block;
        margin-bottom: 10px;
      }
      &-options {
        display: flex;
        input {
          width: 100px;
          display: block;
          margin-right: 20px;
        }
        .option {
          margin-right: 20px;
          padding: 7px 20px;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
          display: flex;
          align-items: center;
          color: $grey;
          font-weight: 300;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
          &.active {
            background-color: $primary-color;
            color: white;
            font-weight: 400;
          }
        }
      }
    }
  }
  .range {
    width: 20%;
    display: flex;
    align-items: center;
    &__line {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      &-item {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 10px 20px;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
      }
      &-label {
        font-weight: 300;
        color: $grey;
        display: block;
      }
      &-duration {
        font-weight: 500;
        color: white;
        display: block;
      }
      input {
        margin-top: 5px;
        display: block;
        box-shadow: none;
        color: $grey;
        border: 1px solid $grey-lighter;
        border-radius: 40px;
        // border-radius: unset !important;
      }
      .line {
        height: 60px;
        background-color: white;
        width: 4px;
        border-radius: $border-radius;
        position: relative;
        &.start {
          margin-bottom: 20px;
          &:after {
            position: absolute;
            top: -5px;
            left: -3.5px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            content: "";
            background-color: white;
          }
        }
        &.end {
          margin-top: 20px;
          &:before {
            position: absolute;
            bottom: -5px;
            left: -3.5px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            content: "";
            background-color: white;
          }
        }
      }
    }
  }
}
