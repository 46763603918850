@import "../../scss/vars.scss";

.users-page {
  margin-top: 130px;
  margin-left: 355px;
  width: calc(100vw - 390px);
  text-align: left;
  padding-bottom: 200px;
  @include md {
    margin-left: 0;
    width: 100vw;
    padding: 0 20px;
    padding-bottom: 100px;
  }
  .inactive-users {
    padding: 0 0 40px;
    .list {
      padding: 0 40px 0 10px;
      overflow: hidden;
      transition: 0.5s ease-in-out;
      max-height: 420px;
      transition: max-height 0.4s ease-out;
      @include md {
        padding: 0;
      }
      &.open {
        max-height: 150vh;
        overflow: auto;
      }
      &__controls {
        position: sticky;
        position: -webkit-sticky;
        height: 60px;
        top: 0;
        background-color: white;
        width: 100%;
        z-index: 9999;
        display: flex;
        justify-content: space-between;
        &-filters {
          display: flex;
        }
      }
      &__show-all {
        display: flex;
        border: none;
        outline: none;
        color: $primary-color;
        background-color: transparent;
        padding: 20px;
      }
      &__item {
        box-shadow: $box-shadow;
        width: 100%;
        margin: 15px 0;
        padding: 10px 15px;
        border-radius: $border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include md {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 95%;
        }
        &-wrapper {
          display: flex;
          gap: 20px;
          align-items: center;
        }
        &-delete {
          top: -10px;
          right: -10px;
          display: flex;
          align-items: center;
          gap: 15px;
          svg {
            margin: 0 !important;
            padding: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.05);
            }
          }
        }
        &-name {
          font-size: 20px;
          font-weight: 300;
        }
        &-controls {
          display: flex;
          transition: width 0.4s ease-in-out;
          &.active {
            width: auto;
          }
          &-options {
            @include md {
              width: 100%;
            }
            .container {
              display: flex;
              border-radius: $border-radius;
              // background-color: $grey-even-lighter;
              box-shadow: inset $box-shadow;
              padding: 10px 12px;
              align-items: center;
              justify-content: center;
              position: relative;
              display: inline-flex;
              @include md {
                margin-top: 10px;
                padding: 10px 3px;
                display: flex;
                justify-content: space-between;
              }
            }
            &__active-state {
              position: absolute;
              background: $primary-color;
              height: calc(100% - 20px);
              width: 50px;
              border-radius: $border-radius;
              top: 10px;
              left: 4px;
              opacity: 0;
              transition: left 0.4s ease-out, width 0.4s ease-out,
                opacity 0.4s ease-out 0.4s;
            }
            &-option {
              padding: 10px 15px;
              border-radius: $border-radius;
              margin: 0 5px;
              // border: 1px solid $grey-even-lighter;
              cursor: pointer;
              transition: color 0.3s ease-out;
              z-index: 1;
              margin-right: 5px;
              @include md {
                font-size: 12px;
                margin: 0;
                padding: 10px 10px;
              }
              &:last-child {
                margin-right: 0;
              }
              &:hover {
                cursor: pointer;
                color: $primary-color;
              }
              &.active {
                color: white;
                pointer-events: none;
              }
            }
            &-delete {
              display: inline-block;
              padding: 5px 17px;
              font-size: 18px;
              font-weight: 300;
              margin: 0 20px;
              span {
                color: $grey;
                font-weight: 300;
                font-size: 15px;
              }
              .delete {
                text-align: center;
                padding: 5px;
              }
            }
            &-line {
              width: 1px;
              height: 100%;
              background-color: $primary-color;
            }
          }
          &-save {
            display: none;
            &.active {
              display: block;
              position: relative;
              // &:before {
              //     position: absolute;
              //     content: '';
              //     top: 0;
              //     left: 0;
              //     background-color: gray;
              //     height: 100%;
              //     width: 1px;
              // }
            }
          }
        }
      }
    }
  }
}

.users {
  &__header {
    margin-bottom: 20px;
    position: sticky;
    height: 60px;
    top: 100px;
    z-index: 99;
    display: flex;
    &.big-index {
      z-index: 9999999;
    }
    @include md {
      // padding: 0 10px 0 20px;
    }
    &-search {
      width: calc(100% - 160px);
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 25px 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      background-color: white;
      height: 60px;
      &.print-options {
        width: calc(100% - 240px);
      }
      &.full-width {
        width: 100%;
        &.print-options {
          width: calc(100% - 80px);
          margin-right: 20px;
        }
      }
      &.desktop-min {
        max-width: calc(100% - 350px);
      }
      &.closed {
        max-width: 60px;
        input {
          display: none;
        }
      }
      @include md {
        width: 100%;
        transition: 0.3s ease-in-out;
        max-width: calc(100% - 140px);
        margin-right: 10px;
      }
      input {
        width: 100% !important;
        border-radius: none;
        border: none;
        box-shadow: none;
        outline: none;
        display: block;
        margin-right: 20px;
        color: $grey;
        font-weight: 300;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &-filters {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      width: 100%;
      background-color: white;
      max-height: 60px;
      height: 70vh;
      max-width: 60px;
      display: none;
      overflow-y: hidden;
      position: relative;
      @include md {
        display: block;
      }
      &.open {
        max-width: calc(100% - 140px);
        max-height: 70vh;
        .professor-exams__mobile-filters-preview {
          justify-content: flex-end;
          span {
            color: $grey;
            font-weight: 200;
            font-size: 14px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 0 20px;
            svg {
              margin-left: 10px !important;
            }
          }
        }
      }
      &.closed {
        max-width: 60px;
        overflow: hidden;
      }
      &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 60px;
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  &__title {
    font-size: 22px;
    display: flex;
    color: $grey;
    align-items: flex-end;
    span {
      display: block;
      font-size: 17px;
      font-weight: 200;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }
  &__list {
    margin-bottom: 30px;
    &-label {
      display: flex;
      justify-content: space-between;
      padding-right: 30px;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      margin: -20px;
      padding: 20px;
      padding-right: 0;
      margin-top: 15px;
      height: 100%;
      max-height: 20000px;
      overflow: visible;
      overflow-y: auto;
      transition: 0.5s ease-in-out;
      @include md {
        max-height: 30000px;
      }
      &.collapsed {
        max-height: 0;
        padding: 0;
      }
      &-item {
        display: flex;
        flex-direction: column;
        width: calc(20% - 20px);
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 15px 5px;
        align-items: center;
        transition: 0.3s ease-in-out;
        text-decoration: none;
        background-color: white;
        @include md {
          width: calc(50% - 10px);
        }
        &.archived {
          opacity: 0.5;
        }
        &.edit-dep-users {
          // transition: 0;
          &:hover {
            // cursor: default;
          }
        }
        &:not(.edit-dep-users) {
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
        }
        .profile-img {
          width: 70px;
          height: 70px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .name {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
          color: $grey;
          height: 100%;
          justify-content: center;
          text-align: center;
          span {
            display: block;
          }
        }
      }
    }
  }
  &__edit-class {
    position: fixed;
    top: 35vh;
    min-height: 30vh;
    width: 50vw;
    left: 25vw;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 20px;
    z-index: 9999999999;
    display: flex;
    flex-direction: column;
    &-label {
      font-weight: 300;
      font-size: 25px;
      color: $grey;
      display: block;
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }
    &-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__tuition {
    position: fixed;
    top: calc(50vh - 170px);
    min-height: 340px;
    width: 50vw;
    left: 25vw;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 20px;
    z-index: 9999999999;
    &-label {
      font-weight: 300;
      font-size: 25px;
      color: $grey;
      display: block;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
    }
    &-edit {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }
    &-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &-info {
      margin-top: 60px;
      .tuition-info-item {
        margin-bottom: 15px;
        text-align: center;
        .tuition-title {
          font-weight: 300;
          color: $grey;
          font-size: 15px;
        }
        .tuition-label {
          font-size: 22px;
          margin-left: 5px;
        }
      }
    }
    &-config {
      display: flex;
      flex-direction: column;
      .tuition-item {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        span {
          color: $grey;
          font-weight: 300;
          display: block;
          margin-bottom: 7px;
        }
      }
    }
  }
  &__actions {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-delete {
      button {
        padding: 5px 10px;
        border: unset;
        border: 1px solid white;
      }
    }
    &-general {
      display: flex;
      gap: 25px;
    }
  }
}
