@import "../../scss/vars.scss";

.button-multiple-toggle {
    position: relative;
    .wrapper {
        display: flex;
        border-radius: $border-radius;
        // background-color: $grey-even-lighter;
        box-shadow: inset $box-shadow;
        padding: 10px 12px;
        align-items: center;
        justify-content: center;
        position: relative;
        display: inline-flex;
        .js-btn-slide-active {
            position: absolute;
            background: $primary-color;
            height: calc(100% - 20px);
            width: 50px;
            border-radius: 15px;
            top: 10px;
            left: 4px;
            opacity: 0;
            transition: left .4s ease-out, width .4s ease-out;
        }
        .item {
            padding: 10px 15px;
            margin: 0 5px;
            // border: 1px solid $grey-even-lighter;
            cursor: pointer;
            transition: color .3s ease-out;
            z-index: 1;
            margin-right: 5px;
            @include md{
                padding: 0px 10px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                cursor: pointer;
                color: $primary-color;
            }
            &.active {
                color: white;
                pointer-events: none;
            }
            span{
                @include md{
                    font-size: 0.9rem;
                }
            }
        }
    }
}