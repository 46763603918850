@import "src/scss/vars";

.image-slider {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999999;
  pointer-events: none;
  .ppt-download-button {
    pointer-events: all;
  }
  .audio-file {
    z-index: 999999;
    pointer-events: all;
    margin-top: 75px;
    width: 50% !important;
    @include md {
      width: 90% !important;
      margin-top: 35px;
    }
  }
  .asset-container {
    margin-bottom: 5%;
    width: 80%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; // Ensure the content doesn't spill over
    &:hover {
      cursor: default;
    }
    &__controls {
      display: flex;
      position: fixed;
      width: 100%;
      justify-content: center;
      bottom: 80px;
      gap: 15px;
      z-index: 9999999999999999;
      .controls-item {
        transition: 0.3s ease-in-out;
        pointer-events: all;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
    img,
    iframe {
      max-width: 100%;
      max-height: calc(100% - 80px);
      object-fit: contain; // This ensures that the entire image or iframe is visible without distortion
      width: auto;
      height: auto;
      pointer-events: all;
      background-color: black;
      transition: transform 0.3s ease;
    }
    img {
      border-radius: $border-radius;
    }
    &__nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: white;
      font-size: 2em;
      pointer-events: all;
      //   cursor: pointer;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      &.left {
        left: 10px;
      }
      &.right {
        right: 10px;
      }
    }
  }

  .thumbnail-container {
    padding: 10px;
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 80%;
    overflow-x: auto;
    gap: 15px;
    pointer-events: all;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border: 2px solid transparent;
      border-radius: $border-radius;
      transition: 0.3s ease-in-out;
      background-color: $grey-even-lighter;
      &.icon {
        opacity: 0.3;
      }
      &.pdf,
      &.audio {
        opacity: 0.5;
      }
      &:hover {
        transform: scale(1.05);
      }

      &.active {
        border-color: white;
      }
    }
  }
}
