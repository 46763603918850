@import "../../../scss/vars";

.parent-event {
  &__card {
    width: 100%;
    height: 100%;
    &-save {
      position: fixed;
      z-index: 99;
      bottom: 13vh;
      right: 10vw;
      display: flex;
      justify-content: flex-end;
      @include md {
        bottom: 4vh;
        right: 5vw;
      }
      button {
        background-color: white;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }
  .target {
    margin-top: 30px;
    padding-bottom: 0;
    &__select {
      display: flex;
      @include md() {
        flex-direction: column;
      }
      .item {
        display: flex;
        align-items: center;
        margin-right: 50px;
        font-size: 14px;
        @include md() {
          margin-bottom: 15px;
        }
        span {
          font-size: 14px;
          margin-left: 8px;
          color: $grey;
        }
        &:hover {
          cursor: pointer;
          > .target__select-radio {
            border: 4px solid $primary-color;
            transform: scale(1.08);
          }
        }
      }
      &-radio {
        height: 23px;
        width: 23px;
        border-radius: 50%;
        border: 4px solid white;
        transition: 0.2s ease-in-out;
        box-shadow: $box-shadow-dark;
        background-color: white;
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }
  &__config {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include md {
      flex-direction: column;
    }
  }
  .config__general {
    width: 70%;
    margin-top: 20px;
    @include md {
      width: 100%;
      margin-top: 0;
    }
    &-prof {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include md {
        flex-direction: column;
        justify-content: unset;
      }
      &-parent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        &.hide-mobile {
          @include md {
            display: none;
          }
        }
        &.prof {
          margin-bottom: 10px;
        }
        .profile-img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
        }
        .profile-name {
          display: flex;
          flex-direction: column;
          gap: 5px;
          justify-content: center;
          align-items: center;
          span {
            color: $grey;
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
      &-duration {
        margin-top: 40px;
        @include md {
          margin-top: 20px;
        }
        span {
          color: $grey;
          display: flex;
          font-weight: 300;
          align-items: center;
          font-size: 14px;
          svg {
            padding: 0 !important;
            margin: 0 5px 0 0 !important;
          }
        }
      }
      &-selector {
        @include md {
          margin-top: 20px;
        }
        .cta-shadow {
          font-size: 14px;
          color: $grey;
        }
      }
    }
  }
  .config__date {
    margin-top: 40px;
    @include md {
      margin-top: 20px;
    }
    &-title {
      font-weight: 300;
      color: $grey;
    }
    &-list {
      display: flex;
      //   flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px 10px;
      gap: 15px;
      &-item {
        padding: 10px 15px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        border: 2px solid white;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
        }
        &.active {
          border: 2px solid $primary-color;
        }
        span {
          color: $grey;
          font-weight: 300;
        }
      }
    }
  }
  .config__time {
    width: 23%;
    height: calc(80vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: -20px;
    padding: 20px;
    @include md {
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      height: 150px;
      margin-top: 50px;
      margin-left: 0;
    }
    &-title {
      font-weight: 300;
      color: $grey;
    }
    &-list {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @include md {
        overflow-x: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        -ms-overflow-style: none; // IE and Edge
        scrollbar-width: none; // Firefox
        margin-left: 0px;
        // Hide scrollbar for Chrome, Safari, and Opera
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-item {
        padding: 10px 15px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        border: 2px solid white;
        transition: 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        min-width: 150px; // Ensures a minimum width on mobile for better layout
        flex: 1; // Allows the item to take up available space in flex container

        @include md {
          width: 200px;
          display: block;
        }

        &:hover {
          cursor: pointer;
          transform: scale(1.03);
        }

        &.active {
          border: 2px solid $primary-color;
        }

        &.taken {
          opacity: 0.3;
          pointer-events: none;
        }

        span {
          color: $grey;
          font-weight: 300;
          white-space: nowrap; // Prevents text from breaking
        }
      }
    }
  }
}
