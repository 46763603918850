@import url("https://fonts.googleapis.com/css?family=Mukta:700");

$bg: #f3f8fa;
$white: #fff;
$primary-color: #6225e6;
$test: #4f00f949;
$primary-color-pastel: #7e4ceb;
$secondary-color: #ffd43d;
$secondary-color-pastel: #ffdc5c;
$primary-color-opacity: #6124e681;
// $primary-color-opacity-big: #6224e645;
$primary-color-opacity-big: #6224e645;
$secondary-color-opacity: #ffd53d92;
$blue-color: #33cccc;
$blue-color-opacity: #3ca6ed7c;
$green-color: #25e662;
$blue-dark: #00315d;
$blue-dark-opacity: #00325d87;
$grey: #616161;
$grey-light: #aeaeae;
$grey-lighter: #dadada;
$grey-even-lighter: #ececec;
$grey-super-light: #ececec6f;
$red: #ff6f6f;
$red-opacity: #ff6f6f78;
$pink-color: #fb8da6;
$pink-color-opacity: #fb8da777;
$color-green: #a8e525;
$color-green-opacity: #9dd68572;

$border-radius: 20px;
$border-radius-big: 40px;
$box-shadow-darker: 0 3px 10px 2px rgba(110, 110, 110, 0.25);
$box-shadow-dark: 0 3px 10px 2px rgba(110, 110, 110, 0.2);
$box-shadow: 0 3px 10px 2px rgba(110, 110, 110, 0.1);
$box-shadow-light: 0 3px 10px 2px rgba(152, 152, 152, 0.12);
$box-shadow-lighter: 0 3px 10px 2px rgba(152, 152, 152, 0.05);
$box-shadow-inset: inset $box-shadow;

$mobile: 700px;

:root {
  --on: #6948ed;
  --off: #555;
  --scale: 16;
  --toggle-width: calc(var(--scale) * 3px);
  --toggle-height: calc(var(--scale) * 1.4125px);
  --border-width: calc(var(--scale) * 0.3px);
  --transition-time: 0.25s;
}

.color-primary {
  color: $primary-color;
}

#cta {
  @mixin transition(
    $property: all,
    $duration: 0.45s,
    $ease: cubic-bezier(0.65, 0, 0.076, 1)
  ) {
    transition: $property $duration $ease;
  }

  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    &.learn-more {
      width: 12rem;
      height: auto;
      border: solid 2px;
      border-color: $primary-color;
      border-radius: 1.625rem;
      .circle {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: relative;
        display: block;
        margin: 0;
        width: 3rem;
        height: 3rem;
        background: $primary-color;
        border-radius: 1.625rem;
        .icon {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background: $white;
          &.arrow {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
            left: 0.625rem;
            width: 1.125rem;
            height: 0.125rem;
            background: none;
            &::before {
              position: absolute;
              content: "";
              top: -0.25rem;
              right: 0.0625rem;
              width: 0.625rem;
              height: 0.625rem;
              border-top: 0.125rem solid $white;
              border-right: 0.125rem solid $white;
              transform: rotate(45deg);
            }
          }
        }
      }
      .button-text {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0.75rem 0;
        margin: 0 0 0 1.85rem;
        color: $primary-color;
        font-weight: 700;
        line-height: 1.6;
        text-align: center;
        text-transform: uppercase;
      }
    }
    &:hover {
      .circle {
        width: 100%;
        .icon {
          &.arrow {
            background: $white;
            transform: translate(1rem, 0);
          }
        }
      }
      .button-text {
        color: $white;
      }
    }
  }
}

// Small devices
@mixin sm {
  @media (max-width: 576px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: 768px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: 992px) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: 1200px) {
    @content;
  }
}
