@import "src/scss/vars";

.section-chapters {
  padding-top: 20px;
  .section__title {
    padding: 20px;
  }
  .chapters__slider {
    border: 1px solid red;
  }
}
