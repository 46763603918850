@import "../../scss/vars.scss";

.homepage {
  height: 100%;
  // min-height: 100vh;
  // background: url(../../../public/resources/whitebg.png) no-repeat 0 80%;
  margin-top: 100px;
  padding: 0 20px;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  @include md {
    padding: 0;
  }
  &.modal-open {
    z-index: 9999;
  }
  // .announcement {
  //   color: black;
  //   margin: 7rem;
  //   text-align: left;
  //   width: 30%;
  //   display: flex;
  //   gap: 25px;
  //   flex-direction: column;

  //   #cta {
  //     .learn-more {
  //       border: none;
  //     }
  //   }
  // }

  .bottom-row {
    margin: 20px;
    text-align: left;
    align-items: center;

    .section {
      color: white;
      background-color: #6225e6;
      //margin: 20px;
      border-radius: 1.25rem;
      min-height: 30vh;
      box-shadow: 0 3px 4px #0006;
      display: flex;
      align-content: center;
      padding: 2rem;
      overflow: hidden;
      position: relative;
      z-index: -1;

      #cta {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .learn-more {
          border-color: white;

          .button-text {
            color: white;
          }
        }
      }

      .info {
        width: 85%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        // p{
        // 	font-size: 1vw;
        // }
      }

      button {
        margin-bottom: 10px;
      }

      &__image {
        position: absolute;
        bottom: -10%;
        right: 0;
        opacity: 0.08;
        width: 35%;
        transform: rotate(-20deg);
      }
    }
  }
}

@media (max-width: 575px) {
  .homepage {
    padding-top: 100px;
    margin-top: 25px;
    background: none;

    // .announcement {
    //   width: 100%;
    //   margin: 20%;

    //   #cta {
    //     .learn-more {
    //       width: 13rem;
    //     }
    //   }
    // }

    .bottom-row .section {
      margin-bottom: 20px;

      &__image {
        width: 50%;
      }
    }
  }
}

@media (min-width: 576px) {
  .homepage .bottom-row .exam {
    margin-right: 10px;
  }

  .homepage .bottom-row .quiz {
    margin-left: 10px;
  }
}
