@import "../../scss/vars.scss";

.side-filters {
  @include md() {
    margin-top: 70px;
    display: none;
  }
  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999999999;
    transform: translateY(-100vh);
    transition: 0.4s ease-in-out;
    pointer-events: none;
    &.open {
      transition: 0.4s ease-in-out;
      transform: translateY(0);
    }
    &-container {
      pointer-events: all;
      position: fixed;
      top: 20%;
      // height: 40vh;
      z-index: 999999999999;
      padding-bottom: 150px;
      left: 20vw;
      width: 60vw;
      background-color: white;
      border-radius: 22px;
      box-shadow: 0 5px 10px 4px rgb(0 0 0 / 14%);
      @include md() {
        left: 10vw;
        width: 80vw;
      }
      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        &:hover {
          cursor: pointer;
        }
      }
      .config {
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &__container {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &-title {
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 30px;
            color: $grey;
            margin: 0 auto;
            @include md {
              font-size: 1.4rem;
            }
          }
          .error {
            margin-top: 10px;
            display: block;
            color: $red;
          }
          &-name {
            margin-top: 50px;
            @include md {
              width: 100%;
            }
            input {
              width: 400px;
              @include md {
                width: 100%;
              }
            }
          }
          &-grade {
            margin-top: 30px;
            width: 400px;
            padding: 0 20px;
            select {
              padding: 10px 0;
            }
            @include md {
              margin-top: 20px;
              width: 100%;
            }
            select {
              border: none;
              outline: none;
              width: 100%;
            }
          }
        }
        &__options {
          display: flex;
          justify-content: end;
          position: absolute;
          bottom: 40px;
          right: 40px;

          &-btn {
            // width: 250px;
            @include md {
              margin-top: 30px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__mobile {
    display: none;
    @include md {
      position: fixed;
      display: block;
      bottom: 100px;
      // bottom: 170px;
      z-index: 99;
      right: 23px;
      box-shadow: $box-shadow;
      // background-color: $primary-color;
      display: flex;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      .cta {
        box-shadow: $box-shadow-dark;
        border: none;
        padding: 14px;
        color: $grey-light;
        @include md {
          background-color: white;
        }
        span {
          margin-left: 10px;
        }
      }
    }
    &.for-quiz {
      @include md {
        bottom: 180px;
      }
    }
  }
  &__types {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 15px;
    padding: 0 20px;
    @include md {
      padding: 0;
    }
    &-item {
      width: 48%;
      flex-wrap: wrap;
      // box-shadow: $box-shadow;
      border: 1px solid $grey-lighter;
      margin: 8px 0;
      border-radius: $border-radius;
      padding: 10px 0;
      position: relative;
      display: flex;
      align-items: center;
      &.half {
        width: 48%;
        flex-direction: column;
        justify-content: center;
        span {
          margin: 0;
        }
      }
      &.full {
        width: 100%;
        padding: 14px 20px;
        flex-direction: row;
        // justify-content: center;
      }
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
        opacity: 0.4;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      span {
        font-size: 12px;
        letter-spacing: 0.5px;
        color: $grey;
        font-weight: 400;
        display: block;
        margin-left: 15px;
      }
      &.active {
        border: 2px solid $primary-color;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.08);
        transition: transform 0.3s ease-in-out;
      }
      .CircularProgressbar {
        padding: 0 !important;
        margin: 0 0 8px !important;
        height: 40px;
        width: 40px;
      }
      .CircularProgressbar {
        margin: 0 0 8px;
        padding: 0;
        height: 40px;
        width: 40px;
        .CircularProgressbar-trail {
          display: none;
        }
        filter: invert(1%) sepia(0%) saturate(0%) hue-rotate(2deg)
          brightness(400%) contrast(50%);
        text {
          font-size: 35px;
          filter: invert(1%) sepia(0%) saturate(0%) hue-rotate(2deg)
            brightness(10%) contrast(50%);
        }
      }
    }
  }
  &__nav {
    position: fixed;
    top: 100px;
    left: 0vw;
    height: auto;
    width: 320px;
    padding: 0;
    display: flex;
    flex-direction: column;
    z-index: 101;
    // border-right: 1px solid $grey-lighter;
    height: calc(100vh - 120px);
    overflow-y: auto;
    @media screen and (max-width: $mobile) {
      transform: translateY(110vh);
      transform: translateX(10vw);
      transition: 0.3s ease-in-out;
      background-color: white;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      &.show {
        transform: translateY(0);
        transform: translateX(0);
        z-index: 99999999999;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        padding-top: 70px;
        visibility: visible;
        opacity: 1;
      }
      &.addTopMargin {
        margin-top: 60px;
      }
    }
    &-mobile {
      &-close {
        position: absolute;
        top: 50px;
        right: 20px;
        display: none;
        @include md {
          display: block;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: $grey-lighter;
      content: "";
    }
    .classes {
      height: 100%;
      // overflow: auto;
    }
    .departments {
      padding-top: 20px;
      height: 40%;
      overflow: scroll;
    }
    &-title {
      font-size: 18px;
      font-weight: 400;
      color: $grey;
      text-align: left;
      margin-left: 25px;
      @include md {
        margin-left: 5px;
      }
      &.big {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
    &-list {
      margin-top: 10px;
      text-align: left;
      padding: 0 20px;
      @include md {
        padding: 0;
        padding-bottom: 140px;
      }
      .class-section {
        height: 100%;
        max-height: 100vh;
        transition: 0.3s ease-in-out;
        padding: 0 20px;
        margin: 0 -20px;
        overflow: hidden;
        overflow-y: auto;
        &.collapsed {
          // display: none;
          overflow: hidden;
          max-height: 0;
        }
      }
      .loading-classes {
        margin-top: 7px;
        .loader-card {
          padding: 10px 0;
          height: 60px;
          &__item {
            margin: 0;
            height: 100%;
          }
        }
      }

      &-item {
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
        margin: 14px 0;
        &-line {
          margin-top: 10px;
          color: $grey;
          font-weight: 300;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 5px;
          border: 2px solid white;
          &.active {
            border: 2px solid $primary-color;
            border-radius: $border-radius;
          }
          &:hover {
            cursor: pointer;
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        &-button {
          border: 1px solid $grey-even-lighter;
          // box-shadow: $box-shadow-light;
          border-radius: $border-radius;
          padding: 6px 20px;
          transition: 0.3s ease-in-out;
          z-index: 1;
          position: relative;
          background-color: white;
          // display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          // height: 44px;
          position: relative;
          span {
            font-weight: 400;
            display: flex;
            color: $grey;
            align-items: center;
            font-size: 15px;
            &.grade {
              font-weight: 300;
              font-size: 12px;
              color: $grey;
              position: absolute;
              top: -9px;
              padding: 0 10px;
              right: 10px;
              background-color: white;
              display: none;
              font-style: italic;
            }
          }
          &.active {
            border: 2px solid $primary-color;
            // background-color: $primary-color;
            // border: 1px solid $primary-color;
            // font-weight: 500;
            // color: white;
            // span {
            //   font-weight: 500;
            //   color: white;
            // }
            span {
              &.grade {
                display: block;
              }
            }
          }
          &:not(.configuring) {
            &:hover {
              cursor: pointer;
              // border: 2px solid $primary-color;
              transform: scale(1.05);
            }
          }
          &:hover {
            .delete-icon {
              visibility: visible;
            }
            span {
              &.grade {
                display: block;
              }
            }
          }
          .delete-icon {
            padding: 0 !important;
            margin: 0 !important;
            transition: 0.2s ease-in-out;
            visibility: hidden;
            &:hover {
              cursor: pointer;
              transform: scale(1.09);
            }
          }
          &.add {
            margin-bottom: 15px;
            border: unset;
            box-shadow: $box-shadow;
            // border: 1px solid $grey-even-lighter;
          }
          .add-container {
            display: flex;
            align-items: center;
            svg {
              margin: 0 !important;
              padding: 0 !important;
            }
            span {
              display: block;
              margin-left: 10px;
              color: $grey;
              font-weight: 400;
            }
          }
        }
        &-chapters {
          margin-left: 20px;
          .chapter-list {
            &.preview {
              margin-top: -10px;
              display: none;
            }
            &__item {
              // box-shadow: $box-shadow;
              border-radius: $border-radius;
              padding: 6px 17px;
              background-color: white;
              // border: 2px solid white;
              border: 1px solid $grey-lighter;
              margin: 10px 0;
              transition: 0.2s ease-in-out;
              display: flex;
              align-items: center;
              position: relative;
              justify-content: space-between;
              &:hover {
                cursor: pointer;
                transform: scale(1.03);
                > .chapter-list__item-delete {
                  display: block;
                }
              }
              &.active {
                border: 2px solid $primary-color;
              }
              &.add {
                justify-content: unset;
              }
              &-icon {
                margin: 0 10px 0 0 !important;
                padding: 0 !important;
              }
              &-delete {
                align-self: flex-end;
                padding: 0 !important;
                margin: 0 !important;
                transition: 0.2s ease-in-out;
                display: none;
                &:hover {
                  transform: scale(1.08);
                }
              }
              &-add {
                padding: 5px 0 10px;
                input {
                  width: 100%;
                }
                &-controls {
                  margin-top: 10px;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  svg {
                    transition: 0.2s ease-in-out;
                    &:hover {
                      cursor: pointer;
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
