@import "../../scss/vars.scss";

.gif-box {
  position: relative;
  &__icon {
    font-size: 17px;
    font-weight: 500;
    color: #3a3a3a;
    margin: 0 8px;
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 500px;
    background-color: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    box-sizing: content-box;
    padding: 20px 7px;
    transform: translateY(100vh);
    transition: 0.3s ease-in-out;
    &.open {
      transform: translateY(0);
    }
    @include md {
      position: fixed;
      bottom: 65px;
      right: 2.5vh;
      box-sizing: border-box;
    }
    .gif-footer {
      span {
        margin: 5px 0;
        color: $grey;
        display: block;
        padding-left: 10px;
      }
      .search {
        input {
          width: 100%;
          display: block;
        }
      }
    }
    .gif-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      overflow-y: auto;
      height: calc(100% - 70px);
      .item {
        width: 33%;
        border: 3px solid white;
        box-sizing: border-box;
        &:hover {
          cursor: pointer;
          border: 3px solid rgb(187, 187, 187);
        }
        img {
          width: 100%;
          height: 130px;
          object-fit: cover;
        }
      }
    }
  }
}
