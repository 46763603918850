@import "../../../scss/vars.scss";

.chapters-timeline {
  width: 100%;
  margin-top: 20px;
  overflow: visible;
  &__label {
    display: block;
    margin-bottom: 10px;
    color: $grey;
    font-weight: 200;
    font-size: 12px;
    padding-left: 15px;
  }
  &__list {
    position: relative;
    display: flex;
    width: calc(100% - 40px);
    margin-left: 20px;
    overflow-x: auto;
    overflow-y: visible;
    padding: 0 0 40px;
    .timeline-item {
      font-size: 12px;
      font-weight: 300;
      color: $grey;
      display: flex;
      width: auto;
      padding-right: 30px;
      padding-left: 30px;
      position: relative;
      &:last-child {
        &:after {
          width: calc(50% - 15px);
        }
        &.active {
          &.last {
            &:after {
              background-color: $primary-color;
              background-image: unset !important;
            }
          }
        }
      }
      &.active {
        &:after {
          background-color: $primary-color;
        }
        &.last {
          &:after {
            background-color: unset;
            background-image: linear-gradient(
              to right,
              $primary-color 42%,
              $grey-lighter 58%
            );
            background-image: linear-gradient(
              to right,
              $primary-color 45%,
              $primary-color 45%,
              $grey-lighter 55%
            );
          }
        }
        .timeline-item__content-dot {
          bottom: -35px;
          &:after {
            background-color: $primary-color;
            height: 14px;
            width: 14px;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: $grey-lighter;
        bottom: -21px;
        left: 0;
        pointer-events: none;
        z-index: -1;
        transition: 0.3s ease-in-out;
      }
      &__content {
        &-dot {
          position: absolute;
          bottom: -35px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          left: -5px;
          right: 0;
          margin: 0 auto;
          transition: 0.3s ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          &:after {
            content: "";
            background-color: $grey-lighter;
            height: 12px;
            width: 12px;
            border-radius: $border-radius;
            position: relative;
            z-index: 2;
            &:hover {
              cursor: pointer;
              transform: scale(1.2);
            }
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
            border: 1px solid $primary-color-opacity;
            &:after {
              background-color: $primary-color-opacity;
            }
          }
        }
        &-label {
          white-space: nowrap;
          color: $grey;
          font-weight: 300;
        }
      }
    }
  }
}
