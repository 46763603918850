@import "src/scss/vars";

.professor-selector {
  position: relative;
  width: 220px;
  height: 40px;
  .html-container {
    display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &__graph {
      padding: 0 40px;
      width: 100%;
    }
  }
  @include md {
    margin-left: 0;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    z-index: 1;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      box-shadow: none;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 220px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
      font-size: 14px;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      position: sticky;
      bottom: 0;
      height: 50px;
      margin-top: auto;
      button {
        background-color: white;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-item {
        border-radius: $border-radius;
        border: 1px solid $grey-lighter;
        padding: 15px;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.02);
        }
        &.selected {
          border: 2px solid $primary-color;
        }
        .prof-personal {
          display: flex;
          align-items: center;
          gap: 10px;
          &__img {
            img {
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          &__name {
            display: flex;
            gap: 7px;
            span {
              font-weight: 300;
              color: $grey;
            }
          }
        }
      }
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
