@import "src/scss/vars";

.dashboard__sections {
  .tuition-section {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-download {
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
        span {
          font-weight: 300;
          color: $grey;
        }
      }
    }
    .weekly-slider {
      .slider-controls {
        display: flex;
        justify-content: space-between;
      }
      .slider-wrapper {
        display: flex;
        width: 100%;
        .slick-slider {
          width: 70%;
        }
        .weekly-details {
          width: 30%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .tuition-item {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            &__profile {
              display: flex;
              gap: 10px;
              align-items: center;
              img {
                width: 35px;
                height: 35px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            &__details {
              display: flex;
              gap: 7px;
              justify-content: space-between;
              .tuition-date {
                font-weight: 300;
                color: $grey;
              }
              .tuition-amount {
                font-size: 17px;
                color: $grey;
              }
            }
          }
        }
      }
    }
    &__general {
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      .item {
        position: relative;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 20px;
        padding-left: 30px;
        &__icon {
          position: absolute;
          left: -40px;
          top: calc(50% - 25px);
        }
        &__details {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 300;
            color: $grey;
          }
          .label {
            color: $primary-color;
            font-size: 18px;
          }
        }
      }
    }
  }
  .section {
    &.tuition-section {
      padding: 20px 20px 0;
      .section__title {
      }
      .tuition-stats {
        .graph-container {
          margin-top: 15px;
          margin-bottom: 10px;
          .label {
            display: block;
            width: 100%;
            font-size: 18px;
            color: $grey;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
        &__payments {
          .general {
            display: flex;
            // flex-direction: column;
            .grades {
              // width: 100%;
              width: 70%;
            }
            .total {
              width: 30%;
              padding-left: 30px;
              padding-bottom: 30px;
              padding-top: 24px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item {
                height: 45%;
                border-radius: $border-radius;
                // box-shadow: $box-shadow;
                background-color: #f7f7f7;
                display: flex;
                width: 100%;
                padding: 20px;
                align-items: center;
                .label {
                  color: $grey;
                  font-size: 16px;
                  font-weight: 300;
                }
                .CircularProgressbar {
                  height: 100px;
                  width: 100px;
                  .CircularProgressbar-path {
                    stroke: $primary-color;
                  }
                  text {
                    fill: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
