@import "../../../scss/vars.scss";

.users__departments {
  padding-bottom: 30px;
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    &-item {
      padding: 15px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: calc(20% - 20px);
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      border: 2px solid white;
      position: relative;
      @include md {
        width: calc(50% - 10px);
      }
      &:not(.add-department) {
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
          .department__actions {
            display: block;
          }
        }
      }
      .label {
        color: $grey;
      }
      input {
        width: 100%;
      }
      &.add {
        transition: 0;
      }
      &.active {
        border: 2px solid $primary-color;
      }
      .department {
        &__actions {
          position: absolute;
          top: -20px;
          right: 10px;
          display: none;
          &-list {
            gap: 15px;
            display: flex;
          }
          svg {
            margin: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }
          }
        }
        &__edit,
        &__delete {
          display: flex;
          gap: 20px;
        }
      }
    }
  }
}
