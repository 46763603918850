@import "src/scss/vars";

.print-user-stats {
  position: relative;
  width: 40px;
  height: 40px;
  .html-container {
    // display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &__graph {
      padding: 0 40px;
      width: 100%;
    }
  }
  @include md {
    margin-left: 0;
    display: none;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    z-index: 1;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      box-shadow: none;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      position: sticky;
      bottom: 0;
      height: 50px;
      margin-top: auto;
      button {
        background-color: white;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
    .classes-section {
      text-align: left;
      margin-top: 20px;
      .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999998;
        &.show {
          display: block;
        }
      }
      &__header {
        span {
          color: $grey;
          font-weight: 300;
          font-size: 20px;
        }
      }
      &__label {
        font-weight: 300;
        color: $grey;
        font-size: 12px;
      }
      &__list {
        display: flex;
        gap: 20px;
        width: 100%;
        overflow: hidden;
        padding: 10px 20px 20px;
        margin: -10px -20px -20px;
        flex-wrap: wrap;
        .user-class-item {
          padding: 10px 20px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          display: flex;
          flex-direction: column;
          border: 2px solid white;
          transition: 0.3s ease-in-out;
          background-color: white;
          &.selected {
            border: 2px solid $primary-color;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
          .class-name {
            font-weight: 300;
            white-space: nowrap;
          }
          .dep-name {
            font-weight: 300;
            color: $grey;
            font-size: 12px;
            white-space: nowrap;
          }
        }
      }
    }
    .date-selection {
      &__title {
        font-weight: 300;
        color: $grey;
      }
      &__periods {
        display: flex;
        align-items: center;
        gap: 40px;
        .period-item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          span {
            font-weight: 300;
            color: $grey;
            font-size: 15px;
          }
        }
      }
    }
    .target {
      margin-top: 30px;
      padding-bottom: 0;
      &__select {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 10px;
        @include md() {
          flex-direction: column;
        }
        .item {
          display: flex;
          align-items: center;
          margin-right: 50px;
          @include md() {
            margin-bottom: 15px;
          }
          span {
            font-size: 17px;
            margin-left: 8px;
          }
          &:hover {
            cursor: pointer;
            > .target__select-radio {
              border: 4px solid $primary-color;
              transform: scale(1.08);
            }
          }
        }
        &-radio {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 4px solid white;
          transition: 0.2s ease-in-out;
          box-shadow: $box-shadow-dark;
          background-color: white;
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
