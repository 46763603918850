@import "src/scss/vars";

.classes-section {
  padding: 0 20px 30px;
  &__container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__title {
    font-weight: 300;
    color: $grey;
  }
}
