@import "src/scss/vars";

.dashboard__sections {
  .section {
    &.exam-section {
      padding-top: 20px;
      .section__title {
        padding: 20px;
      }
      .general {
        &__monthly {
          margin: 20px 0;
          .label {
            margin-bottom: 15px;
            width: 100%;
            display: block;
            text-align: center;
            font-size: 17px;
            color: $grey;
            font-weight: 300;
          }
          .loader-card {
            height: 400px;
            padding: 20px;
            &__item {
              margin: 0;
              height: 100%;
            }
          }
        }
        &__stats {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          .item {
            width: 30%;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            padding: 20px 10px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            &.loading {
              padding: 0;
              min-height: 155.5px;
              .loader-card {
                height: 100%;
                &__item {
                  height: 100%;
                  margin: 0;
                }
              }
            }
            .label {
              font-size: 17px;
              color: $grey;
              font-weight: 300;
              display: block;
              // width: calc(100% - 90px);
              word-break: break-all;
              margin-top: 10px;
            }
            .CircularProgressbar {
              width: 80px;
              height: 80px;
              .CircularProgressbar-path {
                stroke: $primary-color;
              }
              text {
                fill: $primary-color;
              }
            }
          }
        }
      }
      .old-exams {
        width: 100%;
        margin-top: 20px;
        position: relative;
        .search-exam {
          margin-left: 20px;
          width: 300px;
        }
        &__controls {
          width: calc(100vw - 480px);
          position: absolute;
          top: 80px;
          left: 45px;
          z-index: 9;
          @include md {
            width: calc(100vw - 90px);
            height: 5px;
          }
          .arrow {
            border-radius: 50%;
            height: 40px;
            width: 40px;
            // box-shadow: $box-shadow;
            background-color: #fafafa;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-in-out;
            // background-color: white;
            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }
            &.disabled {
              opacity: 0.3;
              &:hover {
                transform: none;
              }
            }
            svg {
              margin: 0 !important;
              padding: 0 !important;
            }
            &.left {
              left: 0;
            }
            &.right {
              right: 0;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
        .slider {
          .loader-container {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            width: calc(100% - 30px) !important;
            margin: 20px;
            width: calc(100% - 40px);
            padding: 20px;
            .title-loader {
              width: 40%;
              margin: 0 auto;
            }
            .graph-loader-container {
              display: flex;
              .stats-loader {
                width: 60%;
                padding: 20px;
                .stat {
                  height: 200px;
                  margin-bottom: 40px;
                  .loader-card {
                    height: 100%;
                    &__item {
                      height: 100%;
                    }
                  }
                }
              }
              .users-loader {
                width: 40%;
                padding-left: 20px;
                margin-top: 20px;
                .user {
                  height: 60px;
                  margin-bottom: 20px;
                }
              }
            }
          }
          .exam-slide {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            width: 100%;
            margin: 20px;
            width: calc(100% - 40px);
            .info {
              text-align: center;
              padding: 15px 0;
              display: flex;
              flex-direction: column;
              @include md {
                padding-top: 55px;
              }
              .title {
                font-size: 22px;
                color: $grey;
                font-weight: 400;
                span {
                  font-size: 18px;
                  font-weight: 300;
                }
              }
              .class {
                font-size: 18px;
                line-height: 17px;
                color: $grey;
                font-weight: 400;
                margin: 0;
                span {
                  font-size: 15px;
                  font-weight: 300;
                }
              }
              .date {
                margin-top: 3px;
                font-size: 14px;
                color: $grey;
                font-weight: 300;
              }
            }
            .stats-container {
              display: flex;
              width: 100%;
              //   margin-top: 10px;
              @include md {
                flex-direction: column;
              }
              .grade-stats {
                width: 60%;
                margin-top: 43px;
                @include md {
                  width: 100%;
                  margin-top: 10px;
                }
                .graph-container {
                  margin-top: 10px;
                  margin-bottom: 25px;
                  .label {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    color: $grey;
                    font-weight: 400;
                    display: block;
                    margin-bottom: 5px;
                  }
                }
                .boxes {
                  display: flex;
                  width: 100%;
                  margin-bottom: 20px;
                  justify-content: space-between;
                  .total-avg {
                    display: flex;
                    border-radius: $border-radius;
                    background-color: #fafafa;
                    padding: 15px;
                    justify-content: center;
                    margin: 0 10px;
                    width: 48%;
                    @include md {
                      flex-direction: column;
                    }
                    .label {
                      margin-bottom: 10px;
                      color: $grey;
                      font-weight: 300;
                      word-break: break-word;
                      padding-right: 50px;
                      display: flex;
                      align-items: center;
                      @include md {
                        padding: 0;
                      }
                    }
                    .score {
                      display: flex;
                      justify-content: center;
                      .CircularProgressbar {
                        width: 80px;
                        height: 80px;
                        .CircularProgressbar-path {
                          stroke: $primary-color;
                          opacity: 0.6;
                        }
                        .CircularProgressbar-text {
                          fill: $primary-color;
                          transform: translateY(2px);
                        }
                      }
                    }
                  }
                }
              }
              .user-stats {
                width: 40%;
                overflow-y: auto;
                @include md {
                  width: 100%;
                }
                &::-webkit-scrollbar {
                  width: 7px; // Adjust the width of the scrollbar here
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1;
                  border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                  background: #888;
                  border-radius: 10px;
                  &:hover {
                    background: $primary-color;
                  }
                }

                // For Firefox
                scrollbar-width: thin;
                scrollbar-color: #888 #f1f1f1;

                // Apply hover effect for Firefox (requires a workaround)
                &:hover {
                  scrollbar-color: $primary-color #f1f1f1;
                }
                .title {
                  font-size: 18px;
                  text-align: center;
                  display: block;
                  width: 100%;
                  color: $grey;
                  font-weight: 300;
                  padding-top: 0;
                }
                &__list {
                  display: flex;
                  flex-direction: column;
                  padding-left: 20px;
                  padding-right: 10px;
                  margin-top: 15px;
                  .item {
                    margin-bottom: 15px;
                    padding: 10px;
                    border-radius: $border-radius;
                    background-color: #fafafa;
                    display: flex;
                    justify-content: space-between;
                    transition: 0.3s ease-in-out;
                    text-decoration: none;
                    &.not_scored {
                      opacity: 0.6;
                    }
                    &:hover {
                      // cursor: pointer;
                      transform: scale(1.03);
                    }
                    .user-info {
                      color: $grey;
                      display: flex;
                      align-items: center;
                      &__img {
                        img {
                          width: 40px;
                          height: 40px;
                          border-radius: 50%;
                          object-fit: cover;
                          box-shadow: $box-shadow;
                        }
                      }
                      &__name {
                        margin-left: 10px;
                        display: flex;
                        span {
                          color: $grey;
                          font-size: 15px;
                          font-weight: 300;
                          &:nth-child(2) {
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                    .score {
                      display: flex;
                      justify-content: center;
                      .CircularProgressbar {
                        width: 40px;
                        height: 40px;
                        .CircularProgressbar-path {
                          stroke: $primary-color;
                          opacity: 0.6;
                        }
                        .CircularProgressbar-text {
                          fill: $primary-color;
                          font-size: 35px;
                          transform: translateY(4px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
