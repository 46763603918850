@import "src/scss/vars";

.print-users {
  position: relative;
  width: 40px;
  height: 40px;
  .print-users-button {
    display: flex;
    justify-content: flex-end;
    .cta {
      width: 160px;
      margin-left: 300px;
      position: absolute;
      right: 20px;
    }
  }
  .html-container {
    // display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    width: 100%;
    &__graph {
      // padding: 0 40px;
      width: 800px;
    }
  }
  .print-container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    border: 1px solid red;
    pointer-events: none;
    @media print {
      background-color: red;
      border: 2px solid blue;
    }
  }
  .target {
    margin-top: 30px;
    padding-bottom: 0;
    &__select {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      .item {
        display: flex;
        align-items: center;
        margin-right: 50px;
        @include md() {
          margin-bottom: 15px;
        }
        span {
          font-size: 17px;
          margin-left: 8px;
        }
        &:hover {
          cursor: pointer;
          > .target__select-radio {
            border: 4px solid $primary-color;
            transform: scale(1.08);
          }
        }
      }
      &-radio {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 4px solid white;
        transition: 0.2s ease-in-out;
        box-shadow: $box-shadow-dark;
        background-color: white;
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }
  .html-container {
    // display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &__graph {
      padding: 0 40px;
      width: 100%;
    }
  }
  @include md {
    margin-left: 0;
    display: none;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    z-index: 1;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      box-shadow: none;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    // padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    &-actions {
      padding: 0 20px 20px;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      position: sticky;
      bottom: 0;
      height: 70px;
      margin-top: auto;
      .no-view {
        visibility: hidden;
      }
      button {
        background-color: white;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
    .slider-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      overflow-y: auto;
    }

    .slide {
      width: 100%;
      height: 100%;
      position: absolute; // Stacks slides for animation
      top: 0;
      left: 0;
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
