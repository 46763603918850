@import "../../../../scss/vars.scss";

.edit-chapters {
  position: relative;
  width: 60px;
  height: 40px;
  margin-right: 20px;
  @include md {
    margin-left: 0;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    .add-item {
      width: 100%;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      svg {
        margin: 0 !important;
        padding: 8px !important;
        box-shadow: $box-shadow;
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
      span {
        margin-left: 15px;
      }
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.02);
      }
    }
    .new-item {
      width: 100%;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;
      &__info {
        .label {
          color: $grey;
          font-size: 18px;
          font-size: 15px;
          font-weight: 300;
          margin-right: 20px;
        }
        input {
          padding: 7px 15px;
          width: 400px;
        }
      }
      &__actions {
        display: flex;
        svg {
          margin: 0 10px !important;
          padding: 0 !important;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
    }
    .chapters__list {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .chapter-item {
        margin-bottom: 20px;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 15px 20px;
        &:hover {
          .chapter-item__info-action {
            display: flex;
            gap: 20px;
          }
        }
        &__delete {
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;
          &.show {
            display: block;
          }
        }
        &__info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-name {
            color: $grey;
            .label {
              font-size: 15px;
              font-weight: 300;
              margin-right: 10px;
              display: flex;
              gap: 15px;
              align-items: center;
              .label-name {
                font-size: 18px;
                font-weight: 500;
              }
              input {
                width: 300px;
              }
            }
          }
          &-action {
            display: none;
            &.show {
              display: flex;
              gap: 20px;
            }
            svg {
              margin: 0 !important;
              padding: 0 !important;
              transition: 0.3s ease-in-out;
              &:hover {
                cursor: pointer;
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999998;
    &.show {
      display: block;
    }
  }
}
