@import "../../../scss/vars.scss";

.add-department {
  position: relative;
  .preview {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .content {
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    &__slider {
      width: 100%;
      height: calc(100% - 70px);
      overflow: hidden; // Hide overflow to prevent seeing multiple slides
      .slick-list {
        height: 100%;
      }
      .slick-track {
        display: flex;
        height: 100%;
      }
      .slick-slide {
        &:nth-child(2) {
          width: 90vw !important;
        }
      }
      .slide {
        padding: 40px 20px;
      }
      .general-section {
        width: 50vw !important;
        span {
          display: block;
          text-align: center;
          font-size: 18px;
          color: $grey;
          font-weight: 300;
        }
        input {
          display: block;
          margin-top: 50px;
        }
      }
      .users-section {
        width: 90vw !important;
      }
    }
    &__nav {
      height: 70px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
      button {
        border: 1px solid $grey-lighter;
        background-color: white;
        outline: none;
        border-radius: $border-radius;
        padding: 10px 35px;
        color: $grey;
        font-weight: 300;
        &.final {
          background-color: $primary-color;
          border: 1px solid $primary-color;
          color: white;
          font-weight: 500;
          &:hover {
            background-color: white;
            color: $primary-color;
          }
        }
        &:hover {
          border: 1px solid $primary-color;
        }
        &.not-visbile {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 9999;
  }
}
