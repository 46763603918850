@import "../../../../scss/vars";

.student-exams {
  position: relative;
  &__status {
    position: absolute;
    top: -20px;
    right: -20px;
    height: 45px;
    width: 45px;
    z-index: 99999;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    .CircularProgressbar {
      .CircularProgressbar-path {
        stroke: $primary-color;
      }
      text {
        font-size: 30px;
        fill: $primary-color;
      }
    }
  }
  .student-header {
    display: flex;
    margin-bottom: 30px;
    position: sticky;
    top: 80px;
    z-index: 99;
    @include md {
      height: 60px;
      top: 100px;
    }
    &__search {
      width: 100%;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 25px 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      background-color: white;
      height: 60px;
      &.closed {
        max-width: 60px;
        input {
          display: none;
        }
      }
      @include md {
        transition: 0.3s ease-in-out;
        max-width: calc(100% - 70px);
        margin-right: 10px;
      }
      input {
        width: 100% !important;
        border-radius: none;
        border: none;
        box-shadow: none;
        outline: none;
        display: block;
        margin-right: 20px;
        color: $grey;
        font-weight: 300;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &__filters {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      width: 100%;
      background-color: white;
      max-height: 60px;
      height: 70vh;
      max-width: 60px;
      display: none;
      overflow-y: hidden;
      position: relative;
      @include md {
        display: block;
      }
      &.open {
        max-width: calc(100% - 70px);
        max-height: 70vh;
        .professor-exams__mobile-filters-preview {
          justify-content: flex-end;
          padding-right: 20px;
        }
      }
      &.closed {
        max-width: 60px;
        overflow: hidden;
      }
      &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 60px;
        span {
          color: $grey;
          font-weight: 200;
          font-size: 14px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 0 20px;
          svg {
            margin-left: 10px !important;
          }
        }
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    &-card {
      margin-right: 4%;
      @include md {
        margin-right: 10px !important;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
