@import "../../scss/vars.scss";
.skeleton {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding: 0 20px;
  .sideFiltersSkeleton {
    width: 380px;
    padding: 0 20px;
    @include md {
      display: none;
    }
  }
  .wrapper {
    width: calc(100% - 400px);
    @include md {
      width: 100%;
    }
  }
}
