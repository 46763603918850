@import "../../scss/vars.scss";

.print-page {
  padding: 30px 60px;
  margin-top: 100px;
  &__nav {
    width: 100%;
    // border: 1px solid red;
    display: flex;
    gap: 20px;
    &-schedule {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      svg {
        margin: 0 !important;
        padding: 5px !important;
      }
    }
    &-search {
      flex: 1;
      border-radius: 40px;
      height: 40px;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      input {
        background: none;
        width: 100%;
        border: none;
        // border: 1px solid red;
        padding: 0 20px;
        outline: none;
        height: 100%;
        flex: 1;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  &__list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    // .template-box {
    //   box-shadow: $box-shadow;
    //   border-radius: $border-radius;
    //   display: flex;
    //   flex-direction: column;
    //   border-bottom: 5px solid $primary-color;
    //   position: relative;
    //   overflow: hidden;
    //   button {
    //     margin-top: 20px;
    //   }
    //   &__preview {
    //     // padding: 20px;
    //   }
    //   &__title {
    //     padding: 20px;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     background: linear-gradient(
    //       to top,
    //       rgba(255, 255, 255, 1) 40%,
    //       rgba(255, 255, 255, 0) 100%
    //     );
    //   }
    // }
    &-item {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 20px;
      height: 200px;
      width: 150px;
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
      &.new {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}
