@import "src/scss/vars";

.activate-profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  &__modal {
    position: relative;
    z-index: 9999999;
    background-color: white;
    padding: 40px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modal-header {
      font-size: 24px;
      color: $grey;
      font-weight: 300;
      text-align: center;
      width: 100%;
      display: block;
    }
    .form {
      margin: 40px 0 0;
      width: 50%;
      .logo-input {
        margin-bottom: 20px;
      }
    }
    .error {
      span {
        color: $red;
      }
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }
  }
}
