@import "../../../../../scss/vars";

.student-comments {
  padding: 0 20px;
  &__title {
    margin-bottom: 15px;
    color: $grey;
  }
  &__list {
    .comment-item {
      display: flex;
      width: 100%;
      border-radius: $border-radius;
      border: 1px solid $grey-even-lighter;
      margin-bottom: 20px;
      padding: 5px 20px;
      justify-content: space-between;
      align-items: center;
      @include md {
        flex-direction: column;
        padding: 10px;
      }
      &__header {
        display: flex;
        align-items: center;
        width: 30%;
        text-decoration: none;
        @include md {
          width: 100%;
        }
        .header__img {
          width: 40px;
          height: 40px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .header__info {
          margin-left: 10px;
          color: $grey;
          .name {
            span {
              &:nth-child(2) {
                margin-left: 5px;
              }
            }
          }
        }
      }
      &__text {
        width: 70%;
        position: relative;
        height: 100%;
        @include md {
          width: 100%;
          margin-top: 15px;
        }
        &-content {
          border-left: 1px solid $grey-lighter;
          padding: 7px 0;
          position: relative;
          padding-right: 30px;
          @include md {
            border: none;
          }
          span {
            padding-left: 20px;
            color: $grey;
            display: block;
          }
          &-actions {
            position: absolute;
            top: 7px;
            right: 0;
            .action {
              &-preview {
                &:hover {
                  cursor: pointer;
                }
              }
              &-content {
                background-color: white;
                border-radius: $border-radius;
                box-shadow: $box-shadow;
                left: -90px;
                position: absolute;
                span {
                  padding: 5px 15px;
                  &:hover {
                    cursor: pointer;
                    border-radius: $border-radius;
                    background-color: $grey-even-lighter;
                  }
                }
              }
            }
            svg {
              padding: 0 !important;
              margin: 0 !important;
            }
          }
        }
        &-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-left: 1px solid $grey-lighter;
          @include md {
            border-left: unset;
          }
          .wrapper-progress {
            height: 100px;
            &__title {
              margin-left: 10px;
              color: $grey-light;
              font-weight: 300;
              margin-bottom: 8px;
              display: block;
              font-size: 14px;
            }
            &__list {
              display: flex;
              justify-content: center;
              @include md {
                height: 100px;
                overflow-x: auto;
              }
              .progress-item {
                &:first-child {
                  .progress-item__line {
                    &:after {
                      background-image: linear-gradient(
                        to right,
                        white 42%,
                        $grey-even-lighter 58%
                      ) !important;
                      background-image: linear-gradient(
                        to right,
                        white 45%,
                        $grey-even-lighter 55%
                      ) !important;
                    }
                    &.full-active {
                      &:after {
                        // background-image: usnet !important;
                        // background-color: $primary-color !important;
                        background-color: unset !important;
                        background-image: linear-gradient(
                          to right,
                          transparent 42%,
                          $primary-color 58%
                        ) !important;
                      }
                    }
                  }
                }
                &:last-child {
                  .progress-item__line {
                    &:after {
                      background-image: linear-gradient(
                        to right,
                        $grey-even-lighter 42%,
                        white 58%
                      ) !important;
                      background-image: linear-gradient(
                        to right,
                        $grey-even-lighter 45%,
                        white 55%
                      ) !important;
                    }
                    &.half-active {
                      &:after {
                        // background-image: usnet !important;
                        // background-color: $primary-color !important;
                        background-color: unset !important;
                        background-image: linear-gradient(
                          to right,
                          $primary-color 42%,
                          white 58%
                        ) !important;
                      }
                    }
                  }
                }
                &__label {
                  min-width: 140px;
                  min-height: 36px;
                  width: 100%;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  span {
                    padding: 0 20px;
                    text-align: center;
                    display: block;
                    font-weight: 300;
                    color: $grey;
                    line-height: 18px;
                    font-size: 14px;
                  }
                }
                &__line {
                  height: 100%;
                  width: 100%;
                  position: relative;
                  @include md {
                    height: 50px;
                  }
                  &:after {
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    margin: auto 0;
                    height: 2px;
                    width: 100%;
                    background-color: $grey-even-lighter;
                    content: "";
                  }
                  &.full-active {
                    &:after {
                      background-color: $primary-color;
                    }
                  }
                  &.half-active {
                    &:after {
                      background-color: unset;
                      background-image: linear-gradient(
                        to right,
                        $primary-color 42%,
                        $grey-even-lighter 58%
                      );
                      background-image: linear-gradient(
                        to right,
                        $primary-color 45%,
                        $primary-color 45%,
                        $grey-even-lighter 55%
                      );
                    }
                  }
                  .progress-circle {
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    transition: 0.3s ease-in-out;
                    z-index: 1;
                    &:hover {
                      cursor: pointer;
                      transform: scale(1.2);
                      border: 2px solid $primary-color-opacity-big;
                      &:after {
                        background-color: $primary-color-opacity-big;
                      }
                    }
                    &.active {
                      &:after {
                        background-color: $primary-color;
                      }
                    }
                    &:after {
                      position: absolute;
                      bottom: 0;
                      top: 0;
                      left: 0;
                      right: 0;
                      margin: auto;
                      height: 13px;
                      width: 13px;
                      background-color: $grey-even-lighter;
                      content: "";
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
          .wrapper-input {
            display: flex;
            @include md {
              border: none;
            }
            input {
              border: none;
              // border: 1px solid $grey-even-lighter;
              outline: none;
              border-radius: $border-radius;
              width: 100%;
              padding: 10px 35px 10px 20px;
              font-size: 14px;
              color: $grey;
              @include md {
                padding: 10px 25px 5px 5px;
                // border-bottom: 1px solid $grey-even-lighter;
                border-radius: 0;
              }
            }
            svg {
              position: absolute;
              margin: 0 !important;
              padding: 0 !important;
              right: 10px;
              bottom: 4px;
              transition: 0.3s ease-in-out;
              @include md {
                right: 0;
              }
              &:hover {
                cursor: pointer;
                transform: scale(1.1);
              }
            }
          }
        }
      }
      &__score {
        width: 20%;
        &-container {
          width: 100%;
          display: flex;
          justify-content: center;
          .CircularProgressbar {
            height: 100px;
            width: 100px;
            &-trail {
              stroke: $primary-color-opacity-big;
            }
            &-path {
              stroke: $primary-color;
            }
          }
        }
      }
    }
  }
}
