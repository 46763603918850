@import "../../scss/vars.scss";

.select-users {
  // padding: 20px;
  position: relative;
  &__breadcrumb {
    padding: 0 10px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    .breadcrumb-item {
      display: flex;
      gap: 5px;
      color: $grey;
      font-weight: 300;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .target {
    margin-top: 30px;
    padding-bottom: 0;
    &__select {
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;
      @include md() {
        flex-direction: column;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
        span {
          font-size: 17px;
          margin-left: 8px;
        }
        &:hover {
          cursor: pointer;
          > .target__select-radio {
            border: 4px solid $primary-color;
            transform: scale(1.08);
          }
        }
      }
      &-radio {
        height: 25px;
        width: 25px;
        border-radius: 7px;
        border: 4px solid white;
        transition: 0.2s ease-in-out;
        box-shadow: $box-shadow-dark;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }
  &__item {
    border: 2px solid white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 10px;
    width: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    &.all {
      width: 100%;
      padding: 0;
      box-shadow: none;
      align-items: end;
      padding-right: 15px;
      span {
        font-size: 13px;
        color: $primary-color;
        font-weight: 400;
      }
    }
    span {
      color: $grey;
      font-size: 14px;
      font-weight: 300;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &.active {
      border: 2px solid $primary-color;
    }
    &:hover {
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__grades {
    padding: 10px;
    top: 30px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: white;
    &.closed {
      transform: translate(-100vw) scale(0);
      transition: 0.3s ease-in-out;
    }
  }
  &__classes {
    padding: 10px;
    top: 30px;
    z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: 0.3s ease-in-out;
    &.closed-left {
      transform: translate(-100vw) scale(0);
    }
    &.closed-right {
      transform: translate(100vw) scale(0);
    }
  }
  &__departments {
    padding: 10px;
    top: 30px;
    z-index: 4;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: 0.3s ease-in-out;
    &.closed-left {
      transform: translate(-100vw) scale(0);
    }
    &.closed-right {
      transform: translate(100vw) scale(0);
    }
  }
  &__users {
    padding: 10px;
    top: 30px;
    z-index: 5;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: 0.3s ease-in-out;
    &.closed-left {
      transform: translate(-100vw) scale(0);
    }
    &.closed-right {
      transform: translate(100vw) scale(0);
    }
    .select-users {
      &__item {
        &-usr {
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
