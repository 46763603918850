@import "../../scss/vars";
.online-exam-results {
  padding: 20px;
  .rank-item {
    &__input {
      display: flex;
      // box-shadow: $box-shadow;
      // margin-top: 10px;
      border-radius: 30px;
      padding: 10px 7px;
      // border: 1px solid $grey-lighter;
      svg {
        padding: 0 !important;
        margin: 0 !important;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
          background: $primary-color !important;
        }
      }
      input {
        width: 40px;
        border: none;
        outline: none;
        // border: 1px solid black;
        // border-bottom: 1px solid $grey-even-lighter;
        display: block;
        margin: 0 5px;
        text-align: center;
        color: $grey;
        font-size: 18px;
        -moz-appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    .ck-content {
      border-radius: unset !important;
    }
    .ck-editor__top {
      display: none;
    }
    &__item {
      margin-bottom: 35px;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      text-align: left;
      .label {
        font-size: 14px;
        font-weight: 400;
        color: $grey;
        display: flex;
        margin: 10px;
        padding: 5px 20px;
        border-radius: $border-radius;
        background-color: $primary-color;
        width: fit-content;
        color: white;
      }
      .wrapper {
        display: flex;
        width: 100%;
        .sub-label {
          width: 100%;
          text-align: left;
          color: $grey;
          font-weight: 400;
        }
        .exam-question {
          //   border: 1px solid blue;
          width: 35%;
          padding: 10px;
          text-align: left;
          padding-left: 20px;
          @include md {
            width: 100%;
          }
          .rich-text {
            margin-top: 10px;
          }
        }
        .exam-answer {
          // border: 1px solid green;
          width: 35%;
          padding: 10px;
          @include md {
            width: 100%;
          }
          &__images {
            img {
              margin: 20px 0;
              width: 80%;
              object-fit: cover;
              border-radius: $border-radius;
            }
          }
        }
        .exam-score {
          width: 30%;
          padding: 10px;
          @include md {
            width: 100%;
          }
          &__container {
            padding-top: 20px;
            // box-shadow: $box-shadow-inset;
            border-radius: $border-radius;
            margin-top: 20px;
          }
          .score-comments {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            &__label {
              color: $grey;
              font-weight: 300;
            }
            textarea {
              margin-top: 10px;
              display: block;
              box-shadow: none;
              border: 1px solid $grey-even-lighter;
              height: 100px;
            }
            .comment {
              color: $grey;
              margin-top: 10px;
              padding: 0 5px;
            }
          }
          .score-max {
            font-weight: 300;
            color: $grey;
            font-size: 14px;
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
          .score-wrapper {
            // padding: 20px;
            width: 160px;
            position: relative;
            margin: 0 auto;
            .CircularProgressbar {
              height: 160px;
              width: 160px;
              .CircularProgressbar-path {
                stroke: $primary-color;
              }
              .CircularProgressbar-trail {
                stroke: $primary-color-opacity-big;
                // stroke: $grey-even-lighter;
              }
              text {
                fill: $primary-color;
              }
            }
            &__input {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              // border: 1px solid $grey;
            }
          }
        }
      }
    }
  }
}
