@import "../../scss/vars";

.quiz-student__matching {
    width: 100%;
    text-align: left;
    .title {
        font-size: 20px;
        color: grey;
        margin-bottom: 10px;
    }
    .matched {
        position: relative;
        width: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
        .list {
            width: 100%;
            display: flex;
            flex-direction: column;
            .item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
                align-items: center;
                transition: 0.3s ease-in-out;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.03);
                }
                span {
                    width: 48%;
                    border-radius: $border-radius;
                    box-shadow: $box-shadow;
                    padding: 8px 20px;
                }
                svg {
                    transform: rotate(180deg);
                }
                &.wrong {
                    span {
                        background-color: #ff4d4d;
                        color: white;
                    }
                }
        
                &.correct {
                    span {
                        background-color: #99F7AB;
                    }
                }
            }
        }
        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: rgba(128, 128, 128, 0.296);
        }   
    }
    .list {
        width: 100%;
        justify-content: space-between;
        display: flex;
        &-items {
            width: 48%;
            display: flex;
            flex-direction: column;
            span {
                width: 100%;
                box-shadow: $box-shadow;
                border-radius: $border-radius;
                margin: 10px 0;
                padding: 8px 20px;
                text-align: left;
                transition: 0.3s ease-in-out;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.02);
                }
                &.selected {
                    background-color: rgba(128, 128, 128, 0.235);
                }
            }
        }
    }
}