@import "../../../scss/vars.scss";

.inspector {
  &__back {
    margin-bottom: 30px;
  }
  &-item {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    margin: 20px 0;
    align-items: center;
    .color-input {
      align-self: flex-end;
      border: none;
      width: 35px;
      height: 35px;
      outline: none;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      padding: 0;
      margin: 0;
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
    select {
      flex: 1;
    }
    &__exam-stats {
      &-draggable {
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 20px 0;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}
