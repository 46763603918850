@import "src/scss/vars";

.print-grade-item {
  position: relative;
  width: 40px;
  height: 40px;

  @include md {
    margin-left: 0;
    display: none;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    z-index: 1;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      box-shadow: none;
      border-radius: 50%;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    .config-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;
      margin-bottom: 30px;
      &__label {
        font-weight: 300;
        color: $grey;
      }
      input {
        width: 100%;
      }
    }
    .config-type {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      &__label {
        font-weight: 300;
        color: $grey;
      }
      &__list {
        display: flex;
        gap: 20px;
        &-item {
          padding: 20px 40px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          border: 2px solid white;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
          &.active {
            border: 2px solid $primary-color;
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          &-title {
            font-weight: 300;
            color: $grey;
            font-size: 18px;
          }
        }
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      position: sticky;
      bottom: 0;
      height: 50px;
      margin-top: auto;
      button {
        background-color: white;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
    .target {
      margin-top: 30px;
      padding-bottom: 0;
      &__select {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 10px;
        @include md() {
          flex-direction: column;
        }
        .item {
          display: flex;
          align-items: center;
          margin-right: 50px;
          @include md() {
            margin-bottom: 15px;
          }
          span {
            font-size: 17px;
            margin-left: 8px;
          }
          &:hover {
            cursor: pointer;
            > .target__select-radio {
              border: 4px solid $primary-color;
              transform: scale(1.08);
            }
          }
        }
        &-radio {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 4px solid white;
          transition: 0.2s ease-in-out;
          box-shadow: $box-shadow-dark;
          background-color: white;
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
