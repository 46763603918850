@import "../../../../scss/vars.scss";

.add-users-slide {
  height: 80vh;
  overflow-y: auto;
  padding-bottom: 200px;
  &__title {
    display: block;
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px;
    color: $grey;
  }
  &__label {
    margin-bottom: 15px;
    display: block;
    color: $grey;
    font-weight: 300;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
    padding: 20px;
    &-search {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      input {
        width: 400px;
      }
    }
    &-item {
      padding: 15px 5px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: calc(20% - 20px);
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      .action {
        position: absolute;
        top: -10px;
        right: -10px;
      }
      .profile-img {
        width: 70px;
        height: 70px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .name {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $grey;
      }
    }
  }
}
