@import "../../../scss/vars";

.event-comment {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  &.multiple {
    .event-comment__section {
      width: 31%;
      @include md {
        width: 100%;
      }
    }
  }
  @include md {
    justify-content: unset;
    flex-direction: column;
    gap: 10px;
  }
  &__section {
    position: relative;
    width: 100%;
    @include md {
      width: 100%;
    }
    textarea {
      resize: none;
      width: 90%;
      height: 100px;
      margin-top: 3px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    &:hover {
      .event-comment__item-edit {
        display: flex;
      }
    }
    &-title {
      font-weight: 300;
      color: $grey;
      font-size: 14px;
    }
    &-comment {
      font-weight: 300;
      color: $grey;
      padding: 10px 20px;
      border-radius: $border-radius;
      border: 1px solid $grey-even-lighter;
      word-break: break-word;
    }
    &-edit {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      box-shadow: $box-shadow;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      display: none;
      transition: 0.3s ease-in-out;
      @include md {
        display: flex;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  &__new {
    display: flex;
    flex-direction: column;
    &-title {
      font-weight: 300;
      color: $grey;
      font-size: 14px;
    }
    &-textarea {
      display: block;
      margin-top: 5px;
      border: 1px solid $grey-lighter;
      border-radius: $border-radius;
      outline: none;
      resize: none;
      padding: 10px;
      font-size: 16px;
      color: $grey;
      height: 80px;
    }
    &-input {
      display: block;
      margin-top: 5px;
      width: 100%;
      position: relative;
      textarea {
        width: 100%;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
        position: absolute;
        right: 10px;
        bottom: 7px;
        &:hover {
          cursor: pointer;
        }
      }
      input {
        width: 100%;
      }
    }
    &-save {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      &.edit {
        justify-content: space-between;
      }
      button {
        padding: 6px 35px;
      }
    }
  }
}
