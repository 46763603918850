@import "../../scss/vars.scss";
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: $grey-light;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: $border-radius;
  letter-spacing: 2.3px;
}
