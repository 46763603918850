@import "src/scss/vars";

.curriculum-answers {
  &__list {
    &-item {
      margin: 20px 0;
      border: 1px solid $grey-even-lighter;
      border-radius: $border-radius;
      padding: 20px;
      position: relative;
      .header {
        display: flex;
        align-items: center;
        text-decoration: none;
        &__actions {
          position: absolute;
          right: 20px;
          top: 10px;
        }
        &__image {
          height: 40px;
          width: 40px;
          img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &__info {
          margin-left: 10px;
          text-decoration: none;
          &-name {
            display: block;
            span {
              color: $grey;
              &:nth-child(2) {
                margin-left: 7px;
              }
            }
          }
          &-date {
            span {
              font-size: 14px;
              color: $grey;
              font-weight: 200;
            }
          }
        }
      }
      .content {
        &__text {
          margin-top: 10px;
          span {
            font-weight: 300;
            font-size: 17px;
          }
        }
        &__files {
          margin-top: 10px;
          &-item {
            width: 80px;
            height: 80px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: $border-radius;
              transition: 0.3s ease-in-out;
              &:hover {
                cursor: pointer;
                opacity: 0.8;
                transform: scale(1.03);
              }
            }
          }
        }
      }
      .professor-comments {
        &__add {
          margin-top: 20px;
          .comment__input {
            display: flex;
            align-items: center;
            position: relative;
            input {
              border: none;
              border: 1px solid $grey-even-lighter;
              border-radius: $border-radius;
              padding: 5px 45px 5px 20px;
              width: 100%;
              outline: none;
              color: $grey;
            }
            svg {
              margin: 0 !important;
              padding: 0 !important;
              position: absolute;
              right: 10px;
              bottom: 0px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        &__content {
          margin-top: 20px;
          padding-left: 30px;
          border-top: 1px solid $grey-even-lighter;
          padding-top: 10px;
          &-title {
            width: 100%;
            position: relative;
            span {
              display: flex;
              padding-right: 20px;
              width: fit-content;
              font-size: 12px;
              color: $grey;
              font-weight: 200;
              position: relative;
              z-index: 2;
              background-color: white;
            }
            &:after {
              z-index: 1;
              height: 1px;
              background-color: $grey-lighter;
              width: 100%;
              position: absolute;
              content: "";
              top: 10px;
              left: 0;
            }
          }
          &-comment {
            // padding-left: 30px;
            position: relative;
            margin-top: 10px;
            .header {
              display: flex;
              align-items: center;
              text-decoration: none;
              position: relative;
              &__actions {
                position: absolute;
                top: 10px;
                right: 10px;
                svg {
                  margin: 0 !important;
                  padding: 0 !important;
                  transition: 0.3s ease-in-out;
                  &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                  }
                }
                &-options {
                  display: flex;
                  justify-content: space-between;
                  position: absolute;
                  bottom: -30px;
                  right: 20px;
                  border-radius: 5px;
                  box-shadow: $box-shadow;
                  span {
                    padding: 5px 10px;
                    border-radius: 5px;
                    color: $grey;
                    font-weight: 300;
                    &:hover {
                      cursor: pointer;
                      background-color: $grey-even-lighter;
                    }
                  }
                }
              }
              &__image {
                height: 40px;
                width: 40px;
                img {
                  border-radius: 50%;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
              &__info {
                margin-left: 10px;
                &-name {
                  display: block;
                  span {
                    color: $grey;
                    &:nth-child(2) {
                      margin-left: 7px;
                    }
                  }
                }
                &-date {
                  span {
                    font-size: 14px;
                    color: $grey;
                    font-weight: 200;
                  }
                }
              }
            }
            .comment-text {
              margin-top: 10px;
              color: $grey;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
