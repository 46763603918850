@import "src/scss/vars";

.extra-filters {
  padding: 0 20px 30px;
  &__periods {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .period-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      span {
        font-weight: 300;
        color: $grey;
      }
    }
  }
  &__config {
    &-container {
      margin-top: 10px;
      &.half {
        display: flex;
        justify-content: space-between;
      }
      .container-half {
        width: 48%;
      }
    }
  }
  .score-item {
    // box-shadow: $box-shadow;
    // border-radius: $border-radius;
    // border: 1px solid red;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    &__max {
      text-align: center;
      span {
        font-size: 11px;
        color: $grey;
        font-weight: 300;
      }
    }
    span {
      color: $grey;
      font-weight: 300;
      margin-bottom: 10px;
      text-align: center;
    }
    &__input {
      display: flex;
      //box-shadow: $box-shadow;
      border: 1px solid $grey-lighter;
      border-radius: 30px;
      padding: 10px 7px;
      svg {
        padding: 0 !important;
        margin: 0 !important;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
          background: $primary-color !important;
        }
      }
      input {
        width: 40px;
        border: none;
        outline: none;
        // border: 1px solid black;
        // border-bottom: 1px solid $grey-even-lighter;
        display: block;
        margin: 0 5px;
        text-align: center;
        color: $grey;
        -moz-appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
