@import "src/scss/vars";

.dashboard__sections {
  .school-section {
    padding: 20px 20px 0;
    &__general {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      flex-wrap: wrap;
      .item {
        display: flex;
        align-items: center;
        @include md {
          margin-bottom: 20px;
        }
        &__icon {
          margin-right: -15px;
          position: relative;
          z-index: 1;
          border-radius: 50%;
          box-shadow: $box-shadow;
          svg {
            margin: 0 !important;
          }
          @include md {
            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
        &__details {
          padding: 15px 20px 15px 35px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          display: flex;
          flex-direction: column;
          @include md {
            padding: 15px 15px 15px 25px;
          }
          span {
            text-align: left;
          }
          .title {
            color: $grey;
            font-weight: 300;
          }
          .label {
            color: $primary-color;
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 1px;
            // &.yellow {
            //   color: $secondary-color;
            // }
            // &.green {
            //   color: $green-color;
            // }
            // &.red {
            //   color: #ff9797;
            // }
          }
        }
      }
    }
  }
}
