@import "../../../scss/vars.scss";

.chapters {
  margin-bottom: 20px;
  margin-top: 20px;
  @include md {
    padding: 0 20px;
  }
  &__header {
    span {
      font-weight: 300;
      color: $grey;
      font-size: 20px;
    }
  }
  &__list {
    display: flex;
    margin-top: 10px;
    width: 100%;
    flex-wrap: wrap;
    &-add {
      border-radius: 15px;
      box-shadow: $box-shadow;
      padding: 7px 20px;
      margin-right: 20px;
      transition: 0.5s ease-in-out;
      width: 300px;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      height: 38px;
      &-wrapper {
        display: flex;
        input {
          border: none;
          outline: none;
          width: 210px;
          // margin: 0 13px;
        }
      }
      &.closed {
        max-width: 61px;
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
      &.open {
        max-width: 300px;
      }
      &-action {
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &-item {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 7px 20px;
      margin-right: 20px;
      margin-bottom: 15px;
      position: relative;
      span {
        color: $grey;
        font-weight: 300;
      }
      &:hover {
        .chapters__list-item-actions {
          display: block;
        }
      }
      &-actions {
        display: none;
        position: absolute;
        top: -14px;
        right: -2px;
        svg {
          border: 50%;
          height: 25px;
          width: 25px;
          margin: 0 !important;
          padding: 0 !important;
          background: $red;
          box-shadow: $box-shadow;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.08);
          }
        }
      }
    }
  }
}
