@import "../../scss/vars";

.quiz-student {
  height: calc(100vh - 60px);
  margin-top: 20px;
  overflow-x: hidden;
  transition: background-color 0.5s ease-in-out;
  &__info {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 80px;
    left: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    @include md {
      left: 10px;
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      svg {
        margin: 0 !important;
        padding: 0 !important;
        opacity: 0.3;
      }
      span {
        margin-top: 15px;
        display: block;
        color: $grey-light;
        font: 300;
        font-size: 18px;
      }
    }
  }
  &__return {
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    @include md {
      bottom: 100px;
    }
  }
  &__chip {
    position: fixed;
    top: 80px;
    right: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
    &-close {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .editor-read-only {
    width: 100%;
  }
  &.submitted {
    background-color: $grey-even-lighter;
    margin: 0;
    height: 100%;
    @include md {
      background-color: white;
    }
  }
  &__quiz {
    transition: 0.6s ease-in-out;
    transform: translateX(0);
    height: 100%;
    overflow: hidden;
    &.final {
      overflow-y: auto;
    }
    &.hide {
      transition: 0.6s ease-in-out;
      transform: translateX(110vw);
    }
  }

  &__conf {
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.6s ease-in-out;
    padding-top: 20px;
    @include md() {
      z-index: 10;
      padding-bottom: 200px;
    }
    &.hide {
      transition: 0.6s ease-in-out;
      transform: translateX(-110vw);
    }
    &-main {
      margin-top: 110px;
      margin-left: 355px;
      width: calc(100vw - 390px);
      display: flex;
      flex-direction: column;
      text-align: left;
      @include md() {
        width: auto;
        margin-left: unset;
        padding: 0 20px;
        padding-right: 10px;
      }
      .student-header {
        display: flex;
        margin-bottom: 30px;
        position: sticky;
        top: 80px;
        @include md {
          height: 60px;
        }
        &__search {
          width: 100%;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
          padding: 25px 20px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          background-color: white;
          height: 60px;
          &.closed {
            max-width: 60px;
            input {
              display: none;
            }
          }
          @include md {
            transition: 0.3s ease-in-out;
            max-width: calc(100% - 70px);
            margin-right: 10px;
          }
          input {
            width: 100% !important;
            border-radius: none;
            border: none;
            box-shadow: none;
            outline: none;
            display: block;
            margin-right: 20px;
            color: $grey;
            font-weight: 300;
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        &__filters {
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          width: 100%;
          background-color: white;
          max-height: 60px;
          height: 70vh;
          max-width: 60px;
          display: none;
          overflow-y: hidden;
          position: relative;
          @include md {
            display: block;
          }
          &.open {
            max-width: calc(100% - 70px);
            max-height: 70vh;
            .professor-exams__mobile-filters-preview {
              justify-content: flex-end;
              padding-right: 20px;
            }
          }
          &.closed {
            max-width: 60px;
            overflow: hidden;
          }
          &-preview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            height: 60px;
            span {
              color: $grey;
              font-weight: 200;
              font-size: 14px;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;
              padding: 0 20px;
              svg {
                margin-left: 10px !important;
              }
            }
            svg {
              padding: 0 !important;
              margin: 0 !important;
            }
          }
        }
      }
      &-header {
        display: flex;
        margin-bottom: 30px;
        position: sticky;
        top: 80px;
        @include md {
          height: 60px;
        }
        .navigate {
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          padding: 18px 20px;
          width: calc(100% - 100px);
          margin-right: 20px;
          display: flex;
          align-items: center;
          transition: 0.3s ease-in-out;
          background-color: white;
          @include md {
            max-width: calc(100% - 140px);
            padding: 18px 10px;
            margin-right: 10px;
            span {
              font-size: 12px;
            }
          }
          &.closed {
            @include md {
              max-width: 60px;
              span {
                display: none;
              }
            }
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          span {
            color: $grey;
            font-weight: 300;
            display: block;
            margin-left: 15px;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
        }
        .search {
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          width: 100%;
          background-color: white;
          @include md {
            height: 60px;
          }
          .search-close {
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.15);
            }
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          &.closed {
            max-width: 10%;
            @include md {
              max-width: 60px;
            }
            &:hover {
              cursor: pointer;
              transform: scale(1.08);
            }
            input {
              padding: 0;
              overflow: hidden;
              max-width: 0;
            }
          }
          &.open {
            max-width: 50%;
            @include md {
              max-width: calc(100% - 140px);
            }
            input {
              margin-right: 20px;
            }
          }
          input {
            width: 80%;
            display: block;
            box-shadow: unset;
            // border-bottom: 1px solid $grey-even-lighter;
            border-radius: unset;
            padding: 5px 2px;
            color: $grey;
            font-weight: 300;
            @include md {
              width: 72%;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
        .quiz-mobile-filters {
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          width: 100%;
          background-color: white;
          max-height: 60px;
          height: 70vh;
          max-width: 60px;
          margin-left: 20px;
          display: none;
          overflow-y: hidden;
          position: relative;
          @include md {
            display: block;
            margin-left: 10px;
          }
          &.open {
            max-width: calc(100% - 140px);
            max-height: 70vh;
            .professor-exams__mobile-filters-preview {
              justify-content: flex-end;
              padding-right: 20px;
            }
          }
          &.closed {
            max-width: 60px;
            overflow: hidden;
          }
          &__preview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            height: 60px;
            span {
              color: $grey;
              font-weight: 200;
              font-size: 14px;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;
              padding: 0 20px;
              svg {
                margin-left: 10px !important;
              }
            }
            svg {
              padding: 0 !important;
              margin: 0 !important;
            }
          }
        }
      }
      &-edit {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 50px 50px;
        button {
          width: 100%;
        }
      }
      .chapters {
        // padding: 0 50px;
        padding: 0;
        &__title {
          width: 100%;
          // text-align: center;
          padding-bottom: 50px;
          span {
            font-weight: 400;
            color: $grey;
          }
        }
        &__item {
          // box-shadow: 0 5px 15px 4px rgb(0 0 0 / 10%);
          box-shadow: $box-shadow;
          padding: 15px 40px;
          border-radius: $border-radius;
          margin: 0 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.3s ease-in-out;
          @include md {
            padding: 15px 20px;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.01);
            @include md {
              transform: none;
            }
          }
          &-title {
            display: flex;
            align-items: center;
            @include md() {
              width: 80%;
            }
            span {
              padding: 0;
              margin: 0;
              font-size: 18px;
              color: $grey;
            }
          }
          &-select {
            border: 2px solid $primary-color;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            @include md() {
              align-self: center;
            }
            &.active {
              background-color: $primary-color;
            }
          }
        }
      }
      &-start {
        position: fixed;
        bottom: 22px;
        right: 120px;
        display: none;
        &.active {
          display: flex;
          @include md {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            padding: 0 15px;
            right: 0;
            gap: 15px;
          }
        }
        .chatgpt-start {
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include md {
            padding-right: 10px;
            margin: 0 !important;
          }
          svg {
            margin-left: 10px !important;
          }
        }
        @include md {
          right: 100px;
          bottom: 170px;
          display: flex;
          flex-direction: column;
          .cta {
            font-size: 15px;
            padding: 12px 20px;
            &:nth-child(1) {
              width: 57%;
            }
            &:nth-child(2) {
              width: 38%;
            }
          }
        }
      }
    }
    &-select {
      &-list {
        list-style: none;
        padding: 0;
        margin-top: 20px;
      }
      &.hide {
        transform: translateX(-100vw);
        transition: 0.6s ease-in-out;
      }
    }
    &-submit {
      transform: translateX(0);
      transition: 0.6s ease-in-out;
      text-align: center;
      &.hide {
        transform: translateX(100vw);
      }
    }
  }
  .slick {
    &-list {
    }
    &-arrow {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgba(128, 128, 128, 0.2);
      position: absolute;
      z-index: 1000000;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(128, 128, 128, 0.3);
      }
      &:before {
        display: none;
      }
      &:after {
        font-size: 20px;
        color: rgb(37, 37, 37);
        height: 20px;
        width: 20px;
      }
      &.slick-disabled {
        background-color: rgba(128, 128, 128, 0.05);
        &:after {
          color: gray;
        }
      }
      &.slick-prev {
        left: 5vh;
        &:after {
          content: "<";
        }
      }
      &.slick-next {
        right: 3vh;
        &:after {
          content: ">";
        }
      }
    }
    &-dots {
      @include md() {
        line-height: 3;
        bottom: unset;
      }
      li {
        transition: 0.3s ease-in-out;
        margin: 0 13px;
        .quiz-student__carousel-dots-item {
          display: flex;
          justify-content: center;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          align-items: center;
          box-shadow: 0 5px 15px 4px rgb(0 0 0 / 10%);
          font-size: 15px;
          padding-bottom: 2px;
          background: white;
          transition: all 200ms ease-in-out;

          &.disable {
            display: none;
          }
          &.answered {
            outline: 5px solid fade-out(white, 0.6);
            background-color: lighten($primary-color, 20);
            color: white;
            //color: white;
          }
          &.wrong {
            outline: 5px solid #d55672;
          }
          &.correct {
            outline: 5px solid #99f7ab;
          }
        }
        &.slick-active {
          transform: scale(1.25);
          margin: 0 22px;

          .quiz-student__carousel-dots-item {
            //outline: 5px solid fade-out(white, .6);
            background-color: $primary-color;
            color: white;

            background-color: $primary-color;
            outline: 5px solid fade-out($primary-color, 0.6);

            //&:not(.answered) {
            //    background-color: $primary-color;
            //    outline: 5px solid fade-out($primary-color, .6);
            //}

            &.answered {
              //outline: 5px solid fade-out($primary-color, .6)
            }
            &.wrong {
              background: #d55672;
              color: white;
            }
            &.correct {
              background: #99f7ab;
              color: white;
            }
          }
        }
      }
    }
  }
  &__slider {
    padding: 0px 0 !important;
    color: white;
    &-item {
      padding: 250px 0;
    }
  }
  &__quizes-container {
    margin-top: 100px;
    position: relative;
    @include md {
      margin-top: 10px;
      padding-bottom: 150px;
    }
    .slider-controls {
      display: flex;
      justify-content: center;
      &__arrow {
        border: none;
        outline: none;
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: 0 5px 10px 4px rgb(0 0 0 / 7%);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.25s ease-in-out;
        // display: none;
        &:after {
          // position: absolute;
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 3px;
        }
        &:hover {
          transform: scale(1.1);
          color: rgba(0, 0, 0, 0.462);
          background-color: rgba(128, 128, 128, 0.09);
        }
        &.prev {
          // margin-right: 40px;
          &:after {
            content: "<";
          }
        }
        &.next {
          margin-left: 40px;
          &:after {
            content: ">";
          }
        }
      }
    }
  }
  &__submit {
    border: 2px solid #6225e6;
    background-color: white;
    color: #6225e6;
    border-radius: 1.625rem;
    outline: none;
    background-color: transparent;
    padding: 7px 25px 8px;
    transition: 0.2s ease-in-out;
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 15px;
    &-message {
      margin-top: 30px;
      transform: translateY(200px);
      transition: 1s ease-in-out;
      opacity: 0;
      &.show {
        display: block;
        transform: translateY(0);
        opacity: 1;
      }
      span {
        font-size: 25px;
        font-weight: 500;
      }
    }
    &:after {
      content: "SUBMIT";
    }
    &:hover {
      background-color: #6225e6;
      border-color: white;
      color: white;
      cursor: pointer;
    }
    &.submited {
      animation: submitBtnAnimation 0.7s forwards;
      padding: 0;
      &:hover {
        cursor: unset;
        border: 2px solid #6225e6;
        background-color: transparent;
      }
      &:after {
        content: "";
      }
      @keyframes submitBtnAnimation {
        0% {
          width: 170px;
          height: 50px;
          border-radius: 1.625rem;
          border: 2px solid #6225e6;
        }

        100% {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          border: 5px solid #d5d5d5;
        }
      }
    }
    &.progress {
      border: 1px solid #71717171;
      &:hover {
        border: 1px solid #71717171;
      }
      &:after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        border-radius: 50%;
        border-radius: 100%;
        background-color: #e53b3b;
        // animation: scoreAnimation 8s forwards;
        z-index: -2;
      }
      &:before {
        width: 96%;
        height: 96%;
        position: absolute;
        content: "";
        top: 2%;
        left: 2%;
        border-radius: 50%;
        background-color: white;
        z-index: -1;
      }
    }
    &-score {
      width: 0 !important;
      overflow: hidden;
      font-size: 50px;
      transition: 0.7s ease-in-out;
      opacity: 0;
      &.show {
        display: block;
        width: 100% !important;
        // transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &__results {
    max-height: 5000vh;
    transition: 4s ease-in-out;
    overflow-y: hidden;
    &.hide {
      max-height: 0vh;
    }
    &-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 70px;
      @include md {
        padding-left: 0;
      }
      li {
        width: 80vw;
        margin-top: 40px;
        // box-shadow: 0 5px 15px 4px rgb(0 0 0 / 10%);
        padding: 30px 40px;
        border-radius: 30px;
        list-style: none;
        display: flex;
        align-items: end;
        justify-content: space-between;
        @include md {
          width: 100%;
          display: block;
        }
        .results {
          &-card {
            padding: 25px 35px;
            box-shadow: 0 5px 15px 4px rgb(0 0 0 / 10%);
            border-radius: 20px;
            width: 45%;
            text-align: left;
            @include md {
              width: 100%;
            }
            &.correct {
              background-color: rgb(74, 255, 47, 0.21);
              position: relative;
              @include md {
                margin-top: 10px;
              }
              &:after {
                position: absolute;
                top: 50%;
                left: -6vw;
                background-color: black;
                width: 5vw;
                height: 1px;
                content: "";
                opacity: 0.4;
                @include md {
                  border-style: solid;
                  border-right: none;
                  border-top: none;
                  border-width: 1px;
                  border-color: black;
                  height: 5vw;
                  background-color: transparent;
                }
              }
            }
            &.wrong {
              background-color: rgba(255, 67, 67, 0.21);
            }
            &__question {
              font-weight: 500;
              font-size: 20px;
              padding-bottom: 20px;
              position: relative;
              &:after {
                position: absolute;
                width: 100%;
                height: 1px;
                content: "";
                background: black;
                left: 0;
                bottom: 0;
                opacity: 0.4;
              }
            }
            &__answer {
              margin-top: 15px;
              display: flex;
              justify-content: space-between;
              &-icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding-top: 3px;
                box-shadow: 0 5px 8px 4px rgb(0 0 0 / 10%);
                &.correct {
                  // border: 1px solid rgb(74, 255, 47, 0.51);
                  color: rgba(15, 110, 0, 0.81);
                  background-color: rgb(74, 255, 47, 0.21);
                }
                &.wrong {
                  // border: 1px solid rgba(255, 67, 67, 0.51);
                  color: rgba(135, 0, 0, 0.738);
                  background-color: rgba(255, 67, 67, 0.21);
                }
              }
            }
          }
        }
        .results-question {
          margin-bottom: 15px;
          text-align: left;
        }
        .item {
          width: 100%;
          &:nth-child(1) {
            background-color: green;
          }
          &:nth-child(2) {
            background-color: red;
          }
        }
      }
    }
  }

  &__timer-wrapper {
    margin: 40px 90px 0 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    padding-top: 20px;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 12px;
      border-radius: 12px;
      background: $primary-color;
      transition: all 0.2s ease-in-out;
    }

    .timer {
      span {
        font-size: 24px;
        color: $primary-color;
      }
    }
  }

  &__close-icon {
    position: absolute;
    right: 40px;
    top: -12px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    cursor: pointer;
    z-index: 5;
    background: white;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: $primary-color;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: $primary-color;
  }

  &__success {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    //border: 2px solid #6225e6;
    z-index: 999999999;
    overflow: hidden;
    .success-animation {
      margin: 100px auto;
    }

    .checkmark {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #4bb71b;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #4bb71b;
      animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
      position: relative;
      top: 5px;
      right: 5px;
      margin: 0 auto;
    }
    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scale {
      0%,
      100% {
        transform: none;
      }

      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
      }
    }
  }

  &__wrong {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    //border: 2px solid #6225e6;
    z-index: 999999999;
    overflow: hidden;

    .error-animation {
      margin: 100px auto;
    }

    .crossmark {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #ff3b3b; // Red color
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #ff3b3b;
      animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
      position: relative;
      top: 5px;
      right: 5px;
      margin: 0 auto;
    }

    .crossmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #ff3b3b; // Red color
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .crossmark__cross {
      transform-origin: 50% 50%;
      stroke-dasharray: 56; // Adjusted for the X's length
      stroke-dashoffset: 56;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scale {
      0%,
      100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 30px #ff3b3b; // Red color fill
      }
    }
  }
}
