.progress-bar {
				position: fixed;
				z-index: 999999;
				width: 100vw;
				height: 100vh;
				background: rgba(255, 255, 255, 0.35);
				backdrop-filter: blur(10px);
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
}