@import "../../scss/vars";
@import "../../scss/main";

.exams {
  margin-top: 130px;
  margin-left: 355px;
  width: calc(100vw - 390px);
  @include md {
    margin-left: 0;
    width: 100vw;
    padding: 0 20px;
    padding-bottom: 100px;
  }
  &__pagination {
    padding: 40px 0;
  }
  &__card {
    position: relative;
    width: 22%;
    height: 170px;
    border-radius: $border-radius;
    margin: 12px 0;
    z-index: 1;
    padding: 0 !important;
    @include md {
      width: 100%;
      margin-right: 0;
    }
    &.parent {
      height: 220px;
    }
    &.exam {
      border-left: 6px solid $secondary-color;
    }
    &.online-exam {
      border-left: 6px solid $blue-color;
    }
    &.test {
      border-left: 6px solid $green-color;
    }
    &.is-expanded {
      z-index: 999;
    }
    &.is-collapsed {
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.04);
      }
    }
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s ease;
      background: #fff;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      text-align: left;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      .professor-event-card {
        height: calc(100% - 100px);
        overflow-y: auto;
        @include md {
          height: calc(100% - 80px);
        }
      }
      .student-event-card {
        overflow-y: auto;
      }
      .exams__card-actions {
        display: flex;
        justify-content: space-between;
        height: 50px;
        padding: 0 30px;
        margin-bottom: 20px;
        @include md {
          height: 50px;
          margin-bottom: 10px;
          button {
            padding: 10px 0;
            width: 45%;
          }
        }
      }
      &.is-collapsed {
        padding: 13px 15px 5px;
        height: 100%;
        .exams__card-content-closed {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .closed__details {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .detail-item {
            display: flex;
            align-items: center;
            &.child {
              margin: 5px 0;
            }
            img {
              height: 35px;
              width: 35px;
              object-fit: cover;
              border-radius: 50%;
              box-shadow: $box-shadow;
            }
            .CircularProgressbar {
              height: 30px;
              width: 30px;
              margin-right: 10px;
              .CircularProgressbar-path {
                stroke: $primary-color;
              }
              text {
                font-size: 32px;
                fill: $primary-color;
              }
            }
            svg {
              height: 35px;
              width: 35px;
              border-radius: $border-radius;
              margin: 0 !important;
              // box-shadow: $box-shadow;
            }
            span {
              color: $grey;
              font-weight: 300;
              margin-left: 5px;
              font-size: 14px;
            }
          }
        }
        .title__wrapper {
          width: 100%;
          position: relative;
          &:after {
            position: absolute;
            bottom: -15px;
            content: "";
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $grey-even-lighter;
          }
        }
        .title {
          font-size: 18px;
          color: $grey;
          font-weight: 400;
          position: relative;
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &.is-expanded {
        position: absolute;
        top: -20vh;
        left: -20vw;
        height: auto;
        // padding: 20px 30px;
        // overflow-y: auto;
      }
    }
  }

  .events__item-create {
    width: 100%;
  }
}
