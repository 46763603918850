@import "src/scss/vars";

.expenses-section {
  // min-height: 100vh;
  margin-top: 30px;
  &__content {
    .expenses-timeline {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      &__item {
        width: 100%;
        padding: 20px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        display: flex;
        gap: 40px;
        align-items: center;
        justify-content: space-between;
        .item-details {
          display: flex;
          align-items: center;
        }
        &-actions {
          display: flex;
          gap: 15px;
          svg {
            margin: 0 !important;
            padding: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              cursor: pointer;
            }
          }
        }
        &-type {
          font-size: 18px;
          color: $grey;
          margin-right: 10px;
        }
        &-date {
          font-weight: 300;
          color: $grey;
          margin-right: 10px;
        }
        &-ammount {
          display: flex;
          gap: 7px;
          align-items: center;
          span {
            &:nth-child(1) {
              font-weight: 300;
              color: $grey;
              font-size: 14px;
            }
            &:nth-child(2) {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
  &__add {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
    &-item {
      width: 29%;
      position: relative;
      height: 80px;
      &-label {
        margin-bottom: 10px;
        display: block;
        color: $grey;
        font-weight: 300;
      }
      &.save {
        width: 13%;
      }
    }
    &-select {
      position: relative;
      .dropdown {
        &-preview {
          height: 28px;
          cursor: pointer;
        }
        &-content {
          position: absolute;
          top: 28px; // Position below the preview
          left: 0;
          right: 0;
          max-height: 0;
          overflow: hidden;
          background-color: white;
          z-index: 1000; // Ensure it stays on top
          transition: max-height 0.3s ease-in-out;

          border-radius: $border-radius;
          &.open {
            max-height: 400px;
            box-shadow: $box-shadow;
          }
          &__item {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            &.active {
              background-color: $grey-even-lighter;
            }
            &:last-child {
              border-bottom: none;
            }
            &.new {
              display: flex;
              justify-content: space-between;
              input {
                flex: 1;
              }
              button {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
