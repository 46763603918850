@import "../../scss/vars";

.quiz-student__answers {
  list-style: none;
  padding-left: 0;
  &-item {
    .button-multiple-toggle {
      .wrapper {
        box-shadow: unset;
        border: 1px solid $grey-even-lighter;
      }
    }
    margin: 15px 0px;
    border-radius: 0.85rem;
    padding: 5px 20px;
    box-shadow: $box-shadow;
    transition: 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.01);
    }
    .details {
      display: flex;
      align-items: center;
      padding: 5px;
      &.correct-wrong {
        justify-content: space-between;
        width: 100%;
      }
      span {
        @include md {
          width: calc(100% - 40px);
        }
      }
    }
    &-check {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid gray;
      transition: all 200ms ease-in-out;

      &.checked {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        transition: all 250ms ease-in-out;
      }
      &.multiple {
        background-color: unset;
        border-radius: 2px;
        border: 2px solid gray;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }

    &.wrong {
      background-color: #ff4d4d;
      color: white;

      .checked {
        background-color: darken(#ff4d4d, 20);
        border-color: white;
      }
    }

    &.correct {
      background-color: #99f7ab;

      .checked {
        background-color: darken(#99f7ab, 20);
        border-color: white;
      }
    }
  }
}
