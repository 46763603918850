@import "../../../scss/vars.scss";

.editor-preview {
  &__section {
    position: relative;
    border: 1px solid transparent;
    &-delete {
      background-color: $red;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      // left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      right: -20px;
      &.delete-section {
        right: unset;
        left: 30px;
        top: -10px;
        z-index: 9999999;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &-drag {
      background-color: $primary-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      border: 1px solid $primary-color !important;
      .editor-preview__section-drag {
        display: flex;
      }
      .delete-section {
        display: flex;
      }
    }
  }
  &__wrapper {
    width: 700px;
    position: relative;
    overflow: visible;
  }
}
