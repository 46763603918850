@import "src/scss/vars";

.missing-tuition {
  margin-top: 40px;
  .slider-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 300;
      font-size: 20px;
      color: $grey;
    }
  }
  .student-lists {
    display: flex;
    justify-content: space-between;
    .list-title,
    .list-label {
      font-weight: 300;
      color: $grey;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      svg {
        margin: 0 !important;
        padding: 0 !important;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .list-title {
      margin-bottom: 20px;
    }
    .missing-tuition__users-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 47%;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 0 20px;
      padding: 0 -20px;
    }
    .list-user-item {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 10px;
      text-decoration: none;
      color: $grey;
      width: 100%;
      transition: 0.3s ease-in-out;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        transform: scale(1.02);
      }
      .personal-info {
        padding-right: 10px;
        border-right: 1px solid $grey-lighter;
        &__img {
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &__name {
          display: flex;
          flex-direction: column;
        }
      }
      .details {
        padding-left: 10px;
        &__name {
          display: flex;
          gap: 5px;
          span {
            font-weight: 500;
          }
        }
        &__paid {
          font-weight: 300;
          color: $grey-light;
          font-size: 15px;
          span {
            font-weight: 300;
            color: $grey-light;
          }
          .details__paid-bold {
            margin-left: 5px;
            color: $grey;
            font-weight: 400;
            font-size: 17px;
          }
        }
      }
    }
    .users-list {
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      margin: 0 -20px;
      padding: 0 40px;
      width: 45%;
    }
  }
}
