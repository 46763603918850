@import "../../../scss/vars.scss";

.filter-users {
  width: 300px;
  height: 300px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  margin-left: 15px;
  max-width: 60px;
  max-height: 60px;
  transition: 0.3s ease-in-out;
  background-color: white;
  position: relative;
  overflow: hidden;
  @include md {
    display: none;
  }
  &.is-collapsed {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  &.is-expanded {
    max-width: 300px;
    max-height: 300px;
  }
  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    transition: 0.3s ease-in-out;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  &__preview {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  &__content {
    margin-top: 40px;
    padding: 0 20px;
    .content-title {
      font-weight: 300;
      color: $grey;
      display: block;
      margin-bottom: 10px;
    }
    .content__options {
      display: flex;
      flex-direction: column;
      gap: 6px;
      &-item {
        border: 1px solid $grey-lighter;
        border-radius: $border-radius;
        padding: 6px 12px;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.02);
          //   background-color: $grey-even-lighter;
        }
        span {
          color: $grey;
          font-weight: 300;
        }
        &.active {
          border: 1px solid $primary-color;
        }
      }
    }
  }
}
