@import "../../scss/vars.scss";

.chat-popups {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  margin-right: 100px;
  &__chats {
    display: flex;
    align-items: flex-end;
  }
}
