@import "src/scss/vars";

.template-item {
  position: relative;
  width: 200px;
  height: 300px;
  .template-preview-outer {
    pointer-events: none;
  }
  @include md {
    margin-left: 0;
    display: none;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    z-index: 1;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease;
    // background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      // justify-content: center;
      border-bottom: 5px solid $primary-color;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
      display: flex;
      flex-direction: row;
      box-shadow: unset;
      gap: 20px;
      justify-content: unset;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__actions {
    height: 50px;
    position: absolute;
    bottom: 0;
    right: -100px;
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  }
  &__configure {
    // background-color: white;
    // box-shadow: $box-shadow;
    // border-radius: $border-radius;
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 700px);
    // overflow-y: auto;
    .button-multiple-toggle {
      height: 64px;
      padding: 5px 10px;
      top: 0;
      position: sticky;
      z-index: 99999;
      background-color: white;
      .wrapper {
        width: 100%;
        padding: 10px 0;
        box-shadow: none;
        .item {
          display: flex;
          justify-content: center;
          flex: 1;
          padding: 5px;
          span {
            font-size: 13px;
          }
        }
      }
    }
    .configure-container {
      flex: 1;
      position: relative;
      overflow: hidden;
      border-radius: $border-radius;
      background-color: white;
      box-shadow: $box-shadow;
      overflow-y: auto;
    }
    .configure-footer {
      height: 150px;
      margin-top: 20px;
      border-radius: $border-radius;
      background-color: white;
      box-shadow: $box-shadow;
      .footer__selected {
        &-list {
          padding: 10px;
          display: flex;
          overflow-x: auto;
          gap: 10px;
          &-item {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            align-items: center;
            justify-content: center;
            svg {
              margin: 0 !important;
              padding: 0 !important;
            }
            span {
              display: block;
              text-align: center;
              white-space: no-wrap;
              min-width: 100px;
              font-weight: 300;
              color: $grey;
            }
          }
        }
      }
    }
  }
  .template-box {
    position: relative;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    &.expanded {
      background-color: white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 700px;
    }
    &__actions {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: $primary-color;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  .overlay__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    height: 100px;
    background: linear-gradient(
      to top,
      rgb(255, 255, 255) 10%,
      rgba(255, 255, 255, 0) 100%
    );
    span {
      font-weight: 300;
      color: $grey;
    }
  }
  //   &__config {
  //     padding: 20px;
  //     height: 100%;
  //     overflow-y: auto;
  //     position: relative;
  //     padding-top: 40px;
  //     display: flex;
  //     flex-direction: column;
  //     .config-title {
  //       display: flex;
  //       flex-direction: column;
  //       width: 100%;
  //       gap: 6px;
  //       margin-bottom: 30px;
  //       &__label {
  //         font-weight: 300;
  //         color: $grey;
  //       }
  //       input {
  //         width: 100%;
  //       }
  //     }
  //     .config-type {
  //       display: flex;
  //       flex-direction: column;
  //       width: 100%;
  //       gap: 10px;
  //       &__label {
  //         font-weight: 300;
  //         color: $grey;
  //       }
  //       &__list {
  //         display: flex;
  //         gap: 20px;
  //         &-item {
  //           padding: 20px 40px;
  //           border-radius: $border-radius;
  //           box-shadow: $box-shadow;
  //           display: flex;
  //           flex-direction: column;
  //           align-items: center;
  //           gap: 10px;
  //           border: 2px solid white;
  //           transition: 0.3s ease-in-out;
  //           &:hover {
  //             cursor: pointer;
  //             transform: scale(1.03);
  //           }
  //           &.active {
  //             border: 2px solid $primary-color;
  //           }
  //           svg {
  //             margin: 0 !important;
  //             padding: 0 !important;
  //           }
  //           &-title {
  //             font-weight: 300;
  //             color: $grey;
  //             font-size: 18px;
  //           }
  //         }
  //       }
  //     }
  //     &-actions {
  //       display: flex;
  //       justify-content: flex-end;
  //       margin-top: 40px;
  //       position: sticky;
  //       bottom: 0;
  //       height: 50px;
  //       margin-top: auto;
  //       button {
  //         background-color: white;
  //         &:hover {
  //           background-color: $primary-color;
  //         }
  //       }
  //     }
  //     .target {
  //       margin-top: 30px;
  //       padding-bottom: 0;
  //       &__select {
  //         display: flex;
  //         flex-direction: column;
  //         gap: 15px;
  //         margin-top: 10px;
  //         @include md() {
  //           flex-direction: column;
  //         }
  //         .item {
  //           display: flex;
  //           align-items: center;
  //           margin-right: 50px;
  //           @include md() {
  //             margin-bottom: 15px;
  //           }
  //           span {
  //             font-size: 17px;
  //             margin-left: 8px;
  //           }
  //           &:hover {
  //             cursor: pointer;
  //             > .target__select-radio {
  //               border: 4px solid $primary-color;
  //               transform: scale(1.08);
  //             }
  //           }
  //         }
  //         &-radio {
  //           height: 25px;
  //           width: 25px;
  //           border-radius: 50%;
  //           border: 4px solid white;
  //           transition: 0.2s ease-in-out;
  //           box-shadow: $box-shadow-dark;
  //           background-color: white;
  //           &.active {
  //             background-color: $primary-color;
  //           }
  //         }
  //       }
  //     }
  //     &-close {
  //       position: absolute;
  //       top: 10px;
  //       right: 10px;
  //       &:hover {
  //         cursor: pointer;
  //       }
  //     }
  //   }
}
