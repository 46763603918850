@import "src/scss/vars";

.add-curriculum {
  position: relative;
  width: 100%;
  //   padding: 0 20px;
  height: 67px;
  margin-right: 20px;
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.closed {
    @include md {
      max-width: 60px;
      span {
        display: none;
      }
    }
  }
  &.open {
    @include md {
      max-width: calc(100% - 140px);
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 20px;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &-save {
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;
      height: 72px;
      position: sticky;
      bottom: 0;
      pointer-events: none;
      button {
        background-color: white;
        pointer-events: all;
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      &-files {
        display: flex;
        align-items: center;
      }
      .label {
        font-weight: 300;
        color: $grey;
        font-size: 14px;
        display: block;
        margin-bottom: 7px;
      }
      &-upload {
        height: 100px;
        width: 100px;
        background-color: $grey-even-lighter;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        &:hover {
          background-color: $primary-color;
          svg {
            path {
              color: white;
            }
          }
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
        input {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          display: block;
          opacity: 0;
        }
      }
      &-thumbnails {
        // margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        align-items: center;
        .exam-thumbnail-item {
          width: 60px;
          margin-right: 20px;
          position: relative;
        }
        img {
          width: 50px !important;
          height: 50px !important;
          object-fit: cover;
          border-radius: 50%;
          box-shadow: $box-shadow;
        }
        svg {
          position: absolute;
          top: -10px;
          right: -10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .departments,
      .chapters {
        &__list {
          display: flex;
          flex-wrap: wrap;
          .item {
            margin: 0 20px 20px 0;
            padding: 10px 20px;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            border: 2px solid white;
            &:hover {
              cursor: pointer;
            }
            &.selected {
              border: 2px solid $primary-color;
            }
          }
        }
      }
      .classes {
        width: 100%;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        input {
          margin: 10px 10px 0;
          font-size: 13px;
          width: 94%;
          box-shadow: unset;
        }
        &__list {
          overflow-y: auto;
          max-height: 30vh;
          padding: 5px 10px 0;
          &-item {
            padding: 5px 20px;
            display: flex;
            position: relative;
            flex-direction: column;
            border: 2px solid white;
            border-radius: $border-radius;
            &.active {
              border: 2px solid $primary-color;
              &:after {
                display: none;
              }
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              border-radius: $border-radius;
              background-color: $grey-light;
              opacity: 0.8;
            }
            &:hover {
              cursor: pointer;
              background-color: $grey-super-light;
            }
            .class-name {
              font-size: 15px;
              font-weight: 400;
              margin: 0;
              padding: 0;
            }
            .grade {
              font-size: 13px;
              color: $grey;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
