@import "../../../../scss/vars.scss";

.online-grades-section {
  padding: 0 20px;
  height: 80vh;
  // overflow-y: auto;
  padding-top: 40px;
  overflow-x: hidden;
  pointer-events: all;
  padding-bottom: 50px;
  .maxed-rank {
    margin-top: 20px;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: 300;
    color: $grey;
  }
  .thema-max {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: $grey;
  }
  .add-thema {
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    color: $grey;
    svg {
      padding: 0 !important;
      margin: 0 !important;
      margin-left: 10px !important;
    }
  }
  .max-rank {
    margin-top: 15px;
    .grades-section__progress {
      // width: 80%;
      margin-left: 20px;
    }
    .rank-item {
      &__input {
        display: flex;
        border-radius: 30px;
        padding: 10px 7px;
        box-shadow: $box-shadow;
        // border: 1px solid $grey-even-lighter;
        svg {
          padding: 0 !important;
          margin: 0 !important;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
            background: $primary-color !important;
          }
        }
        input {
          width: 60px;
          border: none;
          outline: none;
          // border: 1px solid black;
          // border-bottom: 1px solid $grey-even-lighter;
          display: block;
          margin: 0 5px;
          text-align: center;
          color: $grey;
          -moz-appearance: textfield;
        }
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
      margin-top: 15px;
      input {
        margin-right: 20px;
        width: 100px;
        box-shadow: unset;
        border-radius: unset;
        border-bottom: 1px solid #cacaca;
        text-align: center;
        padding: 2px 10px;
      }
    }
    &__label {
      margin-right: 20px;
      font-size: 17px;
      color: $grey;
      font-weight: 300;
    }
  }
  .accordion {
    margin-top: 30px;
    &-button {
      background-color: $primary-color;
      color: white;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      &__actions {
        svg {
          margin: 0 !important;
        }
        .chevron {
          margin-left: 20px !important;
        }
      }
      &:after {
        display: none;
      }
    }
  }
  .online-grade-item {
    .content {
      position: relative;
      .ck-content {
        box-shadow: unset !important;
      }
      .ck-editor {
        border: 1px solid $grey-lighter;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-radius: $border-radius;
        &__top {
          border-radius: 0;
        }
      }
      .ck-sticky-panel {
        box-shadow: unset !important;
        border-radius: 0;
      }
      .ck-editor__top {
        box-shadow: unset !important;
        border-bottom: 1px solid $grey-lighter;
        margin-bottom: 1px;
        // border: 2px solid $grey-lighter;
        overflow: visible;
        .ck-toolbar {
          background-color: transparent;
        }
      }
      &__title {
        margin-bottom: 10px;
        display: block;
        color: $grey;
        font-weight: 300;
      }
      &__rank {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-label {
          font-weight: 300;
          color: $grey;
          display: block;
          margin-bottom: 10px;
        }
        .thema-input {
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          align-content: center;
          .rank-item {
            &__input {
              display: flex;
              border-radius: 30px;
              padding: 10px 7px;
              // box-shadow: $box-shadow;
              border: 1px solid $grey-lighter;
              svg {
                padding: 0 !important;
                margin: 0 !important;
                transition: 0.3s ease-in-out;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.03);
                  background: $primary-color !important;
                }
              }
              input {
                width: 60px;
                border: none;
                outline: none;
                // border: 1px solid black;
                // border-bottom: 1px solid $grey-even-lighter;
                display: block;
                margin: 0 5px;
                text-align: center;
                color: $grey;
                -moz-appearance: textfield;
              }
              input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
            }
          }
          span {
            display: block;
            // margin-left: 10px;
            margin-bottom: 10px;
            color: $grey;
            font-weight: 300;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }
}
