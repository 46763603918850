.template-preview-wrapper {
  .preview-layout {
    background: #fff;
    // optional styling
  }
  .preview-grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    overflow: hidden;

    .preview-text {
      padding: 10px;
      text-align: center;
    }
    .preview-image {
      display: block;
    }
    .preview-spacer {
      // empty space
    }
    .preview-line {
      width: 100%;
      height: 1px;
      background-color: #ccc;
    }
    .preview-exam-stats {
      width: 100%;
      table {
        border-collapse: collapse;
        width: 100%;
        th,
        td {
          border: 1px solid #ccc;
          padding: 4px;
          text-align: left;
        }
      }
    }
  }
}
