@import "../../scss/vars.scss";

.calendar-full {
  display: flex;
  padding: 0 20px 100px;
  margin: 10px 0 0;
  position: relative;
  flex-direction: column;
  width: 99%;
  @include md {
    padding: 0 20px;
    margin-top: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-title {
      font-weight: 200;
      color: $grey;
      font-size: 22px;
    }
    .filters {
      // border: 1px solid red;
      width: 80%;
      // margin-bottom: 20px;
      height: 50px;
      position: sticky;
      top: 100px;
      z-index: 99;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // margin-right: 20px;
      display: none;
      &__list {
        display: flex;
        height: 100%;
        // display: none;
        &-item {
          margin-right: 20px;
          padding: 10px 20px;
          height: 100%;
          border-radius: $border-radius;
          // box-shadow: $box-shadow;
          border: 1px solid $grey-lighter;
          display: flex;
          align-items: center;
          // border: 2px solid white;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            border: 2px solid $primary-color;
          }
          span {
            color: $grey;
            font-weight: 300;
          }
        }
      }
      &__search {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        width: 100%;
        display: none;
        @include md {
          height: 103px;
        }
        .search-close {
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.15);
          }
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
        &.closed {
          max-width: 10%;
          @include md {
            max-width: 20%;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.08);
          }
          input {
            padding: 0;
            overflow: hidden;
            max-width: 0;
          }
        }
        &.open {
          max-width: 40%;
          @include md {
            max-width: 75%;
          }
          input {
            margin-right: 20px;
          }
        }
        input {
          width: calc(100% - 80px);
          display: block;
          box-shadow: unset;
          border-bottom: 1px solid $grey-lighter;
          border-radius: unset;
          padding: 10px;
          @include md {
            width: 72%;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .week-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .week-slider__days {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .week-slider__day {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    // min-height: 100vh;
    @include md() {
      display: block;
    }
  }
  &__title {
    text-align: left;
    padding-bottom: 20px;
    @include md() {
      padding-bottom: unset;
    }
    h2 {
      font-weight: 200;
      font-size: 24px;
    }
  }
  .showCalendarBig {
    position: relative;
    &.is-open {
      position: fixed;
      top: 7vh;
      height: 90vh;
      width: 90vw;
      left: 5vw;
      background-color: white;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      z-index: 999999;
    }
  }
  &__left {
    width: 30%;
    position: sticky;
    top: 100px;
    height: 50vh;
    z-index: 10;
    margin-top: 10px;
    background-color: white;
    // box-shadow: $box-shadow;
    // border-radius: $border-radius;
    @include md() {
      width: 100%;
      position: initial;
      top: 85px;
      // height: 100%;
      height: unset;
      margin-bottom: 30px;
    }
    .slider__wrapper {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      height: 700px;
      transition: 0.5s ease-in-out;
      overflow: hidden;
      background-color: white;
      &.for-mini {
        // max-height: 133px;
        max-height: 170px;
      }
      &.for-full {
        max-height: 400px;
      }
    }
    .manage-calendar {
      @include md {
        display: none;
      }
    }
    .calendar {
      // padding: 10px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      svg {
        background-color: transparent !important;
      }
      &__title {
        padding: 25px 0 10px;
        font-weight: 400;
        font-size: 25px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        color: $grey;
        // border-bottom: 1px solid $grey-lighter;
        // box-shadow: $box-shadow;
      }
      &__controls {
        position: absolute;
        top: 26px;
        transition: 0.2s ease-in-out;
        border-radius: 50%;
        &.next {
          right: 10px;
        }
        &.prev {
          left: 10px;
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
          &:hover {
            cursor: pointer;
          }
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.2);
          background: $primary-color-opacity;
          svg {
            path {
              color: white;
            }
          }
        }
      }
      &__table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 15px;
        padding: 0 10px;
        thead {
          margin: 0;
          th {
            font-weight: 400;
            color: $grey;
          }
        }
      }
      &__row {
        &-day {
          height: 40px;
          width: 40px;
          transition: 0.2s ease-in-out;
          position: relative;
          color: $grey;
          font-weight: 300;
          border-radius: $border-radius;
          &:hover {
            cursor: pointer;
            background-color: $primary-color-opacity;
            color: white;
          }
          &.empty {
            &:hover {
              background-color: unset;
              cursor: default;
            }
          }
          &.active {
            background-color: $primary-color;
            color: white;
            font-weight: 500;
            border-radius: $border-radius;
            &:after {
              display: none;
            }
            &:before {
              display: none;
            }
          }
          &.current {
            &:not(.active) {
              border: 1px solid $primary-color;
            }
          }
          &.lecture {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 0;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.test {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.exam {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 0;
              margin: 0 auto;
              background-color: $secondary-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.online {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 0;
              margin: 0 auto;
              background-color: $blue-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.exam-and-test {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $secondary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-test {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.online-and-exam {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $blue-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $secondary-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-online {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $blue-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-exam {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $secondary-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-exam-and-test-and-online {
            &:after {
              position: absolute;
              width: 15px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 19px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $blue-color 35%,
                white 35%,
                white 65%,
                $secondary-color 65%
              );
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 15px;
              height: 3px;
              bottom: 5px;
              // left: 0;
              left: 0;
              right: 19px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $green-color 35%,
                white 35%,
                white 65%,
                $primary-color 65%
              );
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-exam-and-online {
            &:after {
              position: absolute;
              width: 20px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $primary-color 35%,
                white 35%,
                white 65%,
                $secondary-color 65%
              );
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 7px;
              height: 3px;
              bottom: 2px;
              right: 23px;
              left: 0;
              margin: 0 auto;
              background-color: $blue-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.online-and-exam-and-test {
            &:after {
              position: absolute;
              width: 20px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $blue-color 35%,
                white 35%,
                white 65%,
                $secondary-color 65%
              );
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 7px;
              height: 3px;
              bottom: 2px;
              right: 23px;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-exam-and-test {
            &:after {
              position: absolute;
              width: 20px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $primary-color 35%,
                white 35%,
                white 65%,
                $secondary-color 65%
              );
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 7px;
              height: 3px;
              bottom: 2px;
              right: 23px;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-online-and-test {
            &:after {
              position: absolute;
              width: 20px;
              height: 3px;
              bottom: 2px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background: linear-gradient(
                to right,
                $primary-color 35%,
                white 35%,
                white 65%,
                $blue-color 65%
              );
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 7px;
              height: 3px;
              bottom: 2px;
              right: 23px;
              left: 0;
              margin: 0 auto;
              background-color: $color-green;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-exam {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $secondary-color;
              border-radius: 4px;
              content: "";
            }
          }
          &.lecture-and-online-exam {
            &:after {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 0;
              left: 15px;
              margin: 0 auto;
              background-color: $primary-color;
              border-radius: 4px;
              content: "";
            }
            &:before {
              position: absolute;
              width: 10px;
              height: 3px;
              bottom: 5px;
              right: 15px;
              left: 0;
              margin: 0 auto;
              background-color: $blue-color;
              border-radius: 4px;
              content: "";
            }
          }
        }
      }
    }
  }
  &__right {
    // border: 1px solid green;
    // overflow-x: hidden;
    // overflow-y: hidden;
    width: 68%;
    padding: 10px 1.5% 0;
    @include md() {
      width: 100%;
      padding-bottom: 200px;
    }
    &-title {
      font-size: 20px;
      text-align: left;
      font-weight: 300;
      color: $grey;
    }
    .events {
      margin-top: 0px;
      @include md() {
        margin-top: 20px;
      }
      &__old {
        &-wrapper {
          transition: 0.3s ease-in-out;
          &.close {
            height: 0;
            overflow-y: hidden;
          }
        }
      }
      &__line {
        margin: 10px 0 10px;
        position: relative;
        width: 100%;
        &.old {
          &:hover {
            cursor: pointer;
          }
        }
        &.arrow {
          position: absolute;
          right: 10px;
          top: -10px;
          display: flex;
          justify-content: flex-end;
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        &-label {
          text-align: left;
          display: flex;
          align-items: center;
          svg {
            padding: 0 !important;
            margin: 0 !important;
            margin-right: 5px !important;
          }
          span {
            font-size: 14px;
            position: relative;
            background-color: white;
            color: $grey;
            font-weight: 300;
          }
        }
      }
      &__modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        transition: 0.4s ease-in-out;
        visibility: hidden;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.272);
        z-index: 999;
        &.show {
          visibility: visible;
        }
      }
    }
  }
}
