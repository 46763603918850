@import "../../../../scss/vars";

.student-exams {
  position: relative;
  &__status {
    position: absolute;
    top: -20px;
    right: -20px;
    height: 45px;
    width: 45px;
    z-index: 99999;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    .CircularProgressbar {
      .CircularProgressbar-path {
        stroke: $primary-color;
      }
      text {
        font-size: 30px;
        fill: $primary-color;
      }
    }
  }
  &__header {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @include md {
      margin-bottom: 30px;
      flex-direction: column;
    }
    .search {
      padding: 10px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s ease-in-out;
      width: 100%;
      @include md {
        margin-top: 20px;
      }
      .search-close {
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &.closed {
        max-width: 10%;
        @include md {
          max-width: 100%;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.08);
        }
        input {
          padding: 0;
          overflow: hidden;
          max-width: 0;
          box-shadow: none;
          @include md {
            padding: 10px;
            max-width: 100%;
            margin-right: 20px;
          }
        }
      }
      &.open {
        max-width: 50%;
        @include md {
          max-width: 100%;
        }
        input {
          margin-right: 20px;
        }
      }
      input {
        width: 80%;
        display: block;
        box-shadow: unset;
        border-radius: unset;
        border-bottom: 1px solid $grey-lighter;
        padding: 10px;
        color: $grey;
      }
    }
    .filters {
      display: flex;
      // &.online-exam {
      //   &.search-open {
      //     .filters__item {
      //       span {
      //         max-width: 0;
      //         max-height: 15px;
      //       }
      //     }
      //   }
      // }
      @include md {
        flex-wrap: wrap;
      }
      &__item {
        margin-right: 20px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        @include md {
          margin-bottom: 15px;
          margin-right: 10px;
        }
        &.start {
          padding: 10px 30px;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
          margin-bottom: 10px !important;
        }
        span {
          transition: 0.3s ease-in-out;
          font-weight: 300;
          font-size: 12px;
          color: $grey;
          overflow: hidden;
          height: 15px;
        }
        &.selected {
          // border: 2px solid $primary-color;
          background-color: $primary-color;
          span {
            font-weight: 400;
            color: white;
          }
        }
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    &-card {
      margin-right: 4%;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
