@import "../../../../scss/vars.scss";

.timologio-section {
}

.add-timologio {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 10px;
  @include md {
    margin-left: 0;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 20px;
    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 20px;
    position: relative;
    margin-top: 20px;
    &-amount {
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        width: 140px;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .content-title {
        font-weight: 300;
        color: $grey;
        display: block;
        text-align: center;
      }
      .content-item {
        display: flex;
        flex-direction: column;
        gap: 7px;
        &.double {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          .content-item {
            width: 48%;
          }
        }
        span {
          color: $grey;
          font-weight: 300;
        }
        .double-input {
          display: flex;
          gap: 15px;
          input {
            &:nth-child(1) {
              // width: 280px;
              flex: 1;
            }
            &:nth-child(2) {
              width: 120px;
            }
          }
        }
      }
    }
    .kad {
      &-title {
        weight: 300;
        color: $grey;
        margin-bottom: 7px;
      }
      &-search {
        width: 100%;
        input {
          width: 100%;
        }
      }
      &-list {
        margin-top: 10px;
        .input {
          padding: 5px;
        }
        .css-13cymwt-control {
          border: none;
        }
      }
    }
    &-item {
      position: relative;
      margin-top: 10px;
      .checkbox-wrapper {
        margin-top: 20px;
      }
      &-label {
        margin-bottom: 10px;
        display: block;
        color: $grey;
        font-weight: 300;
      }
      &.save {
        position: sticky;
        bottom: 0px;
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
        .cta {
          background-color: white;
          &:hover {
            background-color: $primary-color;
          }
        }
      }
    }
    &-add {
      justify-content: space-between;
      display: flex;
      gap: 20px;
      // border: 1px solid $grey-even-lighter;
      margin-top: 10px;
      padding: 10px 5px;
      .cta {
        padding: 5px 30px;
      }
      input {
        border: 1px solid $grey-lighter;
        border-radius: $border-radius;
        outline: none;
        padding: 6px 20px;
        flex: 1;
      }
    }
    &-select {
      position: relative;
      border-radius: $border-radius;
      border: 1px solid $grey-even-lighter;
      padding: 10px;
      max-height: 350px;
      overflow-y: auto;
      .dropdown {
        &-preview {
          height: 28px;
          cursor: pointer;
        }
        &-content {
          left: 0;
          right: 0;
          overflow: hidden;
          background-color: white;
          z-index: 1000; // Ensure it stays on top
          transition: max-height 0.3s ease-in-out;
          &__item {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            border-radius: $border-radius;
            margin-bottom: 10px;
            input {
              border: 1px solid $grey-lighter;
              border-radius: $border-radius;
              outline: none;
              padding: 6px 20px;
            }
            &.active {
              border: 1px solid $primary-color;
            }
            &:hover {
              cursor: pointer;
              background-color: $grey-even-lighter;
            }
            &:last-child {
              border-bottom: none;
            }
            &.new {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              border: none;

              &:hover {
                background-color: unset;
              }
              input {
                flex: 1;
              }
              button {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
