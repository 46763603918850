@import "../../scss/vars.scss";

.start-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  @include md {
    flex-direction: column;
  }
  &__return-home {
    position: fixed;
    top: 40px;
    left: 0;
    display: flex;
    align-items: center;
    opacity: 0.7;
    z-index: 9999999;
    padding: 15px;
    z-index: 9999;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 10px;
    }
  }
  .terms-and-conditions {
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;
  }
  .terms-modal {
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .wrapper {
      z-index: 99999;
      top: 10vh;
      left: 10vw;
      width: 80vw;
      height: 80vh;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      background-color: white;
      &__close {
        position: fixed;
        top: 6vh;
        right: 10vw;
        background-color: white;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        z-index: 99999999;
        pointer-events: none;
        svg {
          margin: 0 !important;
          padding: 5px !important;
        }
        @include md {
          top: 6vh;
          right: 2vw;
        }
      }
      @include md {
        width: 95vw;
        height: 80vh;
      }
    }
  }
  .google__button {
    position: relative;
    z-index: 10;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      content: "";
    }
    @include md {
      order: 2;
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .label {
      font-weight: 300;
      color: $grey;
      display: block;
      margin-right: 10px;
    }
    .google-img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      opacity: 0.8;
    }
    .google-provider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999999;
      height: 100%;
      opacity: 0;
    }
  }
  .login-input {
    display: flex;
    box-shadow: $box-shadow;
    border-radius: 30px;
    align-items: center;
    padding: 10px 20px;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    input {
      display: block;
      margin-left: 20px;
      border: none;
      outline: none;
      color: $grey;
      background-color: transparent;
      width: 100%;
    }
  }
  .dual-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include md {
      flex-direction: column;
    }
    .login-input {
      width: 48%;
      @include md {
        width: 100%;
      }
    }
  }
  &__form {
    width: 50%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 130px;
    position: relative;
    z-index: 10;
    @include md {
      width: 100%;
      order: 2;
      height: 80vh;
      padding: 10px;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      box-shadow: $box-shadow-dark;
      background-color: white;
    }
    .errors {
      span {
        color: $red;
      }
    }
    &-header {
      width: 300px;
      max-height: 120px;
      margin-bottom: 30px;
      @include md {
        margin-bottom: 30px;
        margin-top: 20px;
        max-height: 70px;
        max-width: 68vw;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .toggle {
      display: flex;
      width: 100%;
      justify-content: center;
      @include md {
        width: 100%;
      }
      &__option {
        padding: 5px 20px;
        color: $grey;
        font-weight: 500;
        font-size: 22px;
        margin: 0 20px;
        &:hover {
          cursor: pointer;
        }
        &.active {
          font-weight: 600;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }
      }
    }
    .slide-container {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      transition: 0.5s ease-in-out;
      &.for-login {
        max-height: 320px;

        &.success {
          max-height: 420px;
        }
        &.error {
          max-height: 380px;
        }
      }
      &.for-register {
        max-height: 430px;
        min-height: 400px;
        // @include md {
        //   max-height: 900px;
        // }
      }
      @include md {
        max-height: 100% !important;
      }
    }
    .login-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: 0.5s ease-in-out;
      @include md {
        justify-content: flex-start;
      }
      &.show {
        transform: translateX(0);
      }
      &.close {
        transform: translateX(-50vw);
        @include md {
          transform: translateX(-100vw);
        }
      }
      form {
        width: 70%;
        display: flex;
        flex-direction: column;
        @include md {
          width: 90%;
          margin-top: 30px;
        }
        .login-input {
          margin: 15px 0;
          @include md {
            margin: 15px 0 20px;
          }
        }
      }
      .actions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        width: 70%;
        align-items: center;
        @include md {
          flex-direction: column;
          width: 90%;
          margin-top: 30px;
        }
        button {
          @include md {
            width: 100%;
          }
        }
        &__underline {
          color: $primary-color;
          font-weight: 500;
          @include md {
            order: 2;
            margin-top: 15px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .success {
        margin-top: 20px;
        padding: 20px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        display: flex;
        align-items: center;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
        span {
          color: $grey;
          font-weight: 300;
          display: block;
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
    .register-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      transition: 0.5s ease-in-out;
      padding: 40px 10px 0;
      @include md {
        overflow-y: auto;
        padding-bottom: 50px;
      }
      .login-input {
        margin-bottom: 30px;
      }
      &.show {
        transform: translateX(0);
      }
      &.close {
        transform: translateX(50vw);
        @include md {
          transform: translateX(100vw);
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include md {
          flex-direction: column;
          button {
            width: 100%;
          }
        }
      }
      &__terms {
        margin-bottom: 20px;
        text-align: left;
        .label {
          color: $grey;
          font-weight: 300;
          font-size: 14px !important;
          @include md {
            font-size: 13px !important;
          }
          span {
            border-bottom: 1px solid $grey-light;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &__graphic {
    width: 50%;
    height: 100%;
    position: relative;
    z-index: 1;
    @include md {
      order: 1;
      height: 20vh;
      width: 100%;
    }
    .edupal-logo {
      position: absolute;
      width: 30vw;
      right: 15vh;
      top: 0;
      bottom: 0;
      margin: auto 0;
      display: flex;
      align-items: center;
      transition: 0.5s ease-in-out;
      &.loading {
        right: 0;
        width: 30vh;
        @include md {
          right: 25vw;
          width: 50vw;
          margin-top: 120px;
        }
      }
      @include md {
        width: 60vw;
        right: 20vw;
        margin-top: 80px;
      }
      img {
        padding: 40px;
        width: 100%;
        object-fit: cover;
        &.white-label {
          margin-left: 190px;
          @include md {
            margin-left: 0;
          }
          &.small {
            width: 90%;
          }
          animation: moveUpDown 3s ease-in-out infinite;
          @keyframes moveUpDown {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(
                -20px
              ); // Adjust the value to control the height of the movement
            }
            100% {
              transform: translateY(0);
            }
          }
        }
      }
    }
    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: scale(100px);
      }
    }
    .circle {
      background-color: $primary-color;
      width: 105vh;
      height: 105vh;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -65vh;
      margin: auto;
      border-radius: 50%;
      z-index: -1;
      box-shadow: $box-shadow-dark;
      @include md {
        right: unset;
        top: unset;
        bottom: -80vw;
        left: -5vw;
        width: 110vw;
        height: 110vw;
      }
      &.orange {
        background-color: #f67800 !important;
      }
      &.blue {
        background-color: #00bffe !important;
      }
      &.purple {
        background-color: #7415f5 !important;
      }
      &.first {
        animation: scale-in-out-first 5s infinite;
        &.loading {
          transition: 0.5s ease-in-out;
          width: 20px;
          height: 20px;
          right: 34vw;
          animation: loading-circle 3s infinite;
          @include md {
            width: 15px;
            height: 15px;
            bottom: 50%;
            left: calc(28vw - 45px);
          }
        }
      }
      &.second {
        background-color: $primary-color-opacity;
        width: 116vh;
        height: 113vh;
        animation: scale-in-out 4s infinite;
        @include md {
          width: 120vw;
          height: 120vw;
          left: -10vw;
          bottom: -82vw;
        }
        &.loading {
          transition: 0.5s ease-in-out;
          width: 20px;
          height: 20px;
          right: calc(34vw - 40px);
          animation: loading-circle 3s infinite;
          animation-delay: 0.5s;
          @include md {
            width: 15px;
            height: 15px;
            bottom: 50%;
            left: calc(28vw - 15px);
          }
        }
      }
      &.third {
        background-color: $primary-color-opacity-big;
        width: 127vh;
        height: 124vh;
        animation: scale-in-out 5s infinite;
        @include md {
          width: 125vw;
          height: 125vw;
          left: -12.5vw;
          bottom: -84vw;
        }
        &.loading {
          transition: 0.5s ease-in-out;
          width: 20px;
          height: 20px;
          right: calc(34vw - 80px);
          animation: loading-circle 3s infinite;
          animation-delay: 1s;
          @include md {
            width: 15px;
            height: 15px;
            bottom: 50%;
            left: calc(28vw + 15px);
          }
        }
      }
      &.fourth {
        width: 80vh;
        height: 80vh;
        box-shadow: unset;
        right: -50vh;
        @include md {
          width: 72vw;
          height: 72vw;
          left: 10vw;
          bottom: -55vw;
          right: 10vw;
        }
        &.loading {
          // display: none;
          @include md {
          }
        }
      }
    }
    @keyframes loading-circle {
      0%,
      100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.3);
      }
    }
    @keyframes scale-in-out-first {
      0%,
      100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
    }
    @keyframes scale-in-out {
      0%,
      100% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.1);
        opacity: 0.5;
      }
    }
  }
  .target {
    margin-top: 30px;
    padding-bottom: 0;
    &__select {
      display: flex;
      @include md() {
        flex-direction: column;
      }
      .item {
        display: flex;
        align-items: center;
        margin-right: 50px;
        @include md() {
          margin-bottom: 15px;
        }
        .label {
          font-size: 17px;
          margin-left: 12px;
          display: block;
          width: calc(100% - 37px);
          span {
            margin: 0 4px;
          }
        }
        &:hover {
          // cursor: pointer;
          > .target__select-radio {
            // transform: scale(1.08);
          }
        }
      }
      &-radio {
        &:hover {
          cursor: pointer;
        }
        border: 4px solid $primary-color;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        // border: 4px solid white;
        transition: 0.2s ease-in-out;
        box-shadow: $box-shadow-dark;
        background-color: white;
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }
}
