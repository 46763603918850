@import "../../../scss/vars";

.general-test {
  &__title {
    padding: 0 20px;
    font-weight: 300;
    color: $grey;
    &.student {
      margin-top: 20px;
    }
  }
  &.is-expanded {
    border-left: unset;
  }
  &__preview {
    display: flex;
    align-items: center;
    height: 100%;
    span {
      color: $grey;
    }
  }
  &__content {
    overflow-y: auto;
    height: 100%;
    .controls {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: flex-end;
      gap: 10px;
      width: calc(100% - 60% - 200px);
      .cta {
        padding: 5px 30px;
      }
    }
  }
  &__header {
    box-shadow: $box-shadow;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    span {
      font-weight: 300;
      color: $grey;
      font-size: 20px;
    }
  }
  &__search {
    padding: 20px;
    input {
      width: 100%;
    }
  }
  &__students {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    .students-item {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      display: flex;
      padding: 20px;
      &__personal {
        border-right: 1px solid $grey-lighter;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        min-width: 300px;
        &-img {
          img {
            height: 50px;
            width: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &-name {
          display: flex;
          gap: 6px;
        }
      }
      &__class {
        padding: 0 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid $grey-lighter;
        span {
          color: $grey;
          font-weight: 300;
        }
      }
      &__grade {
        padding: 0 20px;
        border-right: 1px solid $grey-lighter;
        width: 140px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .CircularProgressbar {
          height: 100px;
          width: 100px;
          // position: absolute;
          // pointer-events: none;
          // top: 0;
          // left: 0;
          .CircularProgressbar-path {
            stroke: $primary-color;
          }
          text {
            fill: $primary-color;
          }
        }
        .score-item {
          display: flex;
          flex-direction: column;
          padding: 10px;
          &__max {
            text-align: center;
            span {
              font-size: 11px;
              color: $grey;
              font-weight: 300;
            }
          }
          span {
            color: $grey;
            font-weight: 300;
            margin-bottom: 10px;
            text-align: center;
          }
          &__input {
            display: flex;
            //box-shadow: $box-shadow;
            border: 1px solid $grey-lighter;
            border-radius: 30px;
            padding: 10px 7px;
            svg {
              padding: 0 !important;
              margin: 0 !important;
              transition: 0.3s ease-in-out;
              &:hover {
                cursor: pointer;
                transform: scale(1.03);
                background: $primary-color !important;
              }
            }
            input {
              width: 40px;
              border: none;
              outline: none;
              // border: 1px solid black;
              // border-bottom: 1px solid $grey-even-lighter;
              display: block;
              margin: 0 5px;
              text-align: center;
              color: $grey;
              -moz-appearance: textfield;
            }
            input[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
          }
        }
      }
      &__comment {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        flex: 1;
        .comment-label {
          font-size: 14px;
          color: $grey;
          font-weight: 300;
        }
        .comment-title {
          font-weight: 300;
          color: $grey;
        }
        textarea {
          resize: none;
          height: 150px;
        }
      }
      &__edit,
      &__save {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }
  &__add {
    padding: 20px;
  }
  &__selector {
    padding: 20px;
    &-title {
      span {
        font-weight: 300;
        color: $grey;
        margin-bottom: 6px;
        display: block;
      }
    }
    .grades-list,
    .classes-list {
      display: flex;
      gap: 15px;
      margin-bottom: 20px;
      flex-wrap: wrap;
      &__item {
        box-shadow: $box-shadow;
        padding: 10px 20px;
        border-radius: $border-radius;
        transition: 0.3s ease-in-out;
        border: 2px solid white;
        &.selected {
          border: 2px solid $primary-color;
        }
        span {
          color: $grey;
          font-weight: 300;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
    }
  }
}
