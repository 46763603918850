@import "../../../../scss/vars.scss";

.date-section {
  display: flex;
  padding-top: 15px;
  width: 78vw;
  height: calc(94vh - 50px);
  overflow-y: auto;
  pointer-events: all;
  position: relative;
  @include md {
    flex-direction: column;
    height: 88vh;
    width: 100%;
    padding-bottom: 100px;
    overflow-y: auto;
    padding-top: 20px;
    padding-left: 5px;
  }
  .config {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    @include md {
      width: 100%;
      height: unset;
      padding: 30px 0 15px;
    }
    .wrapper {
      display: block;
      width: 100%;
      padding: 100px 0;
    }
    .general {
      width: 50%;
    }
    .classrooms {
      padding-right: 30px;
      padding-left: 10px;
      margin-top: 20px;
      @include md {
        padding: 0 15px 0;
      }
      .label {
        color: $grey;
        font-weight: 300;
        font-size: 14px;
        @include md {
          margin-bottom: 6px;
          display: block;
          font-size: 15px;
        }
      }
      .list-wrapper {
        display: flex;
        align-items: center;
        @include md {
          flex-direction: column;
          align-items: unset;
        }
        .scroll-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 50%;
          &:hover {
            cursor: pointer;
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          @include md {
            display: none;
          }
        }
        input {
          font-size: 14px;
          height: 40px;
          margin-right: 15px;
          display: block;
          width: 180px;
          @include md {
            width: 100%;
          }
        }
      }
      &__list {
        display: flex;
        overflow-x: auto;
        align-items: center;
        padding: 10px 0;
        @include md {
          margin-top: 5px;
        }
        .item {
          padding: 10px 15px;
          border-radius: $border-radius;
          border: 2px solid white;
          box-shadow: $box-shadow;
          transition: 0.3s ease-in-out;
          margin-right: 15px;
          font-size: 15px;
          font-weight: 300;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
          &.active {
            border: 2px solid $primary-color;
          }
        }
      }
    }
    .repeat {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 0 30px;
      padding-left: 10px;
      .label {
        color: $grey;
        font-weight: 300;
        font-size: 14px;
      }
      select {
        margin-top: 5px;
        font-size: 14px;
      }
    }
    .time {
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      width: 100%;
      margin-top: 20px;
      .item {
        display: flex;
        flex-direction: column;
        width: 42%;
        label {
          color: $grey;
          font-weight: 300;
          display: block;
          margin-bottom: 10px;
        }
        input {
          font-size: 14px;
        }
      }
    }
    &__date {
      padding-left: 10px;
      width: 90%;
      @include md {
        width: 100%;
      }
      .date-picker {
        width: 100%;
        font-family: Arial, sans-serif;
        border-radius: $border-radius;
        padding: 0 10px;
        .header {
          position: relative;
          left: unset;
          height: unset;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 10px;
          border-bottom: 1px solid $grey-even-lighter;
          span {
            font-size: 18px;
            font-weight: 300;
            color: $grey;
          }

          button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 20px;
          }
        }

        .day-names {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          padding-bottom: 3px;
          margin-top: 3px;
          .day-name {
            padding: 4px;
            font-weight: 200;
            color: $primary-color;
            font-size: 15px;
          }
        }

        .days {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          grid-gap: 20px;
          @include md {
            grid-gap: 0.8em 0.5em;
          }
          .day {
            padding: 20px 5px;
            border-radius: $border-radius;
            cursor: pointer;
            color: $grey;
            font-size: 20px;
            position: relative;
            @include md {
              padding: 7px 10px;
            }
            &:hover {
              background-color: $primary-color-opacity;
              color: white;
            }
            &.not-working {
              opacity: 0.3;
            }
            &.selected {
              background-color: $primary-color;
              color: white;
              font-weight: 500;
              &:after {
                display: none;
              }
              &:before {
                display: none;
              }
            }
            &.no-availability {
              opacity: 0.5;
            }
            &.empty {
              visibility: hidden;
            }
            &.has-lecture {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-exam {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 2px;
                right: 0;
                left: 0;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-exam-and-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-test {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-exam-and-test {
              &:after {
                position: absolute;
                width: 1px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: white;
                border-left: 7px $secondary-color;
                border-right: 7px $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 16px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $color-green;
                border-radius: 4px;
                content: "";
              }
            }
            &.has-lecture-and-exam {
              &:after {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 0;
                left: 15px;
                margin: 0 auto;
                background-color: $primary-color;
                border-radius: 4px;
                content: "";
              }
              &:before {
                position: absolute;
                width: 10px;
                height: 3px;
                bottom: 5px;
                right: 15px;
                left: 0;
                margin: 0 auto;
                background-color: $secondary-color;
                border-radius: 4px;
                content: "";
              }
            }
          }
        }
      }
    }
  }
  .timeline-wrapper {
    width: 30%;
    position: relative;
    @include md {
      width: 100%;
      padding-top: 0;
    }
    &__old-date {
      position: absolute;
      z-index: 99999999999;
      width: 100%;
      height: calc(100% - 100px);
      // background-color: white;
      // opacity: 0.3;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 25px;
        color: white;
      }
    }
    .repeat-type {
      @include md {
        margin-top: 15px;
        padding: 0 15px;
      }
      .label {
        display: none;
        @include md {
          display: block;
          // margin-bottom: 5px;
          color: $grey;
          font-weight: 300;
        }
      }
      select {
        margin-top: 10px;
        width: 94%;
        font-size: 15px;
        color: white;
        // background-color: transparent;
        font-weight: 400;
        color: $grey;
        box-shadow: unset;
        @include md {
          width: 100%;
          box-shadow: $box-shadow;
        }
      }
    }
    .button-multiple-toggle {
      margin-top: 15px;
      .wrapper {
        padding: 6px 8px;
        .item {
          padding: 10px 15px;
        }
      }
    }
    .time {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      @include md {
        padding: 0 15px;
      }
      .item {
        width: 48%;
        display: flex;
        flex-direction: column;
        @include md {
          width: 46%;
        }
        .time-input-group {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: center;
          padding-right: 10px;
          @include md {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
          }
          &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1px;
            svg {
              margin: 0 !important;
              padding: 5px !important;
              transition: 0.3s ease-in-out;
              @include md {
                color: $grey-light !important;
              }
              &:hover {
                transform: scale(1.2);
                cursor: pointer;
              }
            }
            input {
              @include md {
                box-shadow: none !important;
                color: $grey !important;
              }
            }
          }
          span {
            font-size: 20px;
            color: white;
            font-weight: 500;
            @include md {
              color: $grey;
            }
          }
        }
        label {
          display: block;
          color: white;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 5px;
          text-align: center;
          margin-right: 12px;
          @include md {
            color: $grey;
            font-weight: 300;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Remove arrows from number input for Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        input {
          font-size: 14px;
          color: $grey;
          width: 90%;
          box-shadow: unset;
          font-weight: 500;
          border-radius: 40px;
          text-align: center;
          background-color: transparent;
          color: white;
          padding: 0;
          font-size: 16px;
          @include md {
            box-shadow: $box-shadow;
            width: 100%;
          }
        }
      }
    }
  }
  .timeline {
    width: 100%;
    height: calc(89vh - 280px);
    padding: 20px 20px 20px 70px;
    border-radius: $border-radius;
    // box-shadow: $box-shadow;
    overflow-y: auto;
    @include md() {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 0px !important; /* For vertical scrollbars */
      height: 0px !important; /* For horizontal scrollbars */
      background: transparent !important; /* Make the scrollbar transparent */
    }
    .time-slot {
      height: 60px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.699);
      display: flex;
      flex-direction: column;
      text-align: left;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        top: 30px;
        left: 0;
        background-color: #ececec38;
        // background-color: white;
        height: 1px;
        width: 100%;
      }
      &.not-working {
        opacity: 0.3;
      }
      &__current {
        top: 100px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: rgba(38, 177, 38, 0.651);
        position: absolute;
        &:after {
          position: absolute;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: rgba(38, 177, 38, 0.651);
          left: -8px;
          top: -3px;
          content: "";
        }
      }
      &__hour {
        position: absolute;
        left: -50px;
        top: -13px;
        color: white;
        font-weight: 300;
      }
      &__event {
        background-color: #6225e6d2;
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        z-index: 10;
        &:hover {
          cursor: pointer;
          background-color: #6225e6a1;
        }
        &-title {
          color: white;
          font-size: 18px;
        }
      }
      &__taken {
        border-radius: $border-radius;
        display: flex;
        color: white;
        position: relative;
        background-color: red;
        &-title {
          padding: 10px 15px;
          color: white;
          font-weight: 500;
          font-size: 14px;
          span {
            font-size: 15px;
            color: white;
          }
        }
        &-container {
          opacity: 0.7;
        }
      }
    }
  }
}
