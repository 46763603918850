@import "../../scss/vars.scss";

.chat-item {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  box-shadow: $box-shadow;
  width: 350px;
  margin-right: 20px;
  text-align: left;
  position: relative;
  z-index: 10;
  @include md {
    width: 100%;
    height: 100%;
  }
  &__header {
    background-color: white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    @include md {
      border-radius: unset;
      height: 61px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
    }
    .info {
      display: flex;
      align-items: center;
      &__back {
        display: none;
        margin-right: 15px;
        @include md {
          display: block;
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
      &__img {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &__name {
        margin-left: 10px;
        color: white;
      }
    }
    &.not-read {
      background-color: $primary-color;
      @include md {
        background-color: white;
        .info {
          &__name {
            color: $grey;
          }
        }
      }
      .info {
        &__name {
          color: white;
          @include md {
            color: $grey;
          }
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
    .info {
      &__name {
        margin-left: 10px;
        font-size: 17px;
        color: $grey;
      }
    }
    .actions {
      padding: 0;
      display: flex;
      align-items: center;
      &__edit {
        margin-right: 7px;
        svg {
          margin: 0 !important;
          padding: 0 !important;
          transition: 0.2s;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
      &__close {
        @include md {
          display: none;
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
          transition: 0.2s;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &__history {
    height: 500px;
    background-color: white;
    transition: 0.2s ease-in-out;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    @include md {
      height: calc(100% - 60px);
      max-height: 100%;
      margin-top: 61px;
    }
    &.close {
      max-height: 0;
      padding: 0;
    }
    .content {
      height: calc(100% - 60px);
      overflow-y: auto;
      padding-bottom: 10px;
      .message-typing {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 14px 0 8px;
        margin: 20px 25px;
        .dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: gray;
          margin: 0 4px;
          opacity: 0.4;
          animation: typing 1s infinite;

          &:nth-child(1) {
            animation-delay: 0s;
          }

          &:nth-child(2) {
            animation-delay: 0.3s;
          }

          &:nth-child(3) {
            animation-delay: 0.6s;
          }
        }
      }

      @keyframes typing {
        0% {
          opacity: 0.4;
        }
        50% {
          opacity: 1;
          background-color: lightgray;
          transform: translateY(-5px);
        } // Peak opacity with lighter color
        100% {
          opacity: 0.4;
        }
      }
      .message {
        width: 100%;
        display: flex;
        margin-top: 4px;
        padding-left: 6px;
        position: relative;
        align-items: center;
        .bubble {
          display: inline-block !important;
        }
        &.with-date {
          padding-top: 35px;
          .message__date {
            width: 50%;
            display: block;
            text-align: center;
            position: absolute;
            top: 0;
            left: 25%;
            color: $grey;
            font-size: 14px;
            font-weight: 300;
            border-bottom: 1px solid $grey-even-lighter;
          }
        }
        &:hover {
          .bubble__preview-time {
            // display: block !important;
          }
        }
        span {
          overflow-wrap: break-word; // Allows breaking long words
          word-wrap: break-word; // Ensures compatibility
          word-break: normal; // Prevents breaking within words
          white-space: pre-wrap;
        }
        &.first {
          margin-top: 20px;
        }
        &.isLastAndSender {
          padding-bottom: 10px;
        }
        &.sender {
          justify-content: flex-end;
          padding-right: 6px;
          padding-left: 0;
          .bubble {
            background-color: $primary-color;
            display: flex;
            align-items: center;
            position: relative;
            &__preview-time {
              left: unset;
              right: 10px;
            }
            &__profile {
              order: 2;
              margin-left: 10px;
              margin-right: -10px;
            }
            span {
              color: white;
              order: 1;
            }
            &:not(.has-gif) {
              &.first {
                border-bottom-right-radius: 4px;
              }
              &.middle {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              &.last {
                border-top-right-radius: 4px;
              }
            }
          }
        }
        &:not(.sender) {
          .bubble {
            &:not(.has-image) {
              margin-left: 39px;
            }
            &:not(.has-gif) {
              &.first {
                border-bottom-left-radius: 4px;
              }
              &.middle {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &.last {
                border-top-left-radius: 4px;
              }
            }
          }
        }
        .bubble {
          padding: 10px 15px;
          max-width: 80%;
          border-radius: $border-radius;
          background-color: #e3e3e37a;
          &__preview-time {
            display: none;
            position: absolute;
            top: -25px;
            left: 10px;
            background-color: rgba(45, 45, 45, 0.516);
            padding: 5px 10px;
            border-radius: $border-radius;
            color: white;
            // color: $grey;
            font-weight: 300;
            font-size: 14px;
          }
          &.has-gif {
            padding: 0;
          }
          &__gif {
            width: 100%;
            border-radius: $border-radius;
          }
          &__profile {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
            background-color: white;
            margin-right: 10px;
          }
        }
        &__status {
          position: absolute;
          left: 0;
          bottom: -15px;
          height: 15px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
          &-sending,
          &-sent,
          &-read {
            span {
              color: $grey !important;
              font-weight: 300;
              font-size: 12px;
            }
          }
        }
      }
    }
    .create {
      height: 55px;
      border-top: 1px solid $grey-even-lighter;
      display: flex;
      align-items: center;
      @include md {
        margin-bottom: 20px;
      }
      textarea {
        resize: none;
        height: 45px;
        margin-left: 10px;
        transition: 0.3s ease-in-out;
        width: 100%;
        max-width: calc(100% - 40px);
        box-shadow: unset !important;
        &.full {
          max-width: calc(100% - 25px);
          @include md {
            max-width: 100%;
          }
        }
        @include md {
          max-width: calc(100% - 120px);
        }
      }
      &__img {
        margin-left: 5px;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &__send {
        margin: 0 15px;
        display: none;
        @include md {
          display: block;
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}
