@import "../../scss/vars.scss";

.class-selector {
  width: 100%;

  &__title {
    font-size: 16px !important;
    font-weight: 300;
    color: #7a7a7a;
    display: block;
    margin-bottom: 5px;
  }

  &__accordion {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 10px 5px;
    .class-selector__select-all {
      cursor: pointer;
      padding: 5px 20px;
      margin-bottom: 10px;
      width: 100%;
      text-align: left;
      font-weight: 300;
      color: $grey;
      background-color: white;
      border: unset;
      border-radius: unset;
      position: relative;
      border: 2px solid white;
      &:after {
        width: calc(100% - 25px);
        height: 2px;
        background-color: $grey-even-lighter;
        content: "";
        position: absolute;
        left: 12.5px;
        bottom: 0;
      }
      &.selected {
        border: 2px solid $primary-color;
        border-radius: $border-radius;
      }
      &:hover {
        background-color: #f0f0f0;
        border-radius: $border-radius;
      }
      span {
        display: block;
      }
    }
    .class-selector {
      &__classes {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, padding 0.3s ease;
        display: flex;
        flex-direction: column;
        gap: 4px;
        // padding-bottom: 15px;
      }
      &__class {
        font-weight: 300;
        color: $grey;
        cursor: pointer;
        padding: 5px 15px;
        margin: 0 5px;
        transition: background-color 0.3s ease;
        border-radius: $border-radius;
        border: 2px solid white;
        &:hover {
          background-color: hsla(0, 0%, 93%, 0.435);
        }

        &.selected {
          border: 2px solid $primary-color; /* primary color */
        }
      }
    }
    .class-selector__grade {
      margin-bottom: 10px;
      overflow: hidden;
      border: 2px solid white;
      position: relative;
      &:after {
        width: calc(100% - 25px);
        height: 2px;
        background-color: $grey-even-lighter;
        content: "";
        position: absolute;
        left: 12.5px;
        bottom: 0;
      }
      &.expanded {
        .class-selector__classes {
          overflow-y: auto;
        }
        &:after {
          display: none;
        }
        .class-selector__grade-title {
          &:not(.selected) {
            border-radius: 0;
            border-bottom: 1px solid $grey-lighter;
          }
        }
      }
      &:hover {
        border: 2px solid hsla(0, 0%, 93%, 0.435);
        border-radius: $border-radius;
      }
      &.selected {
        border: 2px solid $primary-color;
        border-radius: $border-radius;
      }
      &.expanded .class-selector__classes {
        max-height: 280px; /* adjust as needed */
        padding: 10px 0;
      }

      &-title {
        font-size: 14px;
        cursor: pointer;
        padding: 0 5px;
        // border-bottom: 1px solid $grey-lighter;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-radius: $border-radius;
        font-size: 15px;
        // &.selected {
        //   border: 2px solid $primary-color;
        // }
        &:hover {
          background-color: hsla(0, 0%, 93%, 0.435);
        }
        .grade-name {
          font-weight: 400;
          color: $grey;
          padding: 0 20px;
        }
        .grade-toggle {
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      &__toggle {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        padding: 0 10px;
      }
    }
  }
}
