@import "../../scss/vars.scss";

.dashboard {
  margin-top: 110px;
  width: calc(100vw - 390px);
  margin-left: 360px;
  // overflow-x: hidden;
  padding-bottom: 100px;
  @include md {
    margin-left: 0;
    width: 100vw;
  }
  .button-multiple-toggle {
    margin: 15px 0;
    .wrapper {
      box-shadow: unset;
      box-shadow: $box-shadow;
      width: calc(100% - 20px);
      .item {
        width: 33%;
      }
    }
  }
  &__sections {
    .section {
      // padding: 20px;
      text-align: left;
      &__title {
        font-size: 25px;
        font-weight: 300;
        color: $grey;
      }
    }
  }
}
