@import "../../../scss/vars";

.reading-room {
  border-left: 7px solid $blue-dark;
  &.is-expanded {
    border-left: unset;
  }
  &__preview {
    display: flex;
    align-items: center;
    height: 100%;
    span {
      color: $grey;
    }
  }
  &__content {
    // padding: 20px;
    height: 100%;
    .controls {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: flex-end;
      gap: 10px;
      width: calc(100% - 60% - 200px);
      .cta {
        padding: 5px 30px;
      }
    }
  }
  &__header {
    box-shadow: $box-shadow;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 300;
      color: $grey;
      font-size: 20px;
    }
  }
  &__search {
    padding: 20px;
    input {
      width: 100%;
    }
  }
  &__users {
    padding: 20px;
    height: calc(100% - 150px);
    overflow-y: auto;
    width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-header {
    }
    &-item {
      //   box-shadow: $box-shadow;
      border: 1px solid $grey-lighter;
      border-radius: $border-radius;
      padding: 20px;
      display: flex;
      //   justify-content: space-between;
      position: relative;
      &.active {
        border: 1px solid $primary-color;
      }
      .user-container {
        display: flex;
        align-items: center;
        gap: 10px;
        border-right: 1px solid $grey-lighter;
        width: 220px;
        &__img {
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &__name {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 15px;
          color: $grey;
        }
      }
      .comment-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: calc(100% - 50% - 200px);
        padding-left: 20px;
        &__label {
          font-weight: 300;
          color: $grey;
          font-size: 14px;
        }
        textarea {
          resize: none;
          box-shadow: none;
          border: 1px solid $grey-even-lighter;
        }
      }
      .time-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        width: calc(100% - 50% - 200px);
        padding-left: 20px;
        &__wrapper {
          width: 280px;
          span {
            display: flex;
            color: $grey;
            width: 100%;
            height: 50px;
            justify-content: space-between;
            align-items: center;
            font-weight: 300;
            input {
              padding: 5px 20px;
              box-shadow: unset;
              border: 1px solid $grey-even-lighter;
            }
          }
        }
        &__content {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-weight: 300;
          color: $grey;
          span {
            span {
              color: black;
              margin-left: 5px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
