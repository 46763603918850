@import "src/scss/vars";

.dashboard__sections {
  .active-users-section {
    .section__title {
      margin-left: 20px;
    }
    .active-users {
      margin-top: 10px;
      display: flex;
      overflow-y: auto;
      width: 100%;
      padding: 10px;
      margin-left: 10px;
      &.loading {
        .loader {
          width: 200px;
          margin-right: 20px;
          height: 65px;
          .loader-card {
            height: 100%;
            &__item {
              height: 100%;
              margin: 0;
            }
          }
        }
      }
      .active-user {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 10px 20px;
        text-decoration: none;
        color: $grey;
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.04);
        }
        &__img {
          position: relative;
          &:after {
            position: absolute;
            bottom: -3px;
            right: -3px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            box-shadow: $box-shadow;
            border: 3px solid white;
            content: "";
            background-color: $green-color;
          }
          img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            box-shadow: $box-shadow;
            object-fit: cover;
          }
        }
        &__info {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
        }
        &__last-page {
          font-weight: 300;
          color: $grey;
          font-size: 12px;
        }
        &__name {
          display: flex;
          span {
            font-size: 17px;
            font-weight: 300;
            &:nth-child(2) {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
