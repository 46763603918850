@import "../../../scss/vars";

.parent-contact {
  &__title {
    padding: 0 20px;
    font-weight: 300;
    color: $grey;
    &.student {
      margin-top: 20px;
    }
  }
  &.is-expanded {
    border-left: unset;
  }
  &__preview {
    display: flex;
    align-items: center;
    height: 100%;
    span {
      color: $grey;
    }
  }
  &__content {
    overflow-y: auto;
    height: 100%;
    .controls {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: flex-end;
      gap: 10px;
      width: calc(100% - 60% - 200px);
      .cta {
        padding: 5px 30px;
      }
    }
  }
  &__header {
    box-shadow: $box-shadow;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    span {
      font-weight: 300;
      color: $grey;
      font-size: 20px;
    }
  }
  &__search {
    padding: 20px;
    input {
      width: 100%;
    }
  }
  &__students {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    .students-item {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      display: flex;
      padding: 20px;
      text-decoration: none;
      &__personal {
        border-right: 1px solid $grey-lighter;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        min-width: 80%;
        text-decoration: none;
        color: $grey;
        &-img {
          img {
            height: 50px;
            width: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        &-name {
          display: flex;
          gap: 6px;
        }
      }
      &__class {
        padding: 0 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid $grey-lighter;
        span {
          color: $grey;
          font-weight: 300;
        }
      }
      &__edit,
      &__save {
        width: 20%;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
      }
    }
  }
  &__add {
    padding: 20px;
  }
  &__selector {
    padding: 20px;
    &-title {
      span {
        font-weight: 300;
        color: $grey;
        margin-bottom: 6px;
        display: block;
      }
    }
    .grades-list,
    .classes-list {
      display: flex;
      gap: 15px;
      margin-bottom: 20px;
      flex-wrap: wrap;
      &__item {
        box-shadow: $box-shadow;
        padding: 10px 20px;
        border-radius: $border-radius;
        transition: 0.3s ease-in-out;
        border: 2px solid white;
        &.selected {
          border: 2px solid $primary-color;
        }
        span {
          color: $grey;
          font-weight: 300;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
    }
  }
}
