@import "src/scss/vars";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  color: black;
  height: auto;
  // width: 100vw;
  padding: 0 40px;
  justify-content: center;
  z-index: 999;
  margin: 15px 30px;
  width: calc(100vw - 60px);
  border-radius: $border-radius;
  @include md {
    padding: 0 20px;
    margin: 18px 20px;
    width: calc(100% - 40px);
  }

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.301);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 15px 1px #8080802d;
    border-radius: $border-radius;
  }

  &__bus {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    &-close {
      background-color: white;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: calc(10vh - 50px);
      right: 10vw;
      z-index: 99999999999;
      &:hover {
        cursor: pointer;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &-wrapper {
      position: relative;
      z-index: 9999999999;
      overflow: hidden;
      width: 80vw;
      height: 80vh;
      background-color: white;
      border-radius: $border-radius;
      &__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
        .containLoader {
          position: relative;
          height: 60px;
          width: 60px;
          margin: auto;
          .circleGroup {
            position: absolute;
            height: 100%;
            width: 100%;
          }
          @for $i from 1 through 8 {
            .circle-#{$i} {
              border: 7px solid #6225e6;
              // border: 10px solid black;
              border-radius: 50%;
              box-sizing: border-box;
              border-right: 7px solid transparent;
              border-bottom: 7px solid transparent;
              border-left: 7px solid transparent;
              -webkit-animation: rotate
                1.2s
                cubic-bezier(0, 0.58, 1, 0.3)
                infinite;
              -moz-animation: rotate
                1.2s
                cubic-bezier(0, 0.58, 1, 0.3)
                infinite;
              -o-animation: rotate 1.2s cubic-bezier(0, 0.58, 1, 0.3) infinite;
              animation: rotate 1.2s cubic-bezier(0, 0.58, 1, 0.3) infinite;

              -webkit-animation-delay: 30ms * $i;
              -moz-animation-delay: 30ms * $i;
              -o-animation-delay: 30ms * $i;
              animation-delay: 30ms * $i;
            }
          }
          .innerText {
            position: absolute;
            bottom: -40px;
            animation: flash 1.2s ease-in-out infinite;
            pointer-events: none;
          }
        }

        @keyframes rotate {
          50% {
            border: 1px solid rgb(0, 255, 153);
            border-right: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-left: 1px solid transparent;
          }
          100% {
            transform: rotatez(360deg);
          }
        }
        @keyframes flash {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
  }

  &-container {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @include md {
      min-height: 70px;
    }
    &__logo {
      margin-left: 0;
    }

    &__menu-icon {
      display: none;
      @include md {
        width: 100px;
        justify-content: flex-start;
      }
    }

    &__links {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      padding: 0;

      &-item {
        &:hover {
          cursor: pointer;
        }
        &.home {
          display: none;
        }
        @media screen and (max-width: 992px) {
          display: none;
        }
        .link-item {
          text-decoration: none;
          color: #212121;
          font-size: 18px;
          font-weight: 400;
          padding: 19px 15px;
          transition: all 0.2s ease-in-out;
          font-size: 18px;
          color: $grey;
          display: flex;
          align-items: center;
          &.hidden-super {
            display: none;
          }
          svg {
            margin-bottom: 0 !important;
            padding: 0 !important;
            margin-right: 10px;
          }
          &:hover {
            color: $primary-color;
            svg {
              path {
                color: $primary-color;
              }
            }
          }
          &.active {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            &:hover {
              cursor: pointer;
            }
            svg {
              path {
                color: $primary-color;
              }
            }
          }
        }
      }

      li {
        margin: 0 15px 0 0;
        list-style-type: none;
        &.main-logo {
          @include md {
            margin-left: 0;
          }
          img {
            padding: 5px;
            max-height: 60px;
            object-fit: cover;
            max-width: 200px;
            @include md {
              max-height: 70px;
              max-width: 40vw;
            }
          }
          a {
            &:hover {
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }

    &__links-mobile {
      display: flex;
      flex-wrap: wrap;
      background: white;
      padding: 0;
      list-style-type: none;
      z-index: 9999; // Make sure it's on top
      transition: all 500ms cubic-bezier(1, 0.36, 0.41, 0.9);
      backdrop-filter: blur(10px) opacity(0);
      opacity: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      border-radius: 10px;
      display: none;
      width: 100vw;
      backdrop-filter: blur(10px) opacity(1);
      background: rgba(255, 255, 255, 0.7);
      opacity: 1;
      border-radius: 0;
      position: fixed;
      margin: 0;
      bottom: 0; // Ensures it's at the bottom of the viewport
      height: calc(
        75px + env(safe-area-inset-bottom)
      ); // Adjust height considering the safe area insets
      transform: translateY(0); // Keep it visible at the bottom
      @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: env(
          safe-area-inset-bottom
        ); // Ensures it respects the safe area inset
      }
      @include md {
        display: block;
      }

      .navbar-container__links-mobile-item {
        opacity: 1;
      }

      .nav-items {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        justify-content: space-between;
        padding: 0 20px;
        overflow: hidden;
        &.parent {
          justify-content: space-around;
        }
      }
      &-item {
        font-size: 1rem;
        transition: all 200ms ease-in-out;
        opacity: 0;
        width: unset;
        overflow: hidden;
        padding: 7px 0;
        &.hidden-super {
          display: none;
        }
        @include md {
          font-size: 8px;
        }
        .link-item {
          text-decoration: none;
          color: black;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $grey;
          display: flex;
          flex-direction: column;
          font-size: 9px;
          padding: 5px 0 10px;
          @media screen and (max-width: 360px) {
            font-size: 8px;
          }
          @media screen and (max-width: 300px) {
            display: none;
          }
          &.active {
            color: $primary-color;
            svg {
              fill: $primary-color;
            }
          }
          &:hover {
            color: $primary-color;
            @include md {
              color: $grey;
            }
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }

    &__user {
      position: relative;
      display: flex;
      width: 100px;
      justify-content: flex-end;
      align-items: center;
      .christmas-mode {
        margin-right: 10px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        @include md {
          display: none;
        }
        .toggle {
          padding-top: 5px;
          height: 50px;
          display: flex;
          align-items: center;
        }
        span {
          display: flex;
          align-items: center;
          margin-right: 7px;
          font-size: 30px;
          height: 37px;
          font-weight: 200;
          color: $grey;
        }
      }
      &-bus {
        transition: 0.3s ease-in-out;
        margin-right: 5px;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
      &-icon {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          &:hover {
            cursor: pointer;
          }
        }
      }
      &-settings {
        margin-right: 15px;
        position: relative;
        width: 32px;
        height: 32px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        svg {
          margin: 0 !important;
          padding: 0 !important;
          &.active {
            color: $primary-color !important;
          }
        }
        &:hover {
          // background-color: rgba(131, 131, 131, 0.141);
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
  }
  &-popup {
    position: absolute;
    top: 60px;
    right: 40px;
    width: 300px;
    background-color: rgba(255, 255, 255, 1);
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
    -webkit-box-shadow: 0px 6px 15px 8px rgba(0, 0, 0, 0.42);
    box-shadow: 0px 10px 15px 8px rgba(0, 0, 0, 0.22);
    border-radius: 1.25rem;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    opacity: 0;
    @include md() {
      width: 80vw;
      left: 10vw;
      top: 70px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      margin-left: 15px;
    }
    &__schools {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      flex-wrap: wrap;
      &-info {
        color: $grey-light;
        font-size: 14px;
        font-weight: 300;
      }
      .school {
        width: 45%;
        margin-bottom: 15px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &.active {
          border: 2px solid $primary-color;
        }
        &.long {
          .delete-options {
            display: block;
          }
        }
        .delete-options {
          position: absolute;
          top: -10px;
          right: -10px;
          display: none;
        }
        &-logo {
          img {
            height: 60px;
            width: auto;
            max-width: 100px;
            object-fit: cover;
          }
        }
        &-name {
          color: black;
          font-weight: 200;
          margin: 0;
        }
        &-email {
          text-decoration: none;
          color: $grey;
          font-size: 14px;
          font-weight: 300;
          margin: 0;
          word-break: break-word;
        }
      }
    }
    &__button {
      padding: 10px;
      margin: 20px 0;
      border-radius: 45px;
      background-color: rgba(255, 255, 255, 0.418);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
      color: black;
      transition: 0.4s transform ease-in-out;
      width: 100%;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      &-profile {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        box-shadow: $box-shadow;
        object-fit: cover;
        align-self: flex-start;
      }
      &:hover {
        background-color: #6225e6;
        box-shadow: 0px 15px 20px #6225e65c;
        color: #fff;
        transform: scale(1.08);
        cursor: pointer;
        svg {
          path {
            color: white;
          }
        }
      }
      .child-name {
        // width: 100%;
      }
    }
    &__settings {
      padding-left: 10px;
      border-left: 2px solid $grey-light;
      margin-left: 25px;
      svg {
        margin: 0 !important;
        padding: 0 !important;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &.active {
      max-height: 700px;
      opacity: 1;
      padding: 30px;
      overflow-y: auto;
    }

    &__user {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      align-items: center;
      justify-content: center;
      img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
      label {
        padding: 10px;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    &__logout {
      display: flex;
      padding-top: 30px;
      justify-content: center;

      img {
        width: 18px;
        margin-right: 8px;
      }
      span {
        font-size: 18px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
