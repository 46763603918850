@import "../../scss/vars.scss";

.edit-dep-users {
  position: relative;
  &.not-expanded {
  }
  .preview {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .content {
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    z-index: 1000;
    z-index: 99999;
    padding: 20px;
    padding-top: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    &__label {
      margin-bottom: 10px;
      color: $grey;
      font-weight: 300;
      display: block;
    }
    &__search {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      input {
        width: 400px;
      }
    }
    &__list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-bottom: 30px;
      margin-top: 30px;
      &-item {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: 10px 5px;
        width: calc(20% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // transition: 0.3s ease-in-out;
        position: relative;
        .action {
          position: absolute;
          top: -10px;
          right: -10px;
        }
        .profile-img {
          width: 70px;
          height: 70px;
        }
        &:hover {
          // transform: scale(1.03);
          cursor: pointer;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}
