@import "../../../../scss/vars.scss";

.grades-section {
  margin-top: 20px;
  padding: 0 20px;
  pointer-events: all;
  height: 48vh;
  overflow-y: auto;
  @include md {
    height: 87vh;
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .rank-item {
    &__name {
      width: 160px;
      margin-bottom: 10px;
      box-shadow: none !important;
      border: 1px solid $grey-lighter;
    }
    &__input {
      display: flex;
      // box-shadow: $box-shadow;
      margin-top: 10px;
      border-radius: 30px;
      padding: 10px 7px;
      border: 1px solid $grey-lighter;
      svg {
        padding: 0 !important;
        margin: 0 !important;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.03);
          background: $primary-color !important;
        }
      }
      input {
        width: 40px;
        border: none;
        outline: none;
        // border: 1px solid black;
        // border-bottom: 1px solid $grey-even-lighter;
        display: block;
        margin: 0 5px;
        text-align: center;
        color: $grey;
        -moz-appearance: textfield;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  &__progress {
    width: 86%;
    background-color: $primary-color-opacity-big;
    border-radius: $border-radius;
    height: 10px;
    position: relative;
    .total {
      background-color: $primary-color;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: $border-radius;
      height: 100%;
    }
  }
  .max-rank {
    margin-top: 15px;
    &__wrapper {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .rank-item__input {
        margin-right: 10px;
        margin-top: 0;
        border: none;
        box-shadow: $box-shadow;
      }
      // input {
      //   margin-right: 20px;
      //   width: 100px;
      //   box-shadow: unset;
      //   border-radius: unset;
      //   border-bottom: 1px solid #cacaca;
      //   text-align: center;
      //   padding: 2px 10px;
      // }
    }
    &__label {
      margin-right: 20px;
      font-size: 17px;
      color: $grey;
      font-weight: 300;
    }
  }
  .detailed-rank {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    &__label {
      font-size: 15px;
      color: $grey;
      font-weight: 300;
    }
    .list {
      padding-top: 0;
      margin-top: 10px;
      display: flex;
      justify-content: unset;
      flex-wrap: wrap;
      @include md {
        padding: 0;
        justify-content: space-between;
      }
      &__limit {
        height: 145px;
        display: flex;
        align-items: center;
        width: 250px;
        // border: 1px solid red;
        span {
          font-size: 12px;
          color: $grey;
          font-weight: 300;
        }
      }
      &__add {
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        height: 170px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.04);
        }
        .label {
          margin-top: 5px;
          color: $grey;
          font-weight: 300;
          font-size: 15px;
        }
      }
      .rank-item {
        margin-bottom: 30px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px;
        margin-right: 30px;
        align-items: flex-start;
        height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include md {
          width: 47%;
          margin-right: 0;
        }
        .rank-label {
          text-align: left;
          font-size: 22px;
          font-weight: 400;
        }
        .rank-sub-label {
          font-size: 14px;
          color: $grey;
          font-weight: 300;
        }
        // input {
        //   margin-top: 15px;
        //   width: 100px;
        //   box-shadow: inset $box-shadow;
        //   font-size: 20px;
        //   padding: 10px 15px;
        // }
        &:hover {
          > .remove {
            display: block;
          }
        }
        .remove {
          display: none;
          position: absolute;
          top: -10px;
          right: -10px;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            transform: scale(1.08);
          }
        }
      }
    }
  }
}
