@import "../../../../scss/vars";

.professor-exams {
  &__edit {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    border: 1px solid red;
    z-index: 99999999999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 86px;
    position: sticky;
    top: 100px;
    z-index: 9;
    &.open {
      z-index: 9999;
      .professor-exams__search {
        display: none;
      }
      .professor-exams__mobile-filters {
        display: none;
      }
    }
  }
  .events__item-create {
    width: 100%;
    padding: 8px 20px;
    background-color: white;
    margin-right: 20px;
    transition: 0.3s ease-in-out;
    @include md {
      margin-bottom: 0;
      height: 60px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding: 8px 10px;
      margin-right: 10px;
    }
    .label-create {
      transition: max-width 0.3s ease-in-out;
      color: $grey !important;
      font-weight: 300 !important;
      display: block;
      margin-left: 15px;
      font-size: 16px;
      @include md {
        font-size: 12px;
      }
    }
    &.closed {
      max-width: 48%;
      @include md {
        max-width: 60px;
        .events__item-create-info {
          padding-right: 0 !important;
          margin: 0 auto;
        }
        .label-create {
          max-width: 0;
          overflow: hidden;
          margin: 0 !important;
        }
      }
      &.is-open {
        max-width: 100% !important;
        @include md {
          max-width: calc(100% - 140px) !important;
        }
      }
    }
    &.open {
      max-width: 88%;
      @include md {
        max-width: calc(100% - 140px);
      }
      &.is-open {
        max-width: 100% !important;
      }
    }
  }
  &__mobile-filters {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 100%;
    background-color: white;
    max-height: 60px;
    height: 70vh;
    max-width: 60px;
    margin-left: 20px;
    display: none;
    overflow-y: hidden;
    position: relative;
    @include md {
      display: block;
      margin-left: 10px;
    }
    &.open {
      max-width: calc(100% - 140px);
      max-height: 70vh;
      .professor-exams__mobile-filters-preview {
        justify-content: flex-end;
      }
    }
    &.closed {
      max-width: 60px;
      overflow: hidden;
    }
    &-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      height: 60px;
      span {
        color: $grey;
        font-weight: 200;
        font-size: 14px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 20px;
        svg {
          margin-left: 10px !important;
        }
      }
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
  &__search {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    width: 100%;
    background-color: white;
    @include md {
      height: 60px;
      padding: 0;
    }
    .search-close {
      transition: 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.15);
      }
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &.closed {
      max-width: 10%;
      @include md {
        max-width: 60px;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.08);
      }
      input {
        padding: 0;
        overflow: hidden;
        max-width: 0;
      }
    }
    &.open {
      max-width: 50%;
      @include md {
        max-width: calc(100% - 140px);
      }
      input {
        margin-right: 20px;
      }
    }
    input {
      width: 80%;
      display: block;
      box-shadow: unset;
      border-radius: unset;
      padding: 10px;
      font-weight: 300;
      color: $grey;
      @include md {
        width: 72%;
        font-size: 12px;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    // justify-content: space-between;
    &-card {
      // margin-right: 4%;
      // &:nth-child(4n + 1) {
      //   margin-right: 0;
      // }
      &-delete {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9999;
        z-index: 999999999;
        display: none;
        &.show {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .wrapper {
          padding: 30px;
          background-color: white;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          width: 50vw;
          &__title {
          }
          &__actions {
            margin-top: 50px;
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
