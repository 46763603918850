@import "../../scss/vars.scss";

.chat-history {
  margin-right: 25px;
  position: relative;
  &__new {
    position: fixed;
    bottom: calc(110px + 70vh);
    right: 50px;
    width: 450px;
    z-index: 999999999;
  }
  &__chatItem {
    position: fixed;
    z-index: 9999999;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    left: 0;
    transform: translateX(110vw);
    transition: 0.5s ease-in-out;
    display: none;
    @include md {
      display: block;
    }
    &.open {
      transform: translateX(0);
    }
  }
  &__icon {
    padding: 10px;
    box-shadow: $box-shadow;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    position: relative;
    background-color: $primary-color;
    position: fixed;
    bottom: 25px;
    right: 25px;
    @include md {
      bottom: 100px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
      margin-top: 5px !important;
      fill: white;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
    .count {
      position: absolute;
      height: 24px;
      width: 24px;
      background-color: $red;
      border-radius: 50%;
      top: -8px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
      }
    }
  }
  &__history {
    border-radius: $border-radius;
    width: 450px;
    position: fixed;
    z-index: 99999;
    background-color: white;
    box-shadow: $box-shadow;
    bottom: 100px;
    right: 50px;
    overflow: hidden;
    padding: 0;
    text-align: left;
    height: 70vh;
    transform: translateY(100vh);
    @include md {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
      transform: translateX(110vh);
      transition: 0.5s ease-in-out;
    }
    &.active {
      // max-height: 68vh;
      transform: translateY(0);
      overflow-y: auto;
      @include md {
        max-height: 100vh;
        transform: translateX(0);
      }
    }
    &-search {
      position: sticky;
      top: 0;
      background-color: white;
      padding: 20px;
      z-index: 99;
      @include md {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .back {
        display: none;
        width: 40px;
        @include md {
          display: block;
        }
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
      input {
        width: 100%;
        background-color: white;
        border: unset;
        border-bottom: 1px solid $grey-lighter;
        border-radius: none;
        box-shadow: none;
        outline: none;
        padding: 8px 12px;
        &:focus {
          transform: none;
          box-shadow: none;
        }
        @include md {
          width: calc(100% - 50px) !important;
        }
      }
    }
    .chat-history {
      margin-top: 15px;
      padding: 0 20px;
      margin-right: 0;
      .label {
        font-size: 18px;
        color: $grey;
        font-weight: 300;
      }
      .sublabel {
        font-size: 16px;
        color: $grey-light;
        font-weight: 300;
      }
      .list {
        &__item {
          margin: 10px 0;
          padding: 10px 20px;
          border-radius: $border-radius;
          // box-shadow: $box-shadow;
          border: 1px solid $grey-even-lighter;
          transition: 0.2s ease-in-out;
          display: flex;
          // border: 2px solid white;
          &.not-read {
            // border: 2px solid $primary-color;
            background-color: $grey-even-lighter;
            border: 2px solid $grey-even-lighter;
            position: relative;
            &:after {
              position: absolute;
              content: "";
              height: 7px;
              width: 7px;
              border-radius: 50%;
              background-color: $red;
              top: 17px;
              right: 20px;
            }
            .info {
              .message {
                color: black;
              }
            }
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
          &-img {
            position: relative;
            &.active {
              &:after {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 17px;
                width: 17px;
                border: 3px solid white;
                background-color: $color-green;
                border-radius: 50%;
                content: "";
              }
            }
            img {
              height: 50px;
              width: 50px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .info {
            margin-left: 15px;
            display: flex;
            flex-direction: column;
            width: calc(100% - 50px);
            .name {
              font-size: 17px;
              // font-weight: 300;
              color: $grey;
              span {
                &:nth-child(2) {
                  margin-left: 6px;
                }
              }
            }
            .type {
              width: 100%;
              display: flex;
              // justify-content: flex-end;
              span {
                font-size: 14px;
                color: $grey;
                font-weight: 300;
              }
            }
            .message {
              margin-top: 3px;
              font-size: 15px;
              color: $grey;
              display: inline-block; /* or block, depending on your layout needs */
              max-width: 90%; /* Adjust max-width as needed, 100% to fit container's width */
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
