@import "../../scss/vars.scss";

.student-exam-score {
  .wrapper {
    display: flex;
    align-items: center;
  }
  .online-exam-no-score {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .no-score {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    .wrapper {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      // background-color: $primary-color;
      // box-shadow: $box-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &__back {
        // position: absolute;
        // z-index: -1;
        // opacity: 0.2;
        svg {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
      span {
        // color: white;
        margin-left: 20px;
        color: $grey-light;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
  .total-score {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    position: relative;
    padding-bottom: 20px;
    @include md {
      width: 100%;
    }
    .label {
      color: $grey;
      font-weight: 300;
      margin-bottom: 10px;
    }
    &__wrapper {
      background-color: $primary-color;
      position: relative;
      border-radius: $border-radius;
      display: flex;
      width: fit-content;
      justify-content: center;
      align-items: center;
      padding: 15px 20px;
      height: 100%;
      height: auto;
      .score {
        height: 150px;
        position: relative;
        .CircularProgressbar {
          height: 150px;
          width: 150px;
          .CircularProgressbar-trail {
            stroke: $primary-color-pastel;
          }
          .CircularProgressbar-path {
            stroke: white;
          }
        }
        &__label {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 100px;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 50%;
          color: $primary-color;
          font-size: 22px;
          font-weight: 500;
        }
      }
      .comments {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        .sub-label {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.583);
        }
        .label {
          font-size: 16px;
          color: white;
        }
      }
    }
  }
  .detailed-score {
    @include md {
      order: 2;
    }
    margin-left: 30px;
    .label {
      font-size: 400;
      color: $grey;
      opacity: 0;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;
        padding: 10px 20px 30px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        margin-bottom: 20px;
        &-label {
          margin-bottom: 10px;
          font-weight: 300;
          color: $grey;
        }
        .CircularProgressbar {
          height: 100px;
        }
      }
    }
  }
}
