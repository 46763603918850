@import "../../../scss/vars.scss";

.create-user {
  position: relative;
  width: 60px;
  margin-left: 15px;
  height: 60px;
  margin-right: 20px;
  @include md {
    margin-left: 0;
  }
  @include md {
    height: 60px;
    margin-right: 10px;
    transition: 0.3s ease-in-out;
    span {
      font-size: 12px;
    }
  }
  &.is-collapsed {
    transition: 0.3s ease-in-out;
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    text-align: left;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    &.is-collapsed {
      height: 100%;
      background-color: white;
    }
    &.is-expanded {
      position: absolute;
      z-index: 9999999;
    }
  }
  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include md {
      padding: 0 10px;
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
    span {
      display: block;
      margin-left: 15px;
      color: $grey;
      font-weight: 300;
    }
  }
  &__config {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 40px;
    &-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
    }
    .user-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;
      width: 100%;
      input {
        box-shadow: unset;
        border: 1px solid $grey-even-lighter;
      }
      &__title {
        display: block;
        text-align: center;
        font-size: 18px;
        margin-bottom: 15px;
        color: $grey;
      }
      &__label {
        font-size: 14px;
        font-weight: 300;
        color: $grey;
        display: block;
        margin-bottom: 7px;
      }
      &__error {
        font-size: 14px;
        font-weight: 300;
        color: $red;
        display: block;
        margin-bottom: 7px;
      }
      input {
        width: 100%;
        color: $grey;
      }
      &__type {
        width: 100%;
        display: flex;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
        padding: 5px;
        border-radius: $border-radius;
        border: 1px solid $grey-even-lighter;
        .type-item {
          padding: 10px 20px;
          border-radius: $border-radius;
          margin: 0 5px;
          transition: 0.3s ease-in-out;
          @include md {
            font-size: 12px;
            margin: 0 3px;
            padding: 10px;
          }
          &:hover {
            cursor: pointer;
            background-color: $primary-color-opacity-big;
          }
          &.active {
            background-color: $primary-color;
            color: white;
            font-weight: 500;
          }
        }
      }
      &__img {
        display: flex;
        justify-content: center;
        width: 200px;
        height: 200px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        &:hover {
          cursor: pointer;
          .edit-item__img-overlay {
            display: flex;
          }
        }
        &-overlay {
          display: none;
          position: absolute;
          z-index: 2;
          bottom: 0;
          height: 100%;
          width: 100%;
          background-color: $primary-color-opacity-big;
          align-items: center;
          justify-content: center;
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
        input {
          position: absolute;
          opacity: 0;
          z-index: 3;
          height: 100%;
          width: 100%;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
