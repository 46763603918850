@import "../../scss/vars.scss";

.print-page-editor {
  padding: 30px;
  margin-top: 80px;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  position: relative; /* so z-index can work */
  z-index: 1;
  overflow: visible;

  .editor-preview {
    width: calc(100vw - 400px);
    display: flex;
    justify-content: center;

    &__template {
      border: 1px solid $grey-even-lighter;
      width: 700px;
    }
  }
}
