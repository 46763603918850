@import "../../../scss/vars.scss";

.toolbox-wrapper {
  width: 380px;
  height: calc(100vh - 200px);
  position: sticky;
  top: 120px;
  left: 40px;
  overflow-y: auto;
  // background-color: #fff;
  // box-shadow: $box-shadow;
  // border-right: 1px solid $grey-lighter;
  // border-radius: $border-radius;
  padding: 20px;

  &__components {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .components-wrapper {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      &__title {
        width: 100%;
        position: relative;
        &:after {
          top: 14px;
          left: 0;
          background-color: $grey-lighter;
          height: 1px;
          width: 100%;
          content: "";
          position: absolute;
        }
        span {
          font-weight: 300;
          color: $grey-light;
          background-color: white;
          position: relative;
          z-index: 9999;
          padding: 0 20px;
        }
      }
    }
    .toolbox-item {
      width: 47%;
      margin-bottom: 10px;
      padding: 8px;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      svg {
        margin: 0 !important;
        border-radius: 0 !important;
        padding: 0 !important;
      }
      span {
        font-weight: 300;
        color: $grey;
      }
      &:hover {
        cursor: pointer;
        background-color: #eee;
      }
    }
  }
}
