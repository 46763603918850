@import "src/scss/vars";

.announcements {
  text-align: left;
  padding: 0 20px 35px;
  @include md {
    margin-top: 0;
  }
  &__title {
    display: flex;
    &-label {
      font-size: 22px;
      font-weight: 200;
      color: $grey;
    }
  }
  .add-announcement__classes {
    width: 100%;
    .label {
      font-weight: 300;
      color: $grey;
      margin-bottom: 5px;
      display: block;
    }
    .classes {
      width: 100%;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      input {
        margin: 10px 10px 0;
        font-size: 13px;
        width: 94%;
        box-shadow: unset;
      }
      &__list {
        overflow-y: auto;
        max-height: 30vh;
        padding: 5px 10px 0;
        &-item {
          padding: 5px 20px;
          display: flex;
          position: relative;
          flex-direction: column;
          border: 2px solid white;
          border-radius: $border-radius;
          &.active {
            border: 2px solid $primary-color;
            &:after {
              display: none;
            }
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            border-radius: $border-radius;
            background-color: $grey-light;
            opacity: 0.8;
          }
          &:hover {
            cursor: pointer;
            background-color: $grey-super-light;
          }
          .class-name {
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            padding: 0;
          }
          .grade {
            font-size: 13px;
            color: $grey;
            font-weight: 300;
          }
        }
      }
    }
  }
  .add-announcement {
  }
  &__add {
    position: relative;
    box-shadow: $box-shadow;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-left: 15px;
    background-color: white;
    z-index: 99;
    &.open {
      border-radius: $border-radius;
      position: fixed;
      top: 50%; // Start by positioning the top of the container at the middle of the screen
      left: 0;
      right: 0;
      max-height: 90vh;
      overflow-y: auto; // Allows the content to scroll if it exceeds the max-height
      transform: translateY(-50%) !important;
      left: 5vw;
      height: auto;
      overflow-y: auto;
      width: 90vw;
      padding: 30px;
      z-index: 999999999;
      // margin: auto;
      @include md() {
        margin-left: 0;
        padding: 20px 10px;
      }
    }
    &-plus {
      &:hover {
        cursor: pointer;
      }
    }
    &-close {
      position: absolute;
      top: 25px;
      right: 25px;
      &:hover {
        cursor: pointer;
      }
      svg {
        box-shadow: $box-shadow;
      }
    }
  }
  &__list {
    margin-top: 20px;
    .item {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 15px 20px 10px;
      border-left: 7px solid $primary-color;
      border-left: 7px solid #ff5677;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
      background-color: white;
      position: relative;
      overflow-x: hidden;
      &.open-image {
        z-index: 1000;
      }
      &__delete {
        position: absolute;
        top: 10px;
        right: 10px;
        &-init {
          &:hover {
            cursor: pointer;
          }
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        &-options {
          position: absolute;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          left: -110px;
          bottom: -50px;
          span {
            font-size: 14px;
            color: $grey;
            font-weight: 300;
            padding: 6px 17px;
            border-radius: $border-radius;
            display: block;
            &:hover {
              cursor: pointer;
              background-color: $grey-even-lighter;
            }
          }
        }
      }
      @include md() {
        display: flex;
        flex-direction: column;
      }
      .created_by {
        width: 100%;
        height: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &__img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          box-shadow: $box-shadow;
          object-fit: cover;
        }
        &__info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .name {
            display: flex;
            font-size: 18px;
            span {
              font-weight: 500;
              color: $grey;
              &:nth-child(2) {
                margin-left: 7px;
              }
            }
          }
          .date {
            font-size: 12px;
            color: $grey;
            font-weight: 200;
            display: flex;
            gap: 6px;
            flex-wrap: wrap;
            &__info {
              display: flex;
              gap: 6px;
              &-to {
                display: flex;
                gap: 6px;
              }
            }
          }
        }
        &__tags {
          display: flex;
          height: 100%;
          margin-left: 20px;
          gap: 10px;
          .tag-item {
            background-color: $primary-color;
            color: white;
            padding: 5px 15px;
            border-radius: $border-radius;
            font-size: 12px;
          }
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include md {
          flex-direction: column;
        }
        .details {
          width: 88%;
          @include md() {
            width: 100%;
          }
          .title {
            font-size: 20px;
            font-weight: 400;
            @include md() {
              word-break: break-word;
            }
          }
          .description {
            margin-top: 5px;
            font-size: 16px;
            color: $grey;
            @include md() {
              word-break: break-word;
            }
            figure {
              &:not(.media) {
                height: 18vh !important;
                width: auto;
              }
              img {
                height: 18vh !important;
                width: auto;
                @include md {
                  height: auto !important;
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
            img {
              height: 25vh !important;
              width: auto;
              @include md {
                height: auto !important;
              }
              &:hover {
                cursor: pointer;
              }
            }
          }
          .date {
            font-size: 13px;
            color: $grey;
            font-weight: 200;
            position: absolute;
            top: -22px;
            left: 12px;
            @include md() {
              font-size: 15px;
              top: -24px;
            }
          }
        }
        .delete-options {
          display: flex;
          margin-bottom: 10px;
          opacity: 0;
          @include md() {
            justify-content: flex-end;
            width: 100%;
            margin-top: 15px;
            opacity: 1;
          }
          svg {
            margin: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }
          }
          .check-icon {
            margin-right: 10px !important;
          }
        }
        .children {
          display: flex;
          height: 100%;
          .child {
            margin-left: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            overflow: visible;
            &__profile {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin: 0 auto;
              box-shadow: $box-shadow;
            }
            span {
              font-size: 14px;
              color: $grey;
              margin-top: 6px;
              height: 0;
              overflow: visible;
            }
          }
        }
      }
    }
  }
  .configure {
    height: 100%;
  }
  &__configure {
    position: relative;
    height: 100%;
    @include md() {
      overflow-y: auto;
      padding: 10px;
    }
    .ck-editor__editable {
      min-height: 160px;
    }
    .input-container {
      .label {
        font-size: 17px;
        color: $grey;
        margin-bottom: 7px;
        font-weight: 300;
        display: block;
      }
    }
    .title {
      font-size: 23px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
    }
    .pretitle {
      font-size: 19px;
      font-weight: 300;
    }
    .info {
      margin-top: 30px;
    }
    .group {
      display: flex;
      margin-top: 30px;
      width: 100%;
      justify-content: space-between;
      &__select {
        width: 100%;
        select {
          width: 100%;
        }
      }
    }
    .departments {
      margin-top: 20px;
      .sublabel {
        font-size: 14px;
        color: $grey-light;
        font-weight: 300;
        letter-spacing: 0.5px;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-right: 20px;
          border-radius: $border-radius;
          box-shadow: $box-shadow;
          padding: 13px 18px;
          transition: 0.3s ease-in-out;
          border: 2px solid white;
          &.active {
            border: 2px solid $primary-color;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
        }
      }
    }
    .target {
      margin-top: 30px;
      padding-bottom: 0;
      &__select {
        display: flex;
        @include md() {
          flex-direction: column;
        }
        .item {
          display: flex;
          align-items: center;
          margin-right: 50px;
          @include md() {
            margin-bottom: 15px;
          }
          span {
            font-size: 17px;
            margin-left: 8px;
          }
          &:hover {
            cursor: pointer;
            > .target__select-radio {
              border: 4px solid $primary-color;
              transform: scale(1.08);
            }
          }
        }
        &-radio {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 4px solid white;
          transition: 0.2s ease-in-out;
          box-shadow: $box-shadow-dark;
          background-color: white;
          &.active {
            background-color: $primary-color;
          }
        }
      }
    }
    .save {
      padding-bottom: 20px;
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @include md() {
        position: unset;
        margin-top: 50px;
        button {
          width: 100%;
        }
      }
    }
  }
}
