@import "../../scss/vars";

.full-size-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999999999999;
  @include md {
    background-color: rgba(0, 0, 0, 1);
  }
  &:after {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    background-image: linear-gradient(rgb(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    z-index: 99999999;
    content: "";
  }
  &__container {
    position: absolute;
    top: 5vh;
    left: 5vw;
    height: 85vh;
    width: 90vw;
    @include md {
      top: 0;
      left: 0;
      width: 100vw;
      height: 90vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      // transition: 0.4s ease-in-out;
      &.zoomIn {
        // transform: scale(2);
      }
    }
  }
  &__close {
    position: fixed;
    top: 20px;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  &__controllers {
    position: fixed;
    bottom: 2vh;
    width: 100vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    z-index: 999999999999999999;
    &-item {
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
