@import "../../../../scss/vars.scss";

.professor-event-card {
  position: relative;
  // .timeline {
  //   width: 100%;
  //   border: 1px solid red;
  //   height: 20px;
  //   margin: 20px 0;
  //   display: flex;
  //   &-item {
  //     font-size: 12px;
  //     margin-right: 20px;
  //     font-weight: 300;
  //     color: $grey;
  //   }
  // }
  @include md {
    margin-top: 10px;
  }
  .parent-event-detailed {
    padding: 20px 40px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    .parent-side {
      display: flex;
      justify-content: center;
      width: 230px;
      flex-direction: column;
      border-right: 1px solid $grey-lighter;
      &__title {
        font-weight: 300;
        color: $grey;
        text-decoration: none;
      }
      .parent-detailed {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        width: 90%;
        padding: 20px;
        margin-top: 20px;
        margin-right: 30px;
        transition: 0.3s ease-in-out;
        text-decoration: none;
        color: $grey;
        &:hover {
          transform: scale(1.02);
        }
        img {
          height: 70px;
          width: 70px;
          object-fit: cover;
          border-radius: 50%;
        }
        &__name {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          text-decoration: none;
        }
        &__line {
          margin: 10px 0 5px;
          border-top: 1px solid $grey-lighter;
          width: 100%;
        }
        &__info {
          border: 1px solid $grey-lighter;
          border-radius: $border-radius;
          position: relative;
          padding: 12px 10px 5px;
          width: 100%;
          margin-top: 20px;
          &-label {
            position: absolute;
            top: -10px;
            left: 12px;
            background-color: white;
            font-weight: 300;
            color: $grey;
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }
    }
    .student-side {
      padding-left: 30px;
      width: calc(100% - 230px);
      &__title {
        font-weight: 300;
        color: $grey;
      }
      &__list {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 20px;
        width: 100%;
        &-item {
          text-decoration: none;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
          padding: 20px;
          width: 100%;
          transition: 0.3s ease-in-out;
          &:hover {
            transform: scale(1.02);
          }
          .child-personal {
            display: flex;
            // flex-direction: column;
            align-items: center;
            gap: 20px;
            img {
              width: 60px;
              height: 60px;
              object-fit: cover;
              border-radius: 50%;
            }
            &__name {
              display: flex;
              flex-direction: column;
              font-weight: 300;
              color: $grey;
              align-items: center;
              padding-right: 20px;
            }
          }
          .child-common {
            border-left: 1px solid $grey-lighter;
            padding-left: 20px;
            &__title {
              font-weight: 300;
              color: $grey;
              font-size: 13px;
            }
            &__list {
              margin-top: 5px;
              display: flex;
              flex-direction: column;
              color: $grey;
              font-weight: 400;
              font-size: 14px;
              gap: 4px;
            }
          }
        }
      }
    }
  }
  .event-parents-classes {
    padding: 20px;
    &__title {
      font-weight: 300;
      color: $grey;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 10px;
      .class-item {
        padding: 10px 15px;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        width: 18%;
        display: flex;
        justify-content: center;
        &:hover {
          // transform: scale(1.01);
        }
        font-weight: 300;
        color: $grey;
        font-size: 14px;
      }
    }
  }
  .event-parents-professors {
    padding: 20px;
    &__title {
      font-weight: 300;
      color: $grey;
    }
    &__list {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-top: 10px;
      flex-wrap: wrap;
      .professor-item {
        padding: 15px;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        text-decoration: none;
        transition: 0.3s ease-in-out;
        width: 32%;
        align-items: flex-start;
        &:hover {
          transform: scale(1.01);
        }
        &__personal {
          display: flex;
          gap: 10px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
          }
          .profile-name {
            display: flex;
            gap: 6px;
            flex-direction: column;
            color: $grey;
            font-weight: 300;
            align-items: center;
          }
        }
        &__availability {
          display: flex;
          gap: 15px;
          flex-direction: column;
          .availability-label {
            font-weight: 300;
            color: $grey;
            font-size: 14px;
          }
          .full-availability {
            // font-weight: 300;
            color: $grey;
          }
          .av-item {
            display: flex;
            gap: 4px;
            .av-day {
              color: $grey;
              font-weight: 300;
            }
            .av-time {
              color: $grey;
              font-weight: 500;
              display: flex;
              gap: 5px;
            }
          }
        }
      }
    }
  }
  .event-parents-scheduled {
    &__none {
      margin-top: 30px;
      display: block;
      width: 100%;
      text-align: center;
      span {
        color: $grey;
        font-weight: 300;
        font-size: 22px;
      }
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    box-shadow: $box-shadow;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    z-index: 9999;
    @include md {
      top: 10px;
      right: 10px;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .general {
    padding: 20px;
    @include md {
      padding: 0;
    }
  }
  .dismissal {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0 20px;
    @include md {
      padding: 0;
    }
    &__title {
      // text-align: center;
      font-size: 22px;
      color: $grey;
    }
    &__qr {
      padding: 40px;
      position: absolute;
      right: 0;
      top: 0;
      @include md() {
        padding-left: 0;
        right: unset;
        left: 0;
      }
    }
    &-users {
      width: 100%;
      .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        overflow-x: hidden;
        @include md {
          margin-bottom: 10px;
        }
        &.dismissed-users {
          padding-bottom: 30px;
          overflow-x: hidden;
        }
        .title {
          font-size: 18px;
          margin-left: 1.5%;
          font-weight: 300;
          color: $grey;
          justify-content: unset;
        }
        .users-list {
          display: flex;
          flex-wrap: wrap;
          &__label {
            display: block;
            width: 100%;
            text-align: center;
            color: $grey;
            font-weight: 200;
            font-size: 13px;
          }
          &__empty {
            margin-left: 2%;
            color: $grey-light;
          }
          .user {
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            margin: 10px 1% 10px;
            padding: 20px;
            width: 18%;
            position: relative;
            @include md() {
              width: 45%;
              margin: 10px 2% 10px;
            }
            &:hover {
              cursor: pointer;
            }
            &__info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              &-img {
                img {
                  height: 60px;
                  width: 60px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
              &-title {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                color: $grey;
                text-align: center;
              }
            }
            &__icon {
              position: absolute;
              top: -10px;
              right: -10px;
              svg {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  .start-online {
    display: none;
    @include md {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 20px;
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      span {
        margin-left: 15px;
        font-size: 14px;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 20px 30px;
    box-shadow: $box-shadow;
    height: 116px;
    z-index: 99;
    background-color: white;
    @include md {
      padding: 20px 10px;
      height: auto;
      position: relative;
    }
    &__subtitle {
      color: $grey;
      font-weight: 300;
      font-size: 14px;
    }
    .spacer {
      margin: 0 3px;
    }
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      .label {
        font-size: 23px;
        color: $grey;
        font-weight: 500;
        padding-right: 20px;
        @include md {
          width: 70%;
          padding-right: 0;
          text-align: center;
        }
      }
    }
    &__wrapper {
      display: flex;
      justify-content: center;
      @include md {
        margin-top: 10px;
        justify-content: center;
      }
      &-details {
        display: flex;
        align-items: center;
        margin-top: 10px;
        justify-content: center;
        flex-wrap: wrap;
        @include md {
          flex-direction: column;
          align-items: flex-start;
          width: 70%;
          // border: 1px solid red;
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      @include md {
        width: 100%;
        margin-bottom: 25px;
      }
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      .wrapper {
        margin-left: 10px;
        span {
          font-weight: 300;
          color: $grey;
          font-size: 14px;
          @include md {
            font-size: 15px;
          }
        }
      }
    }
    &__date {
      display: inline-flex;
      align-items: center;
      margin-top: 15px;
      width: fit-content;
      border-radius: $border-radius;
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        .date {
          font-size: 15px;
          color: $grey;
          font-weight: 300;
        }
        .time {
          font-size: 17px;
          color: $grey;
          font-weight: 300;
        }
      }
    }
    &__classroom {
      align-items: center;
      display: flex;
      width: fit-content;
      margin-left: 25px;
      border-radius: $border-radius;
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      .wrapper {
        margin-left: 15px;
      }
      span {
        font-size: 16px;
        color: $grey;
        font-weight: 300;
      }
    }
  }
}
