@import "../../scss/vars";

.drop-down-menu {
  svg {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__preview {
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    position: absolute;
    left: -90px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    overflow: hidden;
    background-color: white;
    display: none;
    &.active {
      display: block;
    }
    span {
      padding: 5px 15px;
      display: block;
      color: $grey;
      font-weight: 300;
      &:hover {
        cursor: pointer;
        background-color: $grey-even-lighter;
      }
    }
  }
}
