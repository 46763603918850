@import "../../scss/vars";

.quiz-student {
  .slick {
    &-slide {
      @include md {
        // padding: 20px;
      }
      &-active {
        opacity: 1;
        // transform: scale(1.3);
        transition: 0.3s ease-in-out;
        .quiz-student__item {
          box-shadow: 0 5px 10px 4px rgb(0 0 0 / 14%);
        }
      }
    }
  }
  &__slide {
    // box-shadow: 0 2px 10px 4px rgb(0 0 0 / 10%);
    // margin: 50px 200px;
    // padding: 30px;
    // border-radius: 1.25rem;
    // overflow-y: hidden;
    // background: white;
    height: calc(100vh - 200px);
    overflow: hidden;
    width: 100vw;
    position: relative;
    overflow-y: scroll;
    padding: 50px 0;
    align-content: center;
    @include md() {
      margin-top: 40px;
      margin-left: 0;
    }
    &-content {
      width: 70%;
      padding: 20px;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      margin-top: 250px;
      margin: auto;
      display: flex;
      flex-direction: column;
      height: fit-content;
      @include md {
        width: 95%;
      }
    }
    .editor-read-only {
      @include md {
        margin-top: 20px;
      }
    }
    &.final {
      width: calc(100% - 400px);
      box-shadow: unset;
      height: 400px;
      height: auto;
      transition: 1s ease-in-out;
      @include md() {
        width: 100%;
      }
      &.expand {
        // width: 100vw !important;
        margin: 0;
      }
    }
    &-header {
      text-align: left;
      padding-bottom: 15px;
      margin-bottom: 30px;
      position: relative;
      display: flex;
      justify-content: space-between;

      &.show-sucess {
        // background-color: #99f7ab;
        // border-radius: $border-radius;
        // color: white !important;
        // .ck-content {
        //   background-color: #99f7ab;
        // }
        .button-multiple-toggle {
          .wrapper {
            border: unset !important;
            border-radius: $border-radius;
            background-color: #99f7ab;
          }
        }
      }
      &.show-wrong {
        // background-color: #ff4d4d;
        // border-radius: $border-radius;
        // color: white !important;
        .button-multiple-toggle {
          .wrapper {
            border: unset !important;
            border-radius: $border-radius;
            background-color: #ff4d4d;
          }
        }
      }
      .wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        align-items: center;
        @include md {
          flex-direction: column;
          .wrapper {
            flex-direction: row;
          }
        }
        .editor-read-only {
          // width: calc(100% - 200px);
          width: 100%;
          margin-bottom: 0;
          margin-top: 15px;
          @include md {
            width: 100%;
          }
          .audio-file {
            margin-top: 10px;
          }
        }
        .button-multiple-toggle {
          margin-top: 15px;
          .wrapper {
            box-shadow: unset;
            border: 1px solid $grey-even-lighter;
          }
        }
      }
      &.hide {
        transition: 0.7s ease-in-out;
        transform: translateY(-100vh);
        opacity: 0;
        height: 0;
        transform: scale(0);
        padding: 0;
        margin: 0;
      }
      &:after {
        content: "";
        width: 100%;
        background-color: rgba(0, 0, 0, 0.109);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &-hint {
        width: 25px;
        height: 25px;
        border: 1px solid $grey-even-lighter;
        border-radius: 50%;
        transition: 0.4s ease-in-out;
        position: absolute;
        right: 0;
        top: 0;
        background-color: white;
        color: $grey;
        font-weight: 200;
        &.open {
          width: 450px;
          height: 250px;
          border-radius: 20px;
          position: absolute;
          right: 0;
          z-index: 9999999;
          box-shadow: $box-shadow;
          border: unset;
          @include md {
            width: 100%;
            height: fit-content;
            padding-bottom: 10px;
          }
        }
        &-button {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.1s ease-in-out;
          &:hover {
            cursor: pointer;
          }
          &.hide {
            transform: scale(0);
            height: 0;
          }
        }
        &-content {
          transition: 0.4s ease-in-out;
          padding: 70px 35px 35px;
          text-align: center;
          opacity: 1;
          transform: scale(1);
          height: 100%;
          &.hide {
            transform: scale(0);
            opacity: 0;
            transition: 0.4s ease-in-out;
          }
        }
        &-close {
          position: absolute;
          top: 10px;
          right: 10px;
          margin-left: auto;
          margin-right: auto;
          background-color: white;
          border: 1px solid $grey-lighter;
          border: none;
          outline: none;
          border-radius: 50%;
          outline: none;
          background-color: transparent;
          transition: 0.2s ease-in-out;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          svg {
            margin: 0 !important;
            padding: 0 !important;
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &__slider {
    &-item {
      width: 70%;
      margin-left: 15%;
      border-radius: 1.25rem;
      box-shadow: 0 2px 10px 4px rgb(0 0 0 / 10%);
      opacity: 0.4;
      .question {
        text-align: left;
        padding-bottom: 15px;
        margin-bottom: 30px;
        position: relative;
        &:after {
          content: "";
          width: 100%;
          background-color: rgba(0, 0, 0, 0.109);
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      &.active {
        opacity: 1;
        // transform: scale(1.3);
        transition: 0.3s ease-in-out;
        .quiz-student__item {
          box-shadow: 0 5px 10px 4px rgb(0 0 0 / 14%);
        }
      }
    }
  }
  &__error-msg {
    // border: 2px solid #e73c25;
    color: #e73c25;
    border-radius: 1.625rem;
    outline: none;
    background-color: transparent;
    height: 50px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.3s ease-in-out;
    &.active {
      transform: translateY(0);
      opacity: 1;
    }
  }
  &__answer {
    &-btn {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: white;
      border-radius: 1.625rem;
      outline: none;
      padding: 7px 25px 8px;
      transition: 0.2s ease-in-out;
      width: 170px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: darken($primary-color, 20);
        border-color: $primary-color;
        color: white;
        cursor: pointer;
      }

      &.answered {
        //border: 2px solid $green-color;
        //background: $green-color;
        //color: white;
        pointer-events: none;
      }
    }
  }
}
