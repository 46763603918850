@import "vars.scss";

body {
  -webkit-overflow-scrolling: touch !important; /* Enables smooth scrolling on iOS devices */
  scroll-behavior: smooth !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  div,
  label,
  span,
  button,
  a {
    font-family: "Commissioner", sans-serif;
    // letter-spacing: 0.08em !important;
  }
  @include md {
    overflow-y: hidden !important;
    height: 100vh;
  }
  overflow-x: hidden;
  .App {
    // overflow: hidden;
    @include md {
      height: calc(100vh - env(safe-area-inset-bottom));
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  &.modal-open {
    overflow: hidden;
  }
  .container {
    max-width: unset;
  }
  @include md {
    padding-bottom: 70px;
  }
}

input,
textarea {
  &::placeholder {
    color: $grey-light;
  }
}

.page {
  padding-top: 62px;
}

.fake-hide {
  opacity: 0 !important;
  pointer-events: none;
}

.fc {
  &:first-letter {
    text-transform: uppercase;
  }
}

.afc {
  & {
    text-transform: capitalize;
  }
}

.delete-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: -100vh;
  opacity: 0;
  left: 0;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.336);
  transform: scale(0);
  transition: 0.4s ease-in-out;
  border-radius: 50%;
  &.show {
    top: 0;
    transform: scale(1);
    opacity: 1;
    border-radius: 0;
  }
  .delete-container {
    position: absolute;
    background-color: white;
    top: 25vh;
    left: 20vw;
    width: 60vw;
    height: 50vh;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include md {
      width: 85vw;
      left: 7.5vw;
      top: 20vh;
      height: 60vh;
    }
    .title {
      font-size: 30px;
      text-align: center;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      @include md {
        flex-direction: column;
        button {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// .loading-background {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     background-color: #dddddd6c;
//     overflow-x: hidden;

//     &::after {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: -100%;
//         width: 100%;
//         height: 100%;
//         background: linear-gradient(to right, transparent, #ffffff);
//         animation: loadingAnimation 2s linear infinite;
//     }

//     &.animation-delay {
//         &__1 {
//             &:after {
//                 animation-delay: 0.1s;
//             }
//         }
//         &__2 {
//             &:after {
//                 animation-delay: 0.2s;
//             }
//         }
//         &__3 {
//             &:after {
//                 animation-delay: 0.3s;
//             }
//         }
//         &__4 {
//             &:after {
//                 animation-delay: 0.4s;
//             }
//         }
//         &__5 {
//             &:after {
//                 animation-delay: 0.5s;
//             }
//         }
//         &__6 {
//             &:after {
//                 animation-delay: 0.6s;
//             }
//         }
//     }

//     @keyframes loadingAnimation {
//         0% {
//         left: -100%;
//         }
//         100% {
//         left: 100%;
//         }
//     }
// }

.loading-background {
  $loading-light: #ffffff;
  $loading-dark: #f7f7f7;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%; /* Initial position off-screen */
    width: 200%; /* Twice the width to cover entire container */
    height: 100%;
    background: linear-gradient(
      to right,
      $loading-dark 8%,
      $loading-light 18%,
      $loading-dark 33%
    );
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.cta {
  border: 2px solid $primary-color;
  color: $primary-color;
  border-radius: $border-radius;
  outline: none;
  background-color: transparent;
  padding: 12px 45px;
  transition: 0.3s ease-in-out;
  display: block;
  position: relative;
  .special-icon {
    // position: absolute;
    // top: -23px;
    // right: -23px;
    svg {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  &-link {
    border: none;
    outline: none;
    color: $primary-color;
    background-color: unset;
  }
  &-small {
    padding: 7px 30px;
    font-size: 16px;
  }

  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;
    cursor: pointer;
    svg {
      path {
        color: white;
      }
    }
  }
  &.active {
    background-color: $primary-color;
    color: white;
    &:hover {
    }
  }

  &-fill {
    background-color: $primary-color;
    color: white;
    &:hover {
      background-color: transparent;
      color: $primary-color;
      svg {
        fill: $primary-color;
      }
    }
    svg {
      margin: 0 !important;
      padding: 0 !important;
      margin-left: 20px !important;
    }
  }

  &-primary {
    // background-color: #1FD1F9;
    // background-image: linear-gradient(45deg, #B621FE, #1FD1F9);
    background-color: transparent;
    color: #6225e6;
    &:hover {
      // background-image: linear-gradient(45deg, #832aaf, #48d1ef);
      background-color: #6225e6;
      color: white;
    }
  }

  &-secondary {
    background-color: transparent;
    border: 1px solid #1fd1f9;
    color: white;
    &:hover {
      background-color: #a88beb;
    }
  }

  &-red {
    background-color: transparent;
    border: 1px solid #f44a44;
    color: #f44a44;
    &:hover {
      background-color: #f44a44;
      border: 1px solid #f44a44;
    }
  }

  &-no-border {
    border: none;
  }

  &-gray {
    border: 1px solid gray;
    &:hover {
      background-color: rgb(216, 216, 216);
      span {
        color: white;
      }
    }
  }

  &-filled {
    @extend .cta;
    background: $primary-color;
    color: white;

    &:hover {
      background: transparent;
      color: $primary-color;
      border: 2px solid $primary-color;
    }

    &:focus {
      background: darken($primary-color, 10%);
      color: white;
      border: 2px solid darken($primary-color, 10%);
    }
  }

  &-shadow {
    border: none;
    box-shadow: $box-shadow;
    color: black;
    background-color: white;
    &:hover {
      background-color: white;
      transform: scale(1.05);
      color: black;
    }
  }
}

.logo-input {
  display: flex;
  box-shadow: $box-shadow;
  border-radius: 30px;
  align-items: center;
  padding: 10px 20px;
  svg {
    margin: 0 !important;
    padding: 0 !important;
  }
  input {
    display: block;
    margin-left: 20px;
    border: none;
    outline: none;
    color: $grey;
    width: 100%;
  }
}

.input {
  @extend .input-shadow;
  &-inverse {
    box-shadow: inset $box-shadow;
    &:focus {
      box-shadow: inset $box-shadow;
    }
  }
  &-no-shadow {
    box-shadow: unset !important;
    border: 1px solid $grey-lighter !important;
  }
}

.input-2 {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.286);
  padding: 8px 0;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    // color: white;
    font-family: "Commissioner", sans-serif;
    font-weight: 200;
  }
  &-primary {
  }
}

.input-3 {
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.07);
  padding: 6px 20px;
}

.input-shadow {
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.07);
  padding: 10px 20px;
  transition: all 0.3s;
  background: white;

  select {
    background: transparent;
  }

  &:focus {
    box-shadow: 10px 10px 20px #d1d1d1, -5px -5px 20px rgba(244, 244, 244, 0.48);
  }
}

.input-border {
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: transparent;
  outline: none;
  border-radius: 10px;
  transition: all 0.3s;
  padding: 6px 20px;

  &:focus {
    box-shadow: 10px 10px 20px #cccccc, -10px -10px 20px #ffffff;
  }
}

.input-neumorphism {
  border: none;
  outline: none;
  padding: 6px 20px;
  transition: all 0.3s;
  @extend .neumorphism;
  margin-top: 8px;

  &:focus {
    box-shadow: 10px 10px 20px #afafaf, -10px -10px 20px #ffffff,
      0 0 15px 1px $primary-color;

    //outline: 4px solid rgba(98, 37, 230, 0.14);
  }

  select {
    background: transparent;
  }
}

.select {
  &-main {
    border: 1px solid $primary-color;
    border-radius: $border-radius;
    padding: 7px 12px;
    width: 100%;
  }
}

.link {
  text-decoration: none;
  color: #6225e6 !important;
  font-weight: 500;
  letter-spacing: 0.04rem;
  &-light {
    color: white !important;
    &:hover {
      color: white !important;
    }
  }
  &:hover {
    cursor: pointer;
    color: #2e0e74 !important;
  }
}

.w {
  &-90 {
    width: 90%;
  }
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.244);
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 200;
    &.disabled {
      color: $grey-light;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.checkbox {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: $box-shadow-dark;
  border: 5px solid white;
  &.active {
    &:after {
      position: absolute;
      content: "";
      background-color: $primary-color;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.toggle {
  align-self: flex-end;
  justify-self: flex-end;
  @include md() {
    align-self: flex-start;
  }
  input:checked + label {
    color: var(--on);
    &.disabled {
      color: $grey-lighter;
    }
    &:after {
      left: 100%;
      transform: translateX(-100%);
    }
  }

  input {
    &.active + label {
      color: var(--on);
      &:after {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }

  &.disabled {
    input + label {
      color: $grey-lighter;
      &:after {
        left: 0;
        transform: translateX(0);
      }
    }
  }

  .switch {
    position: relative;
    display: block;
    margin-inline: auto;
    cursor: pointer;

    width: var(--toggle-width);
    height: var(--toggle-height);

    color: var(--off);
    background-color: currentColor;

    border: var(--border-width) solid currentcolor;
    border-radius: var(--toggle-height);

    transition: color var(--transition-time) ease-out;

    padding: 0;

    &:after {
      content: "";
      position: absolute;
      background-color: white;

      /* 	Make it fit and make it round */
      height: 100%;
      aspect-ratio: 1;
      border-radius: 100%;

      /* 	Put it on the left */
      left: 0;
      transform: translateX(0);

      /* 	Set the animation speed and easing */
      transition: left var(--transition-time) ease-out,
        transform var(--transition-time) ease-out;
    }
  }
}

.fade-element {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

a.link {
  font-weight: normal;
  color: $primary-color;
  text-decoration: none;

  &-strip {
    color: inherit;
    text-decoration: none;
  }

  &-subtle {
    color: rgba(0, 0, 0, 0.6);
  }
}

.button {
  @extend .cta;

  &-filled {
    @extend .cta-filled;
  }
}

.input-label {
  font-size: 17px;
  font-weight: normal;
  margin-bottom: 10px;
}

.no-scrollbar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  // Hide scrollbar for Chrome, Safari, and Opera
  &::-webkit-scrollbar {
    display: none;
  }
}

:not(.no-scrollbar) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

.custom-horizontal-scrollbar {
  overflow-x: auto;
  overflow-x: scroll;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cccccc;
  }
}

.neumorphism {
  border-radius: 10px;
  box-shadow: 10px 10px 20px #afafaf, -10px -10px 20px #ffffff;
  background: linear-gradient(145deg, #f6f6f6, #ececec);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(10px);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -40;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(15px);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -50;
    background-image: linear-gradient(
      144deg,
      #af40ff 49%,
      #5b42f3 50%,
      #00ddeb
    );
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  }
}

.input {
  &-small {
    width: 100px;
  }
  &__container {
    position: relative;
    background: #ddddddc7;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    gap: 5px;
    border-radius: 22px;
    transition: transform 400ms;
    transform-style: preserve-3d;
    flex-direction: column;
    color: white;
    width: 100%;
    align-items: flex-start;
  }

  &__shadow {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(30px);
    border-radius: 20px;
    background-color: #999cff;
    background-image: radial-gradient(
        at 85% 51%,
        hsla(60, 60%, 61%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 74% 68%, hsla(235, 69%, 77%, 1) 0px, transparent 50%),
      radial-gradient(at 64% 79%, hsla(284, 72%, 73%, 1) 0px, transparent 50%),
      radial-gradient(at 75% 16%, hsla(283, 60%, 72%, 1) 0px, transparent 50%),
      radial-gradient(at 90% 65%, hsla(153, 70%, 64%, 1) 0px, transparent 50%),
      radial-gradient(at 91% 83%, hsla(283, 74%, 69%, 1) 0px, transparent 50%),
      radial-gradient(at 72% 91%, hsla(213, 75%, 75%, 1) 0px, transparent 50%);
  }

  &__element {
    width: 100%;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 8px 15px;
    position: relative;
    background: #ffffff85;
  }
}

.accordion {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24) !important;
  border-radius: $border-radius !important;
  overflow: hidden;

  &-item {
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;

    .accordion-button {
      border-radius: 0 !important;
    }

    &:first-of-type {
      border-top: none !important;
    }

    &:last-of-type {
      border-bottom: none !important;
    }
  }
}

.hide {
  display: none !important;
}

// CK EDITOR
.editor-read-only {
  figure {
    @include md {
      width: 100% !important;
      img {
        width: 100%;
        height: auto !important;
      }
    }
  }
  .ck-editor__top {
    display: none !important;
  }
  .ck-content {
    border-radius: unset !important;
  }
}
.ck-editor {
  &__editable {
    min-height: 300px;
    border-radius: $border-radius !important;
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    box-shadow: $box-shadow !important;
    // border: 1px solid green !important;
  }
  .ck-read-only {
    .ck-editor__top {
      display: none;
    }
    &.ck-editor__editable {
      min-height: unset;
      border: none !important;
      box-shadow: unset !important;
      padding: 0;
      p {
        margin: 4px 0;
      }
      figure {
        margin: 8px 0;
      }
    }
  }

  &__top {
    border: none;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    .ck-sticky-panel {
      border: none;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      .ck-toolbar {
        border: none;
      }
    }
  }
  &__main {
    .ck-content {
      border: none;
      outline: none;
      box-shadow: none;
      .image {
        width: 50%;
        margin: 0;
        outline: none !important;
        img {
          border-radius: $border-radius;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  transition: 0.3s ease-in-out;
  transform: scale(0);
  opacity: 0;
  &.z-index {
    z-index: 999999999;
  }
  &.show {
    opacity: 1;
    backdrop-filter: blur(6px);
    transform: scale(1);
  }
  &.big {
    width: 500vw;
    top: 0;
    z-index: 9999999999;
  }
  &.transparent {
    opacity: 0;
    transform: scale(1);
    background-color: transparent;
  }
}

.circle-loader {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: $primary-color;
  animation: spin 2s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid transparent;
  }

  &:before {
    top: 0;
    left: 0;
    width: 46px;
    height: 46px;
    border-top-color: $secondary-color;
    animation: spin 3s linear infinite;
  }

  &:after {
    border-top-color: $primary-color;
    animation: spin 1.5s linear infinite;
  }
}

.loader-content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-weight: 500;
    font-size: 35px;
    transition: 0.4s ease-in-out;
    &.show {
      display: inline-block;
      animation: bounce 3s ease-in-out infinite;
    }

    &.dont-show {
      display: none;
    }
    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(
          -20px
        ); // Adjust the value to control the bounce height
      }
    }
  }
}

#loader {
  width: 100px;
  height: 100px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.one {
  width: 120px;
  height: 120px;
  border: 2px solid #ffffff00; /* f3f3f3 */
  position: absolute;
  border-radius: 50%;
  border-right: 4px solid $primary-color;
  border-left: 4px solid $blue-color;
  -webkit-animation: spin-right 2s linear infinite;
  animation: spin-right 2s linear infinite;
}

.two {
  width: 100px;
  height: 100px;
  border: 2px solid #fafafa00; /* f3f3f3 */
  position: absolute;
  border-radius: 50%;
  margin: 10px;
  border-bottom: 4px solid #e67e22;
  border-top: 4px solid #f1c40f;
  -webkit-animation: spin-left 1s linear infinite;
  animation: spin-left 1s linear infinite;
}

.three {
  width: 80px;
  height: 80px;
  border: 2px solid #2c3e5000;
  position: absolute;
  border-radius: 50%;
  margin: 20px;
  border-top: 4px solid #2ecc71;
  -webkit-animation: spin-right 1s linear infinite;
  animation: spin-right 1s linear infinite;
}

@keyframes spin-left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-item {
  opacity: 0.5;
  pointer-events: none;
  overflow-x: hidden;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%; // Start offscreen to the left
    width: 60%;
    height: 3px;
    background-color: $primary-color;
    animation: moveLoadingBar 2s linear infinite; // Adjust the duration as needed
  }
  &.red {
    &:before {
      background-color: $red;
    }
  }
  &.test {
    &:before {
      background-color: $green-color;
    }
  }
  &.exam {
    &:before {
      background-color: $secondary-color;
    }
  }
  &.online-exam {
    &:before {
      background-color: $blue-color;
    }
  }
  &.bottom {
    &:before {
      top: unset;
      bottom: 0;
    }
  }
  @keyframes moveLoadingBar {
    0% {
      left: -100%;
    }
    50% {
      left: 0%;
    }
    100% {
      left: 100%;
    }
  }
}

.events__item {
  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  &-create {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 8px 20px;
    margin: 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    overflow-x: hidden;
    z-index: 9;
    background-color: white;
    &-motion {
      overflow-y: visible !important;
    }
    span {
      color: $grey;
      font-weight: 300;
    }
    &:hover {
      cursor: pointer;
    }
    &-close {
      position: relative;
      transition: 0.3s ease-in-out;
      display: flex;
      justify-content: flex-end;
      margin-top: -45px;
      svg {
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
    &-progress {
      position: fixed;
      top: 20px;
      right: 20px;
      height: 90px;
      width: 90px;
      z-index: 9999;
      // padding: 10px;
      // background-color: white;
      box-shadow: $box-shadow-dark;
      border-radius: 50%;
      .CircularProgressbar-path {
        stroke: $primary-color;
      }
      text {
        font-weight: 1000;
        fill: $primary-color;
      }
    }
    &.is-open {
      position: fixed;
      top: 5vh;
      left: 5vw;
      width: 90vw;
      height: 90vh;
      z-index: 99999;
      border-left: none !important;
      padding: 0;
      height: 90vh;
      top: 5vh;
      box-shadow: none;
      background-color: white;
      overflow: visible;
      @include md {
        height: 92vh !important;
        width: 90vw !important;
        top: 6vh !important;
        left: 5vw !important;
        padding: 0 !important;
        .events__item-create-close {
          width: 90vw !important;
        }
      }
      &.type {
        height: 700px;
        min-height: 34vw;
        top: calc((100vh - 700px) / 2);
        background-color: transparent;
        box-shadow: unset;
        pointer-events: none;
        transition: 0.7s ease-in-out;
        height: 100vh;
        top: 0;
        @include md {
          height: 80vh !important;
          top: 15vh !important;
        }
        .item {
          pointer-events: auto;
        }
      }
      &.general {
        top: 7vh;
        height: 90vh;
        width: 60vw;
        left: 20vw;
        .events__item-create-close {
          width: 60vw;
        }
      }
      &.online-grades {
        padding: 0;
        top: 5vh;
        height: 90vh;
        width: 90vw;
        left: 5vw;
        .events__item-create-close {
          width: 90vw;
        }
      }
      &.grades {
        top: 20vh;
        height: 60vh;
        width: 90vw;
        left: 5vw;
        .events__item-create-close {
          width: 90vw;
        }
      }
      &.online-date {
        top: 10vh;
        height: 80vh;
        left: 10vw;
        background-color: transparent;
        width: 80vw;
        .events__item-create-close {
          width: 80vw;
        }
        &:after {
          content: "";
          position: absolute;
          background-color: white;
          top: 0;
          left: 0;
          width: 80%;
          height: 100%;
          border-radius: 0% 20% 20% 0% / 21% 51% 50% 10%;
          z-index: 3;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:before {
          content: "";
          position: absolute;
          background-color: $test;
          top: 0;
          right: 0;
          width: 90%;
          height: 100%;
          z-index: 2;
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
      &.date {
        top: 6vh;
        height: 93vh;
        width: 80vw;
        left: 10vw;
        background-color: transparent;
        @include md {
          background-color: white;
        }
        .events__item-create-close {
          width: 80vw;
        }
        @include md {
          &:after {
            display: none;
          }
          &:before {
            display: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          background-color: white;
          top: 0;
          left: 0;
          width: 70%;
          height: 100%;
          border-radius: 0% 20% 20% 0% / 21% 51% 50% 10%;
          z-index: 3;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:before {
          content: "";
          position: absolute;
          background-color: $test;
          top: 0;
          right: 0;
          width: 90%;
          height: 100%;
          z-index: 2;
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
      &:hover {
        cursor: default;
      }
      .events__item {
        &-info {
        }
      }
    }
    &-info {
      padding: 10px 10px 10px 0;
      display: flex;
      align-items: center;
      svg {
        padding: 0 !important;
        margin: 0 !important;
      }
      span {
        @include md() {
          width: 80%;
        }
        font-size: 16px;
        font-weight: 300;
        margin-left: 15px;
        color: $grey;
      }
    }
    &-motion {
      height: 100%;
      @include md() {
        width: 100%;
      }
    }
  }
}
