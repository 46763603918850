@import "../../../../scss/vars.scss";

.online-exam-preview {
  margin-top: 20px;
  padding: 20px;
  .themata {
    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .subtitle {
        font-size: 13px;
        color: $grey;
        font-weight: 300;
      }
      .title {
        margin-top: 5px;
        font-size: 16px;
        color: $grey;
      }
      .ck-editor__top {
        display: none !important;
      }
      .ck-content {
        border-radius: unset !important;
      }
      .editor-shell {
        margin-top: 10px;
        padding: 10px 5px;
        .ContentEditable__root {
          max-height: unset;
          span,
          p {
            color: $grey;
          }
          img {
            margin-top: 10px;
            max-height: 30vh;
          }
        }
      }
    }
  }
}
