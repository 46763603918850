@import "src/scss/vars";

.curriculum {
  width: calc(100vw - 390px);
  margin-left: 355px;
  margin-top: 130px;
  padding-bottom: 100px;
  @include md {
    margin-left: unset;
    padding: 0 10px 0 20px;
    width: 100%;
  }
  &__pagination {
    margin: 40px 0;
    width: 100%;
    @include md {
      margin-bottom: 120px;
    }
  }
  &__filters {
    text-align: right;
  }
  .student-header {
    display: flex;
    margin-bottom: 30px;
    position: sticky;
    top: 80px;
    z-index: 99;
    width: 100vw;
    @include md {
      height: 60px;
      top: 100px;
    }
    &__search {
      width: 100%;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 25px 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      background-color: white;
      height: 60px;
      &.closed {
        max-width: 60px;
        input {
          display: none;
        }
      }
      @include md {
        transition: 0.3s ease-in-out;
        max-width: calc(100% - 70px);
        margin-right: 10px;
      }
      input {
        width: 100% !important;
        border-radius: none;
        border: none;
        box-shadow: none;
        outline: none;
        display: block;
        margin-right: 20px;
        color: $grey;
        font-weight: 300;
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &__filters {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      width: 100%;
      background-color: white;
      max-height: 60px;
      height: 70vh;
      max-width: 60px;
      display: none;
      overflow-y: hidden;
      position: relative;
      @include md {
        display: block;
      }
      &.open {
        max-width: calc(100% - 70px);
        max-height: 70vh;
        .professor-exams__mobile-filters-preview {
          justify-content: flex-end;
        }
      }
      &.closed {
        max-width: 60px;
        overflow: hidden;
      }
      &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 60px;
        span {
          color: $grey;
          font-weight: 200;
          font-size: 14px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 0 20px;
          svg {
            margin-left: 10px !important;
          }
        }
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  &__header {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    position: sticky;
    height: 70px;
    top: 100px;
    z-index: 997;
    width: 100%;
    &.is-expanded {
      z-index: 99999;
    }
    @include md {
      height: 60px;
      align-items: unset;
    }
    &-view {
      height: 68px;
      width: 8%;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease-in-out;
      @include md {
        display: none;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &-search {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      width: 100%;
      background-color: white;
      @include md {
        height: 60px;
      }
      .search-close {
        transition: 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
      svg {
        margin: 0 !important;
        padding: 0 !important;
      }
      &.closed {
        max-width: 10%;
        @include md {
          max-width: 60px;
        }
        &:hover {
          cursor: pointer;
          transform: scale(1.08);
        }
        input {
          padding: 0;
          overflow: hidden;
          max-width: 0;
        }
      }
      &.open {
        max-width: 50%;
        @include md {
          max-width: calc(100% - 140px);
        }
        input {
          margin-right: 20px;
        }
      }
      input {
        width: calc(100% - 80px);
        display: block;
        box-shadow: unset;
        // border-bottom: 1px solid $grey-lighter;
        border-radius: unset;
        padding: 10px;
        font-weight: 300;
        color: $grey;
        @include md {
          width: 72%;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .curriculum-mobile-filters {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;
      width: 100%;
      background-color: white;
      max-height: 60px;
      height: 70vh;
      max-width: 60px;
      margin-left: 20px;
      display: none;
      overflow-y: hidden;
      position: relative;
      @include md {
        display: block;
        margin-left: 10px;
      }
      &.open {
        max-width: calc(100% - 140px);
        max-height: 70vh;
        .professor-exams__mobile-filters-preview {
          justify-content: flex-end;
        }
      }
      &.closed {
        max-width: 60px;
        overflow: hidden;
      }
      &__preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 60px;
        span {
          color: $grey;
          font-weight: 200;
          font-size: 14px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 0 20px;
          svg {
            margin-left: 10px !important;
          }
        }
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  &__posts {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include md {
    }
    &-new {
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 18px 20px;
      width: 100%;
      margin: 20px 0;
      position: relative;
      background-color: white;
      margin-right: 20px;
      @include md {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      &:hover {
        cursor: pointer;
      }
      &-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 60px;
        span {
          color: $grey;
          font-weight: 200;
          font-size: 14px;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          padding: 0 20px;
          svg {
            margin-left: 10px !important;
          }
        }
        svg {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
      &.open {
        position: fixed;
        top: 10vh;
        height: 80vh;
        width: 80vw;
        left: 10vw;
        z-index: 9999;
        padding: 20px;
        @include md {
          position: relative;
          left: auto;
          width: 90%;
          top: -8vh;
          padding: 20px 10px;
        }
        &:hover {
          cursor: default;
        }
      }
      &-close {
        position: absolute;
        right: 30px;
        top: 30px;
        &:hover {
          cursor: pointer;
        }
      }
      .configure {
        position: relative;
        height: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 0 20px;
        &__label {
          font-weight: 300;
          font-size: 18px;
          margin-left: 5px;
        }
        .wrapper {
          width: 100%;
          display: flex;
          @include md {
            flex-direction: column;
          }
          .left {
            width: 50%;
            padding: 0 20px 0 0;
            position: relative;
            @include md {
              width: 100%;
            }
            &:after {
              position: absolute;
              content: "";
              width: 1px;
              height: calc(100% - 40px);
              background-color: $grey-lighter;
              top: 40px;
              right: 0;
            }
          }
          .right {
            width: 50%;
            padding: 0 20px;
            @include md {
              width: 100%;
              margin-top: 40px;
            }
          }
        }
        &__message {
          margin-top: 40px;
          font-size: 16px;
          color: $grey;
        }
        &__departments {
          display: flex;
          flex-direction: column;
          &-list {
            margin-top: 20px;
            .item {
              padding: 15px 25px;
              border-radius: $border-radius;
              box-shadow: $box-shadow;
              display: inline-block;
              margin: 0 20px 20px 0;
              border: 2px solid white;
              transition: 0.2s ease-in-out;
              &.selected {
                border: 2px solid $primary-color;
              }
              &:hover {
                cursor: pointer;
                transform: scale(1.05);
              }
            }
          }
        }
        &__class {
          margin-top: 20px;
          select {
            border: none;
            outline: none;
            box-shadow: inset $box-shadow;
            border-radius: 15px;
            padding: 10px;
          }
        }
        &__pretitle {
          width: 100%;
          position: relative;
          padding: 15px 0 30px;
          text-align: center;
          span {
            font-size: 27px;
            font-weight: 500;
          }
          // &:after {
          //     position: absolute;
          //     bottom: 0;
          //     left: 0;
          //     width: 100%;
          //     height: 1px;
          //     background-color: $grey-even-lighter;
          //     content: '';
          // }
        }
        &__title {
          margin-top: 40px;
          width: 100%;
          input {
            width: 100%;
            box-shadow: inset $box-shadow;
          }
        }
        &__description {
          margin-top: 25px;
          width: 100%;
          input {
            width: 100%;
            box-shadow: inset $box-shadow;
          }
        }
        &__files {
          margin-top: 20px;
          text-align: left;
          display: flex;
          flex-direction: column;
          &-thumbnails {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            .exam-thumbnail-item {
              width: 60px;
              margin-right: 20px;
              margin-bottom: 20px;
              position: relative;
            }
            img {
              width: 50px !important;
              height: 50px !important;
              object-fit: cover;
              border-radius: 50%;
              box-shadow: $box-shadow;
            }
            svg {
              position: absolute;
              top: -10px;
              right: -10px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          &-error {
            margin-top: 10px;
            color: $red;
          }
        }
        &__publish {
          width: 50%;
          margin: 70px auto 0;
          align-self: flex-end;
          @include md {
            width: 100%;
          }
          button {
            width: 100%;
          }
        }
      }
    }
    &.view-double {
      .curriculum-card {
        width: 48%;
        @include md {
          width: 100%;
        }
      }
    }
  }
}
